/* eslint-disable react/no-direct-mutation-state */
import React from 'react';
import { connect } from "react-redux";
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { injectIntl } from "react-intl";
import Form, { Item as FormItem, SimpleItem, RequiredRule, GroupItem, RangeRule } from 'devextreme-react/form';
import { dxDateBoxProps } from '../../../../../shared/config/DxFormProperties';
import { withSnackbar } from 'notistack';
import RadioGroup from 'devextreme-react/radio-group';
import moment from 'moment';
import * as actionCrud from '../../../../../crud/action.crud';

export const RESPONSES = {
    SAVE: 'SAVE',
    CANCEL: 'CANCEL',
};

const radioOptions = [
    { value: 0, display: "No" },
    { value: 1, display: "Yes" }
]
export const recurrencyOptions = [
    { value: 'WEEKLY', display: "Weekly" },
    { value: 'MONTHLY', display: "Monthly" },
];
export const weeklyOptions = [
    { value: 'MONDAY', display: "Monday" },
    { value: 'TUESDAY', display: "Tuesday" },
    { value: 'WEDNESDAY', display: "Wednesday" },
    { value: 'THURSDAY', display: "Thursday" },
    { value: 'FRIDAY', display: "Friday" },
    { value: 'SATURDAY', display: "Saturday" },
    { value: 'SUNDAY', display: "Sunday" },
];
const initialState = {
    isrecurrent: 0,
    frequency: null,
    isbusy: false
}

class ContractActionCreateModal extends React.Component {
    constructor(props) {
        super(props);
        this.validatorRef = React.createRef();
        this.validatorName = 'ContractActionCreateModalValidator';
        this.formRef = React.createRef();
        this.dayOfMonthOptions = [...Array(28).keys()].map(i => i + 1);
        this.formData = {}
        this.defaultFields = null;
        if (this.props.defaultSubject) {
            this.defaultFields = { subject: this.props.defaultSubject };
        }
        this.state = { ...initialState }

    }

    componentDidUpdate(revProps, prevState) {
        

    }
    onClose = () => {
        if (this.props.visible) {
            if(this.formRef.current?.instance){
                this.formRef.current.instance.clear();
                this.formRef.current.instance.updateData("subject", this.props.defaultSubject)
            }
            this.props.onDialogResult();
            this.formData = {}
            this.setState({ ...initialState });
        }
    }
    saveItem = async () => {
        try {
            if (this.formRef?.current) {
                const validationRes = this.formRef.current.instance.validate();
                if (validationRes.isValid) {
                    this.setState({ isbusy: true }, async () => {
                        try {
                            const requestObj = { ...this.formData };
                            requestObj.isrecurrent = this.state.isrecurrent;
                            requestObj.contractid = this.props.contract.contractid;
                            if (requestObj.duedate) {
                                requestObj.duedatedisplay = moment(requestObj.duedate).format('DD/MM/YYYY');
                                delete requestObj.duedate;
                            }
                            if (requestObj.daterange) {
                                requestObj.startdatedisplay = moment(requestObj.daterange[0]).format('DD/MM/YYYY');
                                requestObj.enddatedisplay = moment(requestObj.daterange[1]).format('DD/MM/YYYY');
                                delete requestObj.daterange;
                            }
                            if (this.props.workflowItem) {
                                requestObj.sourceworkflowid = this.props.workflowItem.id;
                                requestObj.sourcerecordid = Number(this.props.params.notificationid);
                                requestObj.sourcereferenceid = this.props.referenceid;
                            }
                            const { data: itemCount } = await actionCrud.create(requestObj)
                            if (itemCount > 0) {
                                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'ACTIONS.TOAST.CREATED' }, { COUNT: itemCount }), { variant: 'success', });
                            }
                            else {
                                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'ACTIONS.TOAST.IMPORTED_NONE' }), { variant: 'warning', });
                            }

                            this.props.onDialogResult(RESPONSES.SAVE);
                            this.formData = {}
                            this.setState({ ...initialState });
                        } catch (err) {
                            this.setState({ isbusy: false });
                            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                        }

                    })
                }
            }
        } catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }

        return;
    }
    validateForm = () => {
        const validationResult = this.validatorRef.current.instance.validate();
        return validationResult.isValid;
    }

    formFieldDataChanged = (e) => {
        this.formData = e.component.option("formData");
        if (e.dataField === 'frequency') {
            this.setState({ frequency: e.value })
        }

        else if (e.dataField === 'type') {
            //AUTO SELECT PARTY DEPENDING ON TYPE_WORKFLOW
            const selectedType = this.props.contractTemplate.availableActionTypes.find(x => x.value === e.value);
            if (selectedType && selectedType.wfid) {
                let typeWF = this.props.contractTemplate.workflowsQA[selectedType.wfid];
                if (typeWF && typeWF.notifyingParty) {
                    e.component.updateData("ownerparty", typeWF.notifyingParty)
                }
            }
        }
    }
    onRecurrencyOptionChanged = (e) => {
        if (this.formData) {
            this.formData.isrecurrent = e.value;
        }


        this.setState({ isrecurrent: e.value });
    }

    render() {
        if (!this.props.visible) {
            return null;
        }
        return (
            <Popup
                id='createActionPopup'
                visible={this.props.visible}
                onHiding={this.onClose}
                dragEnabled={true}
                hideOnOutsideClick={false}
                showTitle={true}
                title={this.props.intl.formatMessage({ id: "ACTIONS.POPUP.CREATE.TITLE" })}
                width={600}
                disabled={this.state.isbusy}
                height='auto'>
                <Form
                    ref={this.formRef}
                    formData={this.defaultFields}
                    onFieldDataChanged={this.formFieldDataChanged}
                    labelMode='outside'
                    labelLocation='top'
                >
                    <GroupItem colCount={2}>
                        <FormItem
                            colSpan={1}
                            label={{ text: this.props.intl.formatMessage({ id: 'ACTIONS.FIELD.TYPE' }) }}
                            dataField="type"
                            editorType='dxSelectBox'
                            editorOptions={{
                                dataSource: this.props.contractTemplate.availableActionTypes,
                                valueExpr: 'value',
                                displayExpr: 'display',
                            }}
                            isRequired
                        />
                        <FormItem
                            colSpan={1}
                            label={{ text: this.props.intl.formatMessage({ id: 'ACTIONS.FIELD.OWNER_PARTY' }) }}
                            dataField="ownerparty"
                            on
                            editorType='dxSelectBox'
                            editorOptions={{
                                dataSource: this.props.contractTemplate.parties.existingOptions,
                                valueExpr: 'key',
                                displayExpr: 'display',
                            }}
                            isRequired
                        />
                        <SimpleItem
                            colSpan={1}
                            label={{ text: this.props.intl.formatMessage({ id: 'ACTIONS.FIELD.IS_RECURRENT' }) }}
                            dataField="isrecurrent"
                        >
                            <RadioGroup
                                readOnly={!this.props.allowRecurrent}
                                items={radioOptions}
                                defaultValue={0}
                                valueExpr="value"
                                displayExpr="display"
                                layout="horizontal"
                                value={this.state.isrecurrent}
                                onValueChanged={this.onRecurrencyOptionChanged}
                            />
                        </SimpleItem>

                    </GroupItem>
                    <GroupItem
                        visible={this.state.isrecurrent === 1}
                        name='RecurringFields'
                        colCount={2}
                    >
                        <SimpleItem
                            colSpan={2}
                            label={{ text: this.props.intl.formatMessage({ id: 'ACTIONS.FIELD.DATE_RANGE' }) }}
                            dataField="daterange"
                            editorType="dxDateRangeBox"
                            isRequired={this.state.isrecurrent === 1}
                            editorOptions={{
                                startDatePlaceholder: 'Start Date (DD/MM/YYYY)',
                                endDatePlaceholder: 'End Date (DD/MM/YYYY)',
                                calendarOptions: {
                                    firstDayOfWeek: 1,
                                    min: moment().subtract(10,'years')._d,
                                    max: moment().add(10, 'years')._d,
                                },
                                acceptCustomValue: true,
                                displayFormat: 'dd/MM/yyyy',
                                useMaskBehavior: true,
                                min: moment().subtract(10,'years')._d,
                                max: moment().add(10, 'years')._d,
                            }}
                        >
                            <RangeRule
                                min={moment().subtract(10,'years')._d}
                                max={moment().add(10, 'years')._d}
                                message={"Date range can not be in past or more than 10 years ahead"}
                            />
                            <RequiredRule message="Date Range is required" />
                        </SimpleItem>

                        <FormItem
                            colSpan={1}
                            label={{ text: this.props.intl.formatMessage({ id: 'ACTIONS.FIELD.FREQUENCY' }) }}
                            dataField="frequency"
                            editorType='dxSelectBox'
                            editorOptions={{ dataSource: recurrencyOptions, valueExpr: 'value', displayExpr: 'display' }}
                            isRequired={this.state.isrecurrent === 1}
                        />
                        <FormItem
                            colSpan={1}
                            visible={this.state.isrecurrent === 1 && this.state.frequency === 'WEEKLY'}
                            label={{ text: this.props.intl.formatMessage({ id: 'ACTIONS.FIELD.WEEKDAY' }) }}
                            dataField="weekday"
                            editorType='dxSelectBox'
                            editorOptions={{ dataSource: weeklyOptions, valueExpr: 'value', displayExpr: 'display' }}
                            isRequired={this.state.isrecurrent === 1}
                        />
                        <FormItem
                            colSpan={1}
                            visible={this.state.isrecurrent === 1 && this.state.frequency === 'MONTHLY'}
                            label={{ text: this.props.intl.formatMessage({ id: 'ACTIONS.FIELD.DAY_OF_MONTH' }) }}
                            dataField="dayofmonth"
                            editorType='dxSelectBox'
                            editorOptions={{ dataSource: this.dayOfMonthOptions }}
                            isRequired={this.state.isrecurrent === 1}
                        />
                    </GroupItem>
                    <GroupItem visible={this.state.isrecurrent === 0} name='NonRecurringFields' colCount={2}>
                        <FormItem
                            label={{ text: this.props.intl.formatMessage({ id: 'ACTIONS.FIELD.DUE_DATE' }) }}
                            dataField="duedate"
                            editorType="dxDateBox"
                            editorOptions={{
                                ...dxDateBoxProps,
                                min: moment().subtract(10,'years')._d,
                                max: moment().add(10, 'years')._d,
                                calendarOptions: {
                                    firstDayOfWeek: 1,
                                    min: moment().subtract(10,'years')._d,
                                    max: moment().add(10, 'years')._d,
                                }
                            }}
                            isRequired={this.state.isrecurrent === 0}
                        />
                    </GroupItem>
                    <GroupItem>
                        <FormItem
                            defaultValue={this.props.defaultSubject}
                            label={{ text: this.props.intl.formatMessage({ id: 'ACTIONS.FIELD.SUBJECT' }) }}
                            dataField="subject"
                            editorType="dxTextBox"
                            editorOptions={{ maxLength: 100 }}
                            isRequired
                        />
                        <FormItem
                            label={{ text: this.props.intl.formatMessage({ id: 'ACTIONS.FIELD.DESCRIPTION' }) }}
                            dataField="description"
                            editorType="dxTextArea"
                            editorOptions={{ height: 100, maxLength: 250 }}
                            isRequired
                        />
                    </GroupItem>
                </Form>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{ text: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_CANCEL' }), onClick: this.onClose }}>
                </ToolbarItem>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: this.props.intl.formatMessage({ id: 'ACTIONS.BUTTON.CREATE_ACTION' }),
                        icon: this.state.isbusy ? 'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--dark' : 'save',
                        onClick: this.saveItem
                    }}>
                </ToolbarItem>
            </Popup >
        );
    }
}

const mapStateToProps = store => ({
    contract: store.contract.contract,
    contractTemplate: store.contract.template,
});

export default connect(mapStateToProps)(withSnackbar(injectIntl(ContractActionCreateModal)));