import React from "react";
import BaseDetailPage from "../../_CustomComponents/BaseDetailPage";
import { addDays } from "date-fns";
import { Navigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import {
  Portlet,
  PortletBody,
  PortletHeader
} from "../../../../partials/content/Portlet";
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import * as itqQuotationCrud from "../../../../crud/itqQuotation.crud";
import * as instructionToQuoteCrud from "../../../../crud/instructionToQuote.crud";
import * as fileCrud from "../../../../crud/file.crud";
import axios from "axios";
import ActionModal from '../../_CustomComponents/ActionModal';
import QuoteDocument from "./Document";
import ReplyDocument from "./ReplyDocument";
// import ReplyForm from './ReplyForm';
import { TabPanel, Item } from "devextreme-react/tab-panel";
import { Alert } from "react-bootstrap";
import { ValidationSummary, ValidationGroup } from "devextreme-react";
import Common from "../../../../shared/common";
import moment from "moment";
import { itqQuotableStatuses } from "./Router";
import { FormattedMessage } from "react-intl";
import { CBS_ContractTypes_Quotes, customFieldsWfSubTypes } from '../../../../shared/lookup';
import PageToolbar from '../../_CustomComponents/PageToolbar';
import { withDetailPageProps } from '../../../../shared/hoc/withDetailPageProps';
import NavigationBlocker from '../../../../partials/Tools/NavigationBlocker';
import EmailNotifications from "../../_CustomComponents/EmailNotifications";
const initialState = {
  notification: null,
  files: null,
  showModal: false,
  saving: false,
  deleting: false,
  // isDxFormValid: true,
  isChanged: false,
  //NEW REPLY STATES
  reply: null,
  replyFiles: null,
  isAwaitingReply: false,
  modalTitle: "",
  modalText: "",
  modalMode: "",
  relationCount: 0,
  userLimit: null,
  projectOrderButtonEnabled: false
};

class QuoteDetailPage extends BaseDetailPage {
  constructor(props) {
    super(props);
    this.printIssueDivRef = React.createRef();
    this.printAcceptanceDivRef = React.createRef();
    this.isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'DEV';
    this.wbsEnabled = (process.env.REACT_APP_COST_BREAKDOWN_ENABLED === 'true' && CBS_ContractTypes_Quotes.includes(props.contract.contracttype));

    //NEW REPLY
    this.validatorRef = React.createRef();
    this.validatorName = "QuoteValidator";
    //NAVIGATE TO QUOTE TAB
    if (props.notificationType === "ITQPM") {
      //INSTRUCTION
      this.timer = setTimeout(() => {
        props.parentTabPanel.instance.option("selectedIndex", 1);
      }, 200);
    }


    this.quoteStatuses = props.placeholders
      .find((ph) => ph.replacementKey === "QuoteStatuses")
      .values.filter((item) => item.isActive === true);

    this.state = {
      ...initialState
    };
  }

  componentDidMount() {
    this.fetchQuote();
    this.fetchFiles();
    this.fetchReplyFiles();
  }
  componentDidUpdate(prevprops, prevSatate) {
    if (this.props.params.quotationid !== prevprops.params.quotationid) {
      this.setState({ ...initialState }, () => {
        this.fetchQuote();
        this.fetchFiles();
        this.fetchReplyFiles();
      });
    }
  }
  getMailDescription = () => {
    return this.state.notification.subject;
  };
  getSelectedTypeClauseDisplay = () => {
    return "";
}
  fetchQuote = async () => {
    await this.props.closeSnackbar("PersistentFormWarning");
    itqQuotationCrud
      .getItqQuote(this.props.params.quotationid)
      .then((response) => {
        const quote = response.data;
        if (
          quote.contractid !== this.props.contract.contractid ||
          quote.type !== this.props.notificationType ||
          quote.instructiontoquoteid !==
          this.props.instructionToQuote.instructiontoquoteid
        ) {
          throw new Error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.INVALID_URL' }));
        }
        if (quote.createdate) {
          quote.createdate = new Date(quote.createdate);
        }
        if (quote.responsedate) {
          quote.responsedate = new Date(quote.responsedate);
        }
        if (quote.responsecreatedate) {
          quote.responsecreatedate = new Date(quote.responsecreatedate);
        }
        if (quote.notificationdate) {
          quote.notificationdate = new Date(quote.notificationdate);
        }
        if (quote.draftreplydate) {
          quote.draftreplydate = new Date(quote.draftreplydate);
        }
        if (quote.startdate) {
          quote.startdate = new Date(quote.startdate);
        }
        if (quote.completiondate) {
          quote.completiondate = new Date(quote.completiondate);
        }

        const awaitingReply =
          quote.showtasknotification === 1 && quote.status === 2;
        const initialStatus =
          quote.draftquotestatus == null
            ? null
            : Number(quote.draftquotestatus);
        const replyItem = {
          responsecomment: quote.draftreplycomment,
          status: initialStatus,
          customfieldvalues: quote.customfieldvalues
        };

        quote.referenceid = this.getQuoteReference(quote);
        this.setState({
          notification: quote,
          reply: replyItem,
          isAwaitingReply: awaitingReply,
          saving: false,
          isChanged: false,
        }, () => { this.props.setReference(this.state.notification.referenceid); });
      })
      .catch((err) => {
        this.props.enqueueSnackbar(err.toString(), { variant: "error" });
      });
  };

  fetchFiles = async () => {
    try {
      const { companyid, projectid, contractid } = this.props.contract;
      const { data } = await fileCrud.getContractNotificationFiles(companyid, projectid, contractid, this.props.params.quotationid, this.props.documentType)
      const filesList = JSON.parse(data.aggregatelist)
      this.setState({ files: filesList });
      return filesList;
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
      }
    }
  }
  fetchReplyFiles = async () => {
    try {
      const { companyid, projectid, contractid } = this.props.contract;
      const { data } = await fileCrud.getContractNotificationFiles(companyid, projectid, contractid, this.props.params.quotationid, this.props.documentType + 'Reply')
      const filesList = JSON.parse(data.aggregatelist)
      this.setState({ replyFiles: filesList });
      return filesList;
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
      }
    }
  }

  printHandler = () => {
    this.printIssueRef.handlePrint();
  };
  printAcceptanceHandler = () => {
    this.printAcceptanceRef.handlePrint();
  };
  validateForm = () => {
    const validationResult = this.validatorRef.current.instance.validate();

    if (!validationResult.isValid) {
      this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_MISSING_FIELDS' }), {
        variant: "warning",
      });
    }

    return validationResult.isValid && !this.state.saving;
  };
  showModal = (mode, postActionNavigateObj) => {
    if (mode === "COMMUNICATE") {
      if (this.validateForm()) {
        this.setState({
          showModal: true,
          modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_COMMUNICATE_ITEM' }, { ITEM_NAME: this.props.title }),
          modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_COMMUNICATING' }, { ITEM_NAME: this.props.title, PARTY_NAME: this.props.communicateToUser }),
          modalMode: mode,
          postActionUrl: postActionNavigateObj
        });
      }
    }
    else if (mode === 'SAVE_DRAFT') {
      if (this.validateForm()) {
        this.setState({
          showModal: true,
          modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SAVE_DRAFT_NOTIFY' }),
          modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_SAVING' }),
          modalMode: mode,
        });
      }
    }
    else if (mode === 'DELETE_DRAFT') {
      this.setState({
        showModal: true,
        modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_DELETE_DRAFT_NOTIFY' }),
        modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_DELETING' }),
        modalMode: mode,
      });
    }
    else if (mode === "REPLY_DRAFT") {
      //show reply tab
      this.tabPanel.instance.option("selectedIndex", 1);
      if (this.validateForm()) {
        this.setState({
          showModal: true,
          modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SAVE_REPLY_DRAFT_NOTIFY' }),
          modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_DRAFT_SAVING' }),
          modalMode: mode,
        });
      }
    }
    else if (mode === "REPLY_SEND") {
      //show reply tab
      this.tabPanel.instance.option("selectedIndex", 1);
      if (this.validateForm()) {
        this.setState({
          showModal: true,
          modalTitle: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_SEND_REPLY' }),
          modalText: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_REPLY_SENDING' }, { PARTY_NAME: this.props.globalResources[this.props.workflowItem.notifyingParty + "_NAME"] }),
          modalMode: mode,
          projectOrderButtonEnabled: this.state.reply.status === 1 ? true : false
        });
      }
    }
  };
  onModalResponse = (response, params) => {
    this.setState({ showModal: false, saving: (response === 'OK' || response === "CREATE") }, async () => {
      try {
        if (response === 'OK' || response === "CREATE") {


          if (this.state.modalMode === "COMMUNICATE") {
            const {
              data: itqObj,
            } = await instructionToQuoteCrud.getInstructionToQuote(
              this.props.instructionToQuote.instructiontoquoteid
            );
            if (!itqQuotableStatuses.includes(itqObj.status)) {
              throw new Error(this.props.intl.formatMessage({ id: 'CONTRACT.WF.QUOTES.WARNING.CEN_UPDATED' }),);

            }

            const itq = { ...itqObj };
            const compInitialStatus = itq.status;

            const quote = { ...this.state.notification };
            quote.createbyid = this.props.contractUser.accountid;
            // quote.notificationdatedisplay = moment().format("DD/MM/YYYY");
            const quoteResponseDays =
              compInitialStatus === "ARQ"
                ? this.props.globalResources.ITQ_REQUOTE_RESPONSE
                : this.props.globalResources.ITQ_QUOTE_RESPONSE;
            const quoteResponseDate = moment(
              addDays(new Date(), quoteResponseDays)
            ).format("DD/MM/YYYY");
            quote.quoteresponserequireddatedisplay = quoteResponseDate;
            quote.responserequireddatedisplay = quoteResponseDate;
            quote.createby = this.props.contractUser.name;
            quote.showtasknotification = 1;


            quote.referenceid = this.getQuoteReference(quote);
            const docBodyConfig = this.documentBodyConfig(quote, this.props.resources);
            const docConfig = await this.documentConfig(quote, docBodyConfig, this.props.contractUser.name, false, customFieldsWfSubTypes.ITQQUOTE, null, this.props.workflowItem.form_NotifyCompanyName);
            quote.pdfjsoncontent = JSON.stringify(docConfig);

            this.saveQuote(quote, /*itq*/null, null, null, this.state.modalMode);

          }
          else if (this.state.modalMode === 'SAVE_DRAFT') {
            const notification = { ...this.state.notification };
            notification.createbyid = this.props.contractUser.accountid;
            // notification.notificationdatedisplay = moment().format("DD/MM/YYYY");
            const quoteResponseDays = this.props.instructionToQuote.status === "ARQ" ? this.props.globalResources.ITQ_REQUOTE_RESPONSE : this.props.globalResources.ITQ_QUOTE_RESPONSE;
            const quoteResponseDate = moment(addDays(new Date(), quoteResponseDays)).format("DD/MM/YYYY");
            notification.responserequireddatedisplay = quoteResponseDate;
            notification.createby = this.props.contractUser.name;
            this.saveQuote(notification, null, null, null, this.state.modalMode, params ? params.userid : null);

          }
          else if (this.state.modalMode === "REPLY_DRAFT") {
            const reply = { ...this.state.reply };
            const quote = { ...this.state.notification };
            quote.draftquotestatus = reply.status;
            quote.draftreplycomment = reply.responsecomment;
            //quote.draftreplyproceed = reply.proceed;
            quote.draftreplystatus = "Drafted";
            quote.draftreplybyid = this.props.contractUser.accountid;
            if ((params || {}).userid) {
              quote.draftreplytoid = params.userid;
            }
            quote.draftreplydatedisplay = moment().format("DD/MM/YYYY");
            for (let key in reply) {
              if (key.startsWith("CF_")) {
                quote[key] = reply[key];
              }
            }
            this.saveQuote(quote, null, null, null, this.state.modalMode, (params || {}).userid);

          }
          else if (this.state.modalMode === 'DELETE_DRAFT') {
            const itq = { ...this.state.notification };
            const itqquotationid = itq.itqquotationid
            this.deleteInstructionToQuoteDraft(itqquotationid);

          }
          else if (this.state.modalMode === "REPLY_SEND") {
            const reply = { ...this.state.reply };
            if (!this.replyValidation(reply)) {
              //FINAL REPLY FIELDS CHECK FAILED
              throw new Error(this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_REPLY_FIELDS_MISSING' }))
            }
            const quote = { ...this.state.notification };
            quote.referenceid = this.getQuoteReference(quote);
            quote.responsecomment = reply.responsecomment;
            quote.status = reply.status;

            for (let key in reply) {
              if (key.startsWith("CF_")) {
                quote[key] = reply[key];
              }
            }

            this.onSubmitReply(quote, response);


          }

        }
      } catch (err) {
        this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        this.setState({ saving: false });
      }

    });
  };
  getQuoteReference = (quote) => {
    return quote.referenceid;
  }
  replyValidation = (reply) => {
    if (!reply.responsecomment) { return false; }
    return true;
  }


  deleteInstructionToQuoteDraft = async (itqquotationid) => {

    try {
      await itqQuotationCrud.deleteInstructionToQuoteDraft(itqquotationid);
      this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_DELETED' }), { variant: 'success' });
      this.setState({ saving: false, navigateto: this.props.workflowItem.paths.listPath });

    } catch (err) {
      this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
      this.setState({ saving: false });
    }

  }


  saveQuote = async (quote, itq, assessment, contractSpecifics, action, notifyuserid, response) => {
    //const { companyid, projectid, contractid } = this.props.contract;
    const postEvents = () => {
      //this.notify(action, quote, notifyuserid);

      const state = {
        itemBaseProps: {
          reftype: "Itq",
          seqno: null,
          wfgid: 118,
          wfid: 73,
          refid: this.props.params.notificationid,
          path: this.props.workflowsQA['73'].paths.basePath + this.props.params.notificationid
        },
        itemCustomProps: quote
      };

      if (response === "CREATE") {
        this.setState({
          saving: false,
          isChanged: false,
          navigateto: this.props.workflowsQA['72'].paths.createPath,
          navigatetostate: state
        });
      } else {
        this.fetchQuote();


        this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.SAVED_SUCCESS' }), {
          variant: "success",
        });
      }
    };

    try {
      if (quote.startdate) {
        quote.startdatedisplay = moment(quote.startdate).format('DD/MM/YYYY');
      }
      if (quote.completiondate) {
        quote.completiondatedisplay = moment(quote.completiondate).format('DD/MM/YYYY');
      }
      if (!quote.createdatedisplay && quote.createdate) {
        quote.createdatedisplay = moment(quote.createdate).format("DD/MM/YYYY");
      }
      if (!quote.responserequireddatedisplay && quote.responserequireddate) {
        quote.responserequireddatedisplay = moment(quote.responserequireddate).format(
          "DD/MM/YYYY"
        );
      }
      if (!quote.draftreplydatedisplay && quote.draftreplydate) {
        quote.draftreplydatedisplay = moment(quote.draftreplydate).format(
          "DD/MM/YYYY"
        );
      }
      quote.replyingparty = this.props.workflowItem.replyingParty;
      quote.actioncode = "QUOTE_" + action;
      quote.notifyuserlist = this.getNotifyUserListForUpdate(action, notifyuserid);
      Common.PropertyConversionsBeforeNoticeSaveRequest(quote, this.props.customFieldsCommon, this.props.contractUsers);
      await itqQuotationCrud.updateItqQuote(quote);


      postEvents();
    } catch (err) {
      this.props.enqueueSnackbar(err.toString(), { variant: "error" });
      this.setState({
        saving: false
      });
    }
  };
  fieldChanged = (field, value, isDxFormValid, additionalData) => {
    //SILENT STATE CHANGE
    //WE DONT WANT COMPONENT RERENDER WİTH THIS
    //this.setState({ notification: { ...this.state.notification, [field]: value } });
    if (field) {
      if (field === "assetid") {
        this.setState({
          notification: { ...this.state.notification, [field]: value, asset: { ...additionalData } },
          isChanged: true
        });
      }
      else {
        this.state.notification[field] = value;

        if (!this.state.isChanged) {
          this.setState({ isChanged: true });
        }
      }
    }

    // if (typeof isDxFormValid !== 'undefined' && isDxFormValid !== null) {
    //     if (isDxFormValid !== this.state.isDxFormValid) {
    //         this.setState({ isDxFormValid: isDxFormValid });
    //     }
    // }
  };
  replyFieldChanged = (field, value, isDxReplyFormValid, isCheckBoxValue) => {
    //SILENT STATE CHANGE
    //WE DONT WANT COMPONENT RERENDER WİTH THIS
    //this.setState({ notification: { ...this.state.notification, [field]: value } });
    if (field) {
      this.state.reply[field] = value;
      if (!this.state.isChanged) {
        this.setState({ isChanged: true });
      }
    }
  };

  renderToolbar = (documentIsFinal) => {
    const saveDisabled = (this.state.saving || this.state.showModal);
    return (
      <PageToolbar
                key={this.state.notification.version}
        documentIsFinal={documentIsFinal}
        saveDisabled={saveDisabled}
        isAwaitingReply={this.state.isAwaitingReply}
        actions={{
          showModal: this.showModal,
          onPrintReply: this.printAcceptanceHandler,
          onPrint: this.printHandler
        }}
        workflowItem={this.props.workflowItem}
        parentWorkflowItem={this.props.parentWorkflowItem}
        match={this.props.match}
        customFieldValues={this.state.notification.customfieldvalues}
        referenceid={this.state.notification.referenceid}
        contract={this.props.contract}
        relationParams={{
          subject: this.getMailDescription(),
          assetid: this.state.notification.assetid
        }}
        notification={this.state.notification}
        selectedClausetypeDisplay={this.getSelectedTypeClauseDisplay()}
      />
    );
  };
  tabDocument = () => {
    return (
      <QuoteDocument
        {...this.props}
        validatorRef={this.validatorRef}
        printDivRef={this.printIssueDivRef}
        valueChangeHandler={this.fieldChanged}
        notification={this.state.notification}
        files={this.state.files}

        notificationid={this.props.params.quotationid}
      />
    );
  };

  //   tabCostBreakdown = () => {
  //     return (
  //       <CostBreakdown
  //         {...this.props}
  //         notification={this.state.notification}
  //       />
  //     );
  //   };

  onSubmitReply = async (quote, response) => {
    // quote.responseresponsedatedisplay = moment().format("DD/MM/YYYY");
    quote.replybyid = this.props.contractUser.accountid;
    let contractSpecifics = null;
    const { data: itqObj } = await instructionToQuoteCrud.getInstructionToQuote(
      this.props.instructionToQuote.instructiontoquoteid
    );

    if (!itqQuotableStatuses.includes(itqObj.status)) {
      this.props.enqueueSnackbar(
        this.props.intl.formatMessage({ id: 'CONTRACT.WF.QUOTES.WARNING.CEN_UPDATED_REPLY' }),
        { variant: "error", key: "PersistentFormWarning", persist: true }
      );
      this.setState({ sendingReply: false });
      return;
    }

    /*
    const itq = { ...itqObj };
    const { companyid, projectid, contractid } = this.props.contract;

    //Accepted
    if (quote.status === 1) {
      itq.status = "QA";
      itq.implementedamount = quote.amount;
      itq.quoteresponsereceiveddatedisplay = moment().format("DD/MM/YYYY");
    }
    //Not Accepted - Consultant to Resubmit
    else if (quote.status === 0) {
      const { data: allQuotes } = await itqQuotationCrud.getItqQuotes(companyid, projectid, contractid, quote.instructiontoquoteid)
      const allQuotesList = JSON.parse(allQuotes.aggregatelist);
      const replyPendingQuotes = allQuotesList.filter(Q => Q.itqquotationid !== Number(this.props.params.quotationid) && Q.showtasknotification === 1 && Q.status === 2);
      if (replyPendingQuotes && replyPendingQuotes.length > 0) {
        //There are other awaiting Reply Quotes
        //Keep the Itq status in APMRQ - Awaiting PM Response to quote
        itq.status = 'APMRQ';
      }
      else {
        itq.quotereceiveddatedisplay = null;
        itq.quoteresponserequireddatedisplay = null;
        itq.quoteresponsereceiveddatedisplay = null;
        itq.quoteresponserequireddate = null;
        itq.status = 'ARQ';
      }

      const quoteDate = moment(
        addDays(new Date(), this.props.globalResources.ITQ_REQUOTE_DURATION)
      ).format("DD/MM/YYYY");
      itq.quoterequireddatedisplay = quoteDate;
    }
    //Not Accepted - SM to make own Assessment
    else if (quote.status === 3) {
      itq.status = "APMA";
      const quoteDate = moment(
        addDays(new Date(), this.props.globalResources.ITQ_QUOTE_DURATION)
      ).format("DD/MM/YYYY");
      itq.quoteresponserequireddatedisplay = quoteDate;
      itq.quoteresponsereceiveddatedisplay = moment().format("DD/MM/YYYY");
    }
    //Not Accepted - Decision will not be given
    else if (quote.status === 5) {
      itq.status = "DNG";
      itq.quoteresponsereceiveddatedisplay = moment().format("DD/MM/YYYY");
    }
    */

    const docReplyBodyConfig = this.documentReplyBodyConfig(quote, this.props.resources, this.replyOptions);
    const docReplyConfig = await this.documentConfig(quote, docReplyBodyConfig, this.props.contractUser.name, true, customFieldsWfSubTypes.ITQQUOTE_REPLY, null, this.props.workflowItem.form_ReplyCompanyName);
    quote.pdfreplyjsoncontent = JSON.stringify(docReplyConfig);

    this.saveQuote(
      quote,
      //itq,
      null,
      null,
      contractSpecifics,
      this.state.modalMode,
      null,
      response
    );
  };
  documentBodyConfig = (notification, resources) => {
    const bodyConfig = [
      { resource: "GENERAL.FORMS.FIELD.SUBJECT", value: notification.subject }
    ];

    if (resources.QuoteNoteInstructionToQuote) {
      bodyConfig.push(
        { note: resources.QuoteNoteInstructionToQuote.replace('$reference', this.props.instructionToQuote.referenceid), valueType: 'NOTE' },
      );
    }

    if (this.props.fields.Description) {
      bodyConfig.push({ label: this.props.fields.Description, value: notification.description, valueType: 'HTML', align: 'vertical' })
    }
    if (this.props.fields.Comments) {
      bodyConfig.push({ label: this.props.fields.Comments, value: notification.comments, valueType: 'HTML', align: 'vertical' })
    }
    if (this.props.fields.ItemsList) {
      bodyConfig.push({ label: this.props.fields.ItemsList, value: notification.itemslist })
    }
    if (this.props.fields.TotalListPrice) {
      bodyConfig.push({ label: this.props.fields.TotalListPrice, value: Common.FormatCurrencyNum(notification.totallistprice, (this.props.contract || {}).currency), valueType: 'CURRENCY', currencyCode: (this.props.contract || {}).currency })
    }
    if (this.props.fields.InstructionToQuoteDescription) {
      bodyConfig.push({ label: this.props.fields.InstructionToQuoteDescription, value: notification.instructiontoquotedescription, dataField: 'instructiontoquotedescription' })
    }
    if (this.props.fields.TotalAssessed) {
      bodyConfig.push({ label: this.props.fields.TotalAssessed, value: Common.FormatCurrencyNum(notification.totalassessed, (this.props.contract || {}).currency), valueType: 'CURRENCY', currencyCode: (this.props.contract || {}).currency })
    }
    if (this.props.fields.TotalPrice) {
      bodyConfig.push({ label: this.props.fields.TotalPrice, value: Common.FormatCurrencyNum(notification.totalprice, (this.props.contract || {}).currency), valueType: 'CURRENCY', currencyCode: (this.props.contract || {}).currency })
    }
    if (this.props.fields.StartDate) {
      bodyConfig.push({ label: this.props.fields.StartDate, value: notification.startdate })
    }
    if (this.props.fields.CompletionDate) {
      bodyConfig.push({ label: this.props.fields.CompletionDate, value: notification.completiondate })
    }
    if (this.props.fields.DelayAmount) {
      bodyConfig.push({ label: this.props.fields.DelayAmount, value: Common.FormatCurrencyNum(notification.delayamount, (this.props.contract || {}).currency), valueType: 'CURRENCY', currencyCode: (this.props.contract || {}).currency })
    }
    if (this.props.fields.DelayPaymentPeriod) {
      bodyConfig.push({ label: this.props.fields.DelayPaymentPeriod, value: notification.delaypaymentperiod })
    }



    return bodyConfig;
  }
  documentReplyBodyConfig = (notification, resources, replyOptions) => {
    let reply = '[UNKNOWN]';
    if (notification.status !== null && typeof notification.status !== 'undefined') {
      const statusItem = this.quoteStatuses.find(x => x.field === notification.status.toString());
      if (statusItem) {
        reply = statusItem.display;
      }
    }

    const config = [
      { resource: "CONTRACT.WF.QUOTES.FIELD.TITLE", value: notification.subject },
      { resource: "CONTRACT.WF.QUOTES.FIELD.AMOUNT", value: Common.FormatCurrencyNum(notification.totalprice, (this.props.contract || {}).currency), valueType: 'CURRENCY', currencyCode: (this.props.contract || {}).currency },
      { resource: "CONTRACT.WF.QUOTES.FIELD.ASSESSMENT_STATUS", value: reply },
      { resource: "CONTRACT.WF.QUOTES.FIELD.ASSESSMENT_COMMENT", value: notification.responsecomment, valueType: 'HTML', align: 'vertical' },

    ];


    return config;
  }
  tabReply = () => {
    if (
      !this.props.workflowItem.userFilters.replyNotice.communicateReplyAllowed &&
      !this.props.workflowItem.userFilters.replyNotice.saveDraftReplyAllowed &&
      this.state.isAwaitingReply
    ) {
      return (
        <div style={{ width: "100%", height: "100%", display: "flex" }}>
          <div style={{ width: "100%", maxWidth: "900px", margin: "20px" }}>
            <Alert variant="warning">
              <p>
                <FormattedMessage id="GENERAL.FORMS.WARNING_AWAITING_REPLY" values={{ ITEM_NAME: this.props.workflowItem.pageTitle }} />
              </p>
            </Alert>
          </div>
        </div>
      );
    }
    else if (this.state.notification.status === 4) {
      ///Other Quote Approved
      return (
        <div style={{ width: "100%", height: "100%", display: "flex" }}>
          <div style={{ width: "100%", maxWidth: "900px", margin: "20px" }}>
            <Alert variant="warning">
              <p>
                {this.quoteStatuses.find(x => x.field === "4").display}
              </p>
            </Alert>
          </div>
        </div>
      );
    }
    else {
      return (
        <ReplyDocument
          {...this.props}
          quoteStatuses={this.quoteStatuses}
          isAwaitingReply={this.state.isAwaitingReply}
          printDivRef={this.printAcceptanceDivRef}
          fieldChanged={this.replyFieldChanged}
          notification={this.state.notification}
          reply={this.state.reply}

          files={this.state.replyFiles}
          notificationid={this.props.params.quotationid}
        />
      );
    }
  };
  tabEmailNotification = () => {

    return (
      <EmailNotifications
        {...this.props}
        notificationDate={this.state.notification.notificationdate}
      />
    );

  };
  render() {
    if (
      !this.state.notification ||
      !this.state.files ||
      !this.state.replyFiles
    ) {
      return <PortletSpinner />;
    }
    const documentIsFinal = this.state.notification.showtasknotification === 1;

    if (!documentIsFinal && !this.props.workflowItem.userFilters.mainNotice.viewDraftAllowed) {
      return <Navigate to={".."} />;
    }

    return (
      <Portlet>
        <ReactToPrint
          ref={(el) => (this.printIssueRef = el)}
          trigger={() => <div style={{ display: "none" }}></div>}
          content={() => this.printIssueDivRef.current}
        />
        <ReactToPrint
          ref={(el) => (this.printAcceptanceRef = el)}
          trigger={() => <div style={{ display: "none" }}></div>}
          content={() => this.printAcceptanceDivRef.current}
        />
        <NavigationBlocker
          when={(this.state.isChanged || this.state.saving)}
          message={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.WARNING_UNSAVED' })}
        />
        {this.state.navigateto &&
          (
            <Navigate replace to={this.state.navigateto} state={this.state.navigatetostate} />
          )
        }
        <PortletHeader
          title={documentIsFinal ? this.props.workflowItem.pageTitle : this.props.intl.formatMessage({ id: "GENERAL.FORMS.DRAFT_TITLE" }, { ITEM_NAME: this.props.workflowItem.pageTitle })}
          toolbar={this.renderToolbar(documentIsFinal)}
        />
        <PortletBody>
          <ValidationGroup ref={this.validatorRef} name={this.validatorName}>
            <div>
              <ValidationSummary
                elementAttr={{ align: "right", paddingBottom: 50 }}
                id={this.validatorName}
              ></ValidationSummary>
            </div>
            <TabPanel
              ref={(ref) => this.tabPanel = ref}
              width="100%"
              deferRendering={false}
              animationEnabled={true}
              focusStateEnabled={true}
              hoverStateEnabled={true}
              swipeEnabled={false}
            >
              <Item
                icon="file"
                title={this.props.title}
                render={this.tabDocument}
              />
              <Item
                icon="rename"
                title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_REPLY' })}
                render={documentIsFinal ? this.tabReply : null}
                visible={documentIsFinal}
              />
              {/* {
                this.wbsEnabled && (
                  <Item
                    icon="bulletlist"
                    title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_COST_BREAKDOWN' })}
                    render={this.tabCostBreakdown}
                  />
                )
              } */}
              <Item
                icon="bulletlist"
                title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.TAB_EMAIL_NOTIFICATIONS' })}
                render={documentIsFinal ? this.tabEmailNotification : null} visible={documentIsFinal}
              />
            </TabPanel>
          </ValidationGroup>
        </PortletBody>
        <ActionModal
          visible={this.state.showModal}
          title={this.state.modalTitle}
          text={this.state.modalText}
          mode={this.state.modalMode}
          onDialogResult={this.onModalResponse}
          workflowItem={this.props.workflowItem}
          userLimit={(this.props.workflowItem.financialLimitEnabled && this.state.modalMode === 'REPLY_DRAFT') ? this.state.notification.totalprice : null}
          secondaryButtonEnabled={this.state.projectOrderButtonEnabled}
          secondaryButtonItemName={(this.props.workflowsQA['72'] || {}).notificationName}
        />
      </Portlet>
    );
  }
}


export default withDetailPageProps(QuoteDetailPage);