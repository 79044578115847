import React from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";
import ExpandableNotifyControl from './ExpandableNotifyControl';
import DraftNotifyArea from './DraftNotifyArea';
// import ManualActionArea from './ManualActionArea';
import { propertySummary } from '../../../../store/helpers/DashboardHelper'
import { injectIntl } from "react-intl";

const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false
};


class TabNotify extends React.Component {

    render() {
        let draftWarningCount = 0;
        let replyDraftWarningCount = 0;

        if (this.props.drafts && this.props.dashboard) {
            draftWarningCount = propertySummary(this.props.dashboard, this.props.drafts);
        }

        if (this.props.replyDrafts && this.props.dashboard) {
            replyDraftWarningCount = propertySummary(this.props.dashboard, this.props.replyDrafts);
        }

        return (
            <PerfectScrollbar
                ref={(ref) => { this._scrollBarRef = ref; }}
                options={perfectScrollbarOptions}
                style={{ maxHeight: "calc(100vh - 100px)", position: "relative" }}>
                <div className="kt-notification">
                    {
                        this.props.actions && (
                            this.props.actions.map((item, index) => (
                                <ExpandableNotifyControl
                                    key={item.actionCode}
                                    panelCloseBtnRef={this.props.panelCloseBtnRef}
                                    contractid={this.props.contractid}
                                    isOpen={this.props.isOpen}
                                    onSizeChanged={() => { if (this._scrollBarRef) { this._scrollBarRef.forceUpdate(); } }}
                                    // warningCount={this.props.dashboard[item.actionCode]}
                                    item={item}
                                    party={this.props.party}
                                />
                            )))
                    }
                    {
                        (this.props.drafts && this.props.drafts.length > 0) && (
                            <DraftNotifyArea
                                title={this.props.intl.formatMessage({ id: 'GENERAL.ACTIONS.TITLE.DRAFTS' })}
                                dashboard={this.props.dashboard}
                                panelCloseBtnRef={this.props.panelCloseBtnRef}
                                contractid={this.props.contractid}
                                isOpen={this.props.isOpen}
                                warningCount={draftWarningCount}
                                items={this.props.drafts}
                                party={this.props.party}
                            />
                        )
                    }
                    {
                        (this.props.replyDrafts && this.props.replyDrafts.length > 0) && (
                            <DraftNotifyArea
                                title={this.props.intl.formatMessage({ id: 'GENERAL.ACTIONS.TITLE.REPLY_DRAFTS' })}
                                dashboard={this.props.dashboard}
                                panelCloseBtnRef={this.props.panelCloseBtnRef}
                                contractid={this.props.contractid}
                                isOpen={this.props.isOpen}
                                warningCount={replyDraftWarningCount}
                                items={this.props.replyDrafts}
                                party={this.props.party}
                            />
                        )
                    }

                </div>
            </PerfectScrollbar>
        );
    }
}

export default injectIntl(TabNotify);