import axios from "../shared/axios";

export function getOptions() {
  return axios.get("/api/Option/GetPublicOption");
}


export function getCompanyAreas(companyid = 1) {
  return axios.post(
    `/api/CompanyAreaHub/LoadCompanyAreaHubsList?companyid=${companyid}&category=area`
  );
}

export function getCompanyHubs(companyid = 1) {
  return axios.post(
    `/api/CompanyAreaHub/LoadCompanyAreaHubsList?companyid=${companyid}&category=hub`
  );
}

export function createCompanyAreaOrHub(data) {
  return axios.post("/api/CompanyAreaHub/SaveCompanyAreaHub", data);
}

export function getSmartLinks() {
  return axios.get("/api/SystemLinks/GetList");
}

export function copyNotificationToOtherContracts(data) {
  let notificationController = '';

  switch (data.groupid) {
    case 101:
      notificationController = 'EarlyWarning';
      break;
    default:
      break;
  }
  
  return new Promise((resolve, reject) => {
      axios.post(`/api/${notificationController}/CopyNotificationToOtherContracts`, data)
          .then(result => {
              resolve(result);
          })
          .catch(error => reject(error));
  });
}