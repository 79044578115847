/* eslint-disable no-redeclare */
import ExcelJS from 'exceljs';
import moment from 'moment';
import { recurrencyOptions, weeklyOptions } from './ContractActionCreateModal';
import { typeOptions } from '../ContractActions';


const frequencyOptionKeys = recurrencyOptions.map(t => t.value);
const weekdayOptionKeys = weeklyOptions.map(t => t.value);
const partyTypeKeys = ["SUPPLIER", "PM", "SUPERVISOR", "CLIENT"]

export default class ContractActionExcelHelper {

    static processExcelFile = (file, contractid, actionTypes) => {
        const typeOptionKeys = actionTypes.map(t => t.value);
        return new Promise((resolve, reject) => {
            const wb = new ExcelJS.Workbook();
            const reader = new FileReader();

            reader.readAsArrayBuffer(file);
            reader.onload = async () => {
                try {
                    const buffer = reader.result;
                    const workbook = await wb.xlsx.load(buffer);
                    const extractedData = [];

                    if (!workbook || !workbook.worksheets[0]) {
                        throw new Error("worksheet not found!")
                    }
                    if (workbook.worksheets[0].rowCount > 102) {
                        throw new Error("Can not process more than 100 rows!")
                    }
                    workbook.worksheets[0].eachRow((row, rowIndex) => {
                        if (rowIndex > 2 && row.values && (row.values[1] || row.values[2] || row.values[3] || row.values[4] || row.values[5])) {
                            ContractActionExcelHelper.validateExcelRow(row, rowIndex, typeOptionKeys)
                            const obj = {
                                contractid: contractid,
                                ownerparty: row.values[1],
                                type: row.values[2],
                                subject: row.values[3],
                                description: row.values[4],
                                isrecurrent: row.values[5] === "YES" ? 1 : 0,
                                duedatedisplay: ContractActionExcelHelper.getDateDisplayFromCell(row.values[6]),
                                startdatedisplay: ContractActionExcelHelper.getDateDisplayFromCell(row.values[7]),
                                enddatedisplay: ContractActionExcelHelper.getDateDisplayFromCell(row.values[8]),
                                frequency: row.values[9],
                                weekday: row.values[10],
                                dayofmonth: row.values[11],
                            }
                            extractedData.push(obj);
                        }
                    });
                    resolve(extractedData);
                }
                catch (err) {
                    reject(err);
                }
            };
            reader.onerror = (error) => {
                reject(error);
            };
        })
    }
    static validateExcelRow = (row, rowIndex, typeOptionKeys) => {
        try {
            if (!partyTypeKeys.includes(row.values[1])) { throw new Error(`Invalid PartyType`) }
            if (!typeOptionKeys.includes(row.values[2])) { throw new Error(`Invalid Type`) }
            if (!row.values[3]) { throw new Error(`Subject is required`) }
            if (!row.values[4]) { throw new Error(`Description is required`) }
            if (!["YES", "NO"].includes(row.values[5])) { throw new Error(`Invalid Recurrent Option`) }
            const isrecurrent = row.values[5] === "YES";
            if (isrecurrent) {
                if (!row.values[7]) { throw new Error(`Start Date is required`) }
                ContractActionExcelHelper.getDateDisplayFromCell(row.values[7], moment().subtract(10,'years'), moment().add(10, 'years'))
                if (!row.values[8]) { throw new Error(`End Date is required`) }
                ContractActionExcelHelper.getDateDisplayFromCell(row.values[8], moment().subtract(10,'years'), moment().add(10, 'years'))
                if (!frequencyOptionKeys.includes(row.values[9])) { throw new Error(`Invalid Frequency Option`) }

                const frequency = row.values[9];
                if (frequency === "WEEKLY") {
                    if (!weekdayOptionKeys.includes(row.values[10])) { throw new Error(`Invalid Weekday Option`) }
                }
                if (frequency === "MONTHLY") {
                    if (!row.values[11]) { throw new Error(`Day of Month is required`) }
                    const dayOfMonth = row.values[11];
                    if (!(dayOfMonth >= 1 && dayOfMonth <= 28)) { throw new Error(`Day of Month out of range 1-28`) }
                }
            }
            else {
                if (!row.values[6]) { throw new Error(`Due Date is required`) }
                ContractActionExcelHelper.getDateDisplayFromCell(row.values[6], moment().subtract(10,'years'), moment().add(10, 'years'))
            }
        } catch (err) {
            throw new Error(`Row:${rowIndex} ${err.toString()}!`)
        }

    }
    static getDateDisplayFromCell = (cellValue, minMoment, maxMoment) => {
        try {
            if (cellValue) {
                const dateVal = cellValue.result ? cellValue.result : cellValue;
                const momentObj = moment(dateVal);
                if (momentObj.isValid()) {
                    if (minMoment) {
                        if (momentObj.isBefore(minMoment.startOf('day'))) {
                            throw new Error(`Date can not be before ${minMoment.format('DD/MM/YYYY')}`)
                        }
                    }
                    if (maxMoment) {
                        if (momentObj.isAfter(maxMoment)) {
                            throw new Error(`Date can not be after ${maxMoment.format('DD/MM/YYYY')}`)
                        }
                    }
                    return momentObj.format('DD/MM/YYYY');
                }
                else {
                    throw new Error("Invalid Date")
                }
            }

        } catch (err) {
            throw err;
        }

    }
}