/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import KTOffcanvas from "../../../_metronic/_assets/js/offcanvas";
import { Badge } from "@mui/material";
import { FormattedMessage } from "react-intl";


export default class StickyToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'DEV';
  }
  componentDidMount() {

    const panel1 = document.getElementById("kt_quick_panel_1");
    this.offCanvas1 = new KTOffcanvas(panel1, {
      overlay: true,
      baseClass: "kt-quick-panel",
      closeBy: "kt_quick_panel_close_btn_1",
      toggleBy: "kt_quick_panel_toggler_btn_1",
    });
    this.offCanvas1.on('afterHide', () => { this.updatePanelStatus(1, false); })
    this.offCanvas1.on('afterShow', () => { this.updatePanelStatus(1, true); })

    if (this.props.actionsEnabled) {
      const panel2 = document.getElementById("kt_quick_panel_2");
      this.offCanvas2 = new KTOffcanvas(panel2, {
        overlay: true,
        baseClass: "kt-quick-panel",
        closeBy: "kt_quick_panel_close_btn_2",
        toggleBy: "kt_quick_panel_toggler_btn_2",
      });
      this.offCanvas2.on('afterHide', () => { this.updatePanelStatus(2, false); })
      this.offCanvas2.on('afterShow', () => { this.updatePanelStatus(2, true); })
    }

    // if (this.isDevelopment) {
    //   const panel3 = document.getElementById("kt_quick_panel_3");
    //   this.offCanvas3 = new KTOffcanvas(panel3, {
    //     overlay: true,
    //     baseClass: "kt-quick-panel",
    //     closeBy: "kt_quick_panel_close_btn_3",
    //     toggleBy: "kt_quick_panel_toggler_btn_3",
    //   });
    //   this.offCanvas3.on('afterHide', () => { this.updatePanelStatus(3, false); })
    //   this.offCanvas3.on('afterShow', () => { this.updatePanelStatus(3, true); })
    // }
  }

  updatePanelStatus = (panelNum, isOpen) => {
    this.props.onToggle(panelNum, isOpen);
  }

  render() {
    return (
      <>
        <ul className="kt-sticky-toolbar" >

          <OverlayTrigger
            placement="left"
            overlay={<Tooltip id="layout-tooltip"><FormattedMessage id="GENERAL.WORDS.NOTIFICATIONS" /></Tooltip>}
          >
            <Badge
              anchorOrigin={{ horizontal: "left", vertical: 'top' }}
              invisible={!(this.props.warningCount)}
              badgeContent={this.props.warningCount}
              color="error"
              overlap="rectangular"
            >
              <li
                className="kt-sticky-toolbar__item kt-sticky-toolbar__item--brand"
                data-placement="left"
              // style={{ marginBottom: '5px' }}
              >
                <span
                  ref={this.props.panelCloseBtnRef1}
                  className="kt-header__topbar-icon"
                  id="kt_quick_panel_toggler_btn_1"
                >
                  <i className="flaticon2-bell-2" />
                </span>
              </li>
            </Badge>
          </OverlayTrigger>
          {
            this.props.actionsEnabled && (
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip id="layout-tooltip"><FormattedMessage id="GENERAL.WORDS.ACTIONS" /></Tooltip>}
              >
                <Badge
                  anchorOrigin={{ horizontal: "left", vertical: 'top' }}
                  invisible={!(this.props.actionCount)}
                  badgeContent={this.props.actionCount}
                  color="error"
                  overlap="rectangular"
                >
                  <li
                    className="kt-sticky-toolbar__item kt-sticky-toolbar__item--brand"
                    data-placement="left"
                  // style={{ marginBottom: '5px' }}
                  >
                    <span
                      ref={this.props.panelCloseBtnRef2}
                      className="kt-header__topbar-icon"
                      id="kt_quick_panel_toggler_btn_2"
                    >
                      <i className="flaticon2-check-mark" />
                    </span>
                  </li>
                </Badge>
              </OverlayTrigger>
            )
          }

          {/* {
            this.isDevelopment && (
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip id="layout-tooltip">BI - Blog Posts</Tooltip>}
              >
                <Badge 
                anchorOrigin={{ horizontal: "left", vertical: 'top' }}
                  invisible={true}
                  badgeContent={this.props.warningCount}
                  color="error"
                  overlap="rectangular"
                >
                  <li
                    className="kt-sticky-toolbar__item kt-sticky-toolbar__item--brand"
                    data-placement="left"
                  >
                    <span
                      ref={this.props.panelCloseBtnRef2}
                      className="kt-header__topbar-icon"
                      id="kt_quick_panel_toggler_btn_2"
                    >
                      <i className="flaticon2-rectangular" />
                    </span>
                  </li>
                </Badge>
              </OverlayTrigger>
            )
          } */}
          {/* {
            this.isDevelopment && (
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip id="layout-tooltip">Community Activity</Tooltip>}
              >
                <Badge
                  anchorOrigin={{ horizontal: "left", vertical: 'top' }}
                  invisible={true}
                  badgeContent={this.props.warningCount}
                  color="error"
                  overlap="rectangular"
                >
                  <li
                    className="kt-sticky-toolbar__item kt-sticky-toolbar__item--brand"
                    data-placement="left"
                  >
                    <span
                      ref={this.props.panelCloseBtnRef3}
                      className="kt-header__topbar-icon"
                      id="kt_quick_panel_toggler_btn_3"
                    >
                      <i className="flaticon2-group" />
                    </span>
                  </li>
                </Badge>
              </OverlayTrigger>
            )
          } */}



        </ul>
      </>
    );
  };
}
