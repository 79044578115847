import React from 'react';
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import DataGridBase from "../../../../partials/layout/DataGridBase";
import { Badge } from "react-bootstrap";
import * as paymentCertificateCrud from '../../../../crud/paymentCertificate.crud';
import { notificationAggregateListTagsFilter, notificationUniqueTagsFilter } from "../../../../store/helpers/FilterHelper";
import CustomFieldHelper from "../../_Helpers/CustomFieldHelper";
import { withListPageProps } from '../../../../shared/hoc/withListPageProps';
import CurrencyBatchModal from '../Batch/CurrencyBatchModal/CurrencyBatchModal';
import Common from '../../../../shared/common';


class PaymentListPage extends React.Component {
    constructor(props) {
        super(props);
        this.notificationsTagsEnabled = process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true' && props.contract.specifictags?.length > 0;
        this.notificationsRelsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true';
        this.paymentBatchEnabled = process.env.REACT_APP_PAYMENT_BATCH_ENABLED === 'true';
        this.customFields = [];
        if (props.customFields) {
            if (props.customFields.COMMUNICATION && props.customFields.COMMUNICATION.length > 0) {
                this.customFields.push(...(props.customFields.COMMUNICATION.filter(cf => cf.includelisting)));
            }
            if (props.customFields.REPLY && props.customFields.REPLY.length > 0) {
                this.customFields.push(...(props.customFields.REPLY.filter(cf => cf.includelisting)));
            }
        }
        this.batchWorkflowItem = this.props.workflowItem.config.currentModelType === 'Certificate' ?
            this.props.workflowsQA['78'] :
            this.props.workflowsQA['79'];
        this.multipleCurrencyWizardEnabled =
            process.env.REACT_APP_SECONDARY_PRICES_ENABLED === 'true'
            && process.env.REACT_APP_PAYMENT_BATCH_ENABLED === 'true'
            && Boolean(props.contract.otherprice)
            && this.batchWorkflowItem?.userFilters?.mainNotice?.createAllowed;

        this.additionalButtons = [];
        if (this.multipleCurrencyWizardEnabled) {
            this.additionalButtons.push(
                {
                    location: "after",
                    locateInMenu: "auto",
                    widget: "dxButton",
                    options:
                    {
                        icon: "plus",
                        onClick: () => { this.setState({ currencyBatchModalVisible: true }) },
                        text: "Create Batch",
                    }
                }
            );
        }

        this.state = {
            notifications: null,
            tags: null,
            currencyBatchModalVisible: false
        };
    }
    componentDidMount() {
        this.fetchPaymentCertificates();
    }
    fetchPaymentCertificates = async () => {
        const { companyid, projectid, contractid } = this.props.contract;
        paymentCertificateCrud.getPaymentCertificates(companyid, projectid, contractid, this.props.notificationType, this.props.accessibleWfIds)
            .then(response => {
                let listData = notificationAggregateListTagsFilter(response.data.aggregatelist);
                listData = (this.props.workflowItem.userFilters.mainNotice.viewDraftAllowed === true) ?
                    listData :
                    listData.filter(ntf => ntf.showtasknotification === 1);

                let tagFilters = notificationUniqueTagsFilter(response.data.uniquetags);

                this.setState({ notifications: listData, tags: tagFilters });
            })
            .catch(err => {
                this.props.enqueueSnackbar('Failed to load communications', { variant: 'error', });
            });
    }
    onTableSelectionChanged = ({ selectedRowsData }) => {
        if (selectedRowsData.length > 0) {
            const data = selectedRowsData[0];
            const url = this.calculateUrl(data, true);
            this.props.navigate(url);
        }
    }
    calculateUrl = (rowData, skipHostUrl) => {
        return (skipHostUrl ? '' : process.env.PUBLIC_URL) + this.props.workflowItem.paths.basePath + rowData.paymentcertificateid;
    }
    renderStatusColumn = (cellInfo) => {
        let badgeVariant = cellInfo.data.showtasknotification === 1 ? 'success' : 'secondary';
        const displayText = this.calculateStatusValue(cellInfo.data);
        return (<Badge variant={badgeVariant}>{displayText}</Badge>);
    }
    calculateStatusValue = (rowData) => {
        return this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.' + rowData.status });
    }
    renderCurrencyValue = (rowData) => {
        return rowData.data.currency;
    }
    formatCurrency = (rowData) => {
        return rowData.currency;
    }


    onCurrencyBatchClosed = (refreshIndicator) => {
        this.setState({ currencyBatchModalVisible: false }, () => {
            if (refreshIndicator) {
                this.fetchPaymentCertificates();
            }
        });
    }

    render() {
        if (!this.state.notifications) {
            return (<PortletSpinner />);
        }

        const columns = [
            { resource: "GENERAL.FORMS.FIELD.ID", dataField: "referenceid", dataType: 'string', renderReferenceidLink: true },
            { caption: this.props.notificationType === 'Application' ? this.props.resources.RegisterAppliedByCaption : this.props.resources.RegisterCertifiedByCaption, dataField: "communicatedby" },

        ];
        if (this.props.clauses) {
            columns.push(
                { resource: 'GENERAL.FIELD.APPLICATIONTYPE', dataField: 'paymenttype', lookup: { dataSource: this.props.clauses, valueExpr: 'field', displayExpr: 'display' } }
            );
        }
        columns.push(
            { caption: this.props.resources.AssessmentDayLabel, dataField: "assessmentdate", dataType: 'date' },
            { caption: this.props.notificationType === 'Application' ? this.props.resources.RegisterApplicationDateCaption : this.props.resources.RegisterCertifiedDateCaption, dataField: "paymentcertificateissueddate", dataType: 'date' },
            {
                resource: "CONTRACT.WF.PAYMENT.FIELD.AMOUNT",
                dataField: "othercertfiedamount",
                dataType: "number",
                cellRender: (e) => Common.FormatCurrencyNum(e.data.othercertfiedamount, e.data.currency)
            },
            { resource: "GENERAL.FORMS.FIELD.STATUS", dataField: "status", cellRender: this.renderStatusColumn, calculateCellValue: this.calculateStatusValue },
            { resource: "GENERAL.FORMS.FIELD.LINK", dataField: "url", calculateCellValue: this.calculateUrl, visible: false, showInColumnChooser: false },
            { resource: "GENERAL.FORMS.FIELD.CURRENCY", dataField: "currency", visible: process.env.REACT_APP_PAYMENT_BATCH_ENABLED === 'true', calculateCellValue: this.formatCurrency },
            { resource: "PROJECT.FIELD.CODE", dataField: "projectcode", visible: false },
            { resource: "PROJECT.FIELD.NAME", dataField: "project", visible: false }
        );
        if (this.paymentBatchEnabled) {
            columns.push(
                { resource: 'PAYMENTS.FIELD.BATCH_REFERENCE', dataField: 'paymentbatchreference' }
            );
        }
        if (this.props.poNumberEnabled) {
            columns.push(
                { resource: 'CONTRACT.FIELD.PO_NUMBER', dataField: 'purchaseordernumber', visible: false }
            );
        }

        if (this.props.workflowItem.assetFieldsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.FIELD.ASSET_NAME", dataField: "assetname", visible: false },
                { resource: "GENERAL.FORMS.FIELD.ASSET_TYPE", dataField: "assettype", visible: false },
            );
        }
        if (this.notificationsTagsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.FIELD.PORTFOLIO", dataField: "tags_portfolio", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.PortfolioFilter } },
                { resource: "GENERAL.FORMS.FIELD.PROGRAMME", dataField: "tags_programme", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.ProgrammeFilter } },
                { resource: "GENERAL.FORMS.FIELD.PROJECT", dataField: "tags_project", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.ProjectFilter } },
                { resource: "GENERAL.FORMS.FIELD.OTHER", dataField: "tags_other", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.OtherFilter } }
            )
        }
        if (this.notificationsRelsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.TAB_RELATIONS", dataField: "relatednotices", visible: false },
            )
        }
        columns.push(...CustomFieldHelper.MapCustomFieldsToDataGridColumns(this.customFields));

        return (
            <Portlet>
                <PortletBody>
                    <DataGridBase
                        version="5"
                        title={this.props.intl.formatMessage({ id: 'GENERAL.REGISTER.TITLE2' }, { ITEM_NAME: this.props.workflowItem.pageTitle })}
                        createPath={this.props.workflowItem.userFilters.mainNotice.createAllowed ? this.props.workflowItem.paths.createPath : null}
                        workflowItem={this.props.workflowItem}
                        name={this.props.workflowItem.pageTitle}
                        dataSource={this.state.notifications}
                        keyExpr="paymentcertificateid"
                        selection={{ mode: 'single' }}
                        onSelectionChanged={this.onTableSelectionChanged}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={false}
                        columns={columns}
                        additionalToolbarItems={this.additionalButtons}
                    />
                </PortletBody>
                {
                    this.multipleCurrencyWizardEnabled && (
                        <CurrencyBatchModal
                            {...this.props}
                            workflowItem={this.batchWorkflowItem}
                            visible={this.state.currencyBatchModalVisible}
                            onClose={this.onCurrencyBatchClosed}
                        />
                    )
                }
            </Portlet>
        );
    }
}

export default withListPageProps(PaymentListPage);
