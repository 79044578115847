import React from 'react';
import { Nav } from "react-bootstrap";
import { Badge } from '@mui/material';


export default function SideBarNavigationTab(props) {
    const { replyDrafts, drafts, actions } = props;
    
    let notification = null;
    if (props.warningCount && props.warningCount > 0) {
        notification = props.warningCount;
    }
    if ((actions && actions.length > 0) || (drafts && drafts.length > 0) || (replyDrafts && replyDrafts.length > 0)) {
        return (
            <Nav.Item className="nav-item">
                <Badge overlap="rectangular" badgeContent={notification} color="error">
                    <Nav.Link eventKey={props.eventKey} className="nav-link show">
                        {props.title}
                    </Nav.Link>
                </Badge>
            </Nav.Item>
        );
    }
    else {
        return null;
    }
}