import React from 'react';
import { connect } from "react-redux";
import * as fileCrud from '../../../../crud/file.crud';
import Document from '../../_CustomComponents/DocumentV2';
import BaseDocument from '../../_CustomComponents/BaseDocument';
import { Alert } from "react-bootstrap";
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import moment from 'moment';
import { FormattedMessage, injectIntl } from "react-intl";

//const replyOptionsCEN = [1, 0, 3];
const replyOptionsPRO = [1, 0, 3, 5];
//const replyOptionsPRO_NEC4 = [1, 0, 5];
//const replyOptionsCLAIMS = [1, 0, 3, 5];

class ReplyDocument extends BaseDocument {
    constructor(props) {
        super(props);
        this.quoteStatuses = props.quoteStatuses.map(x => ({ value: +x.field, display: x.display }))
            .filter(y => replyOptionsPRO.includes(y.value))
            .sort((a, b) => a.display.localeCompare(b.display));

        this.replyFormConfig = {
            currencyCode: this.props.contract.currency,
            fields: [
                { resource: "CONTRACT.WF.QUOTES.FIELD.AMOUNT", editorType: 'dxNumberBox', dataField: 'amount', value: () => props.notification.totalprice },
                { resource: "GENERAL.FORMS.FIELD.STATUS", dataField: 'status', editorType: 'dxSelectBox', editorOptions: { dataSource: this.quoteStatuses, valueExpr: 'value', displayExpr: 'display' } },
                { resource: "GENERAL.FORMS.FIELD.COMMENTS", dataField: 'responsecomment', editorType: 'dxHtmlEditor' },
            ]
        };

        this.isAsiteEnabled = process.env.REACT_APP_ASITE_ENABLED === 'true';
    }

    renderForm = () => {
        return (
            <>
                <BiDynamicForm
                    isReply={true}
                    item={this.props.reply}
                    config={this.replyFormConfig}
                    valueChangeHandler={this.props.fieldChanged}
                    customFields={(this.props.customFieldsCommon || {}).RFQQUOTE_REPLY}
                />
            </>
        );
    }

    uploadFile = (data, config) => {
        const { companyid, projectid, contractid } = this.props.contract;
        return fileCrud.uploadContractNotificationFiles(companyid, projectid, contractid, this.props.documentType + 'Reply', this.props.notification.rfqquotationid, data, config)
    }

    render() {
        const documentIsFinal = !this.props.isAwaitingReply;
        const showNote = !documentIsFinal && this.props.notification.draftreplydate;
        return (
            <>
                {
                    showNote && (
                        <Alert variant="secondary">
                            <p>
                                <FormattedMessage id="GENERAL.FORMS.WARNING_DRAFTED" values={{ PERSON_NAME: this.props.notification.draftreplyby, TIME_STAMP: moment(this.props.notification.draftreplydate).format('L') }} />
                                <br />
                                {
                                    this.props.notification.draftreplyto && (
                                        <FormattedMessage id="GENERAL.FORMS.WARNING_DRAFT_NOTIFIED" values={{ PERSON_NAME: this.props.notification.draftreplyto }} />
                                    )
                                }
                            </p>
                        </Alert>
                    )
                }
                <Document
                    key={documentIsFinal}
                    docConfig={this.props.notification.pdfreplyjsoncontent}
                    isFinal={documentIsFinal}
                    printDivRef={this.props.printDivRef}
                    workflowItem={this.props.workflowItem}
                    contract={this.props.contract}
                    reference={this.props.notification.referenceid}
                    form={documentIsFinal ? null : this.renderForm()}
                    uploadMethod={documentIsFinal ? null : this.uploadFile}
                    files={this.props.files}
                    notificationid={this.props.notificationid}
                    documentType={this.props.documentType}
                    isReply={true}
                    customFields={(this.props.customFieldsCommon || {}).RFQQUOTE_REPLY}
                    customFieldValues={this.props.notification.customfieldvalues}
                    assetid={this.props.requestForQuote.assetid}
                />
            </>
        );

    }
}

const mapStateToProps = store => ({
    // globalResources: store.contract.template.resources,
    // contract: store.contract.contract,
});

export default injectIntl(connect(mapStateToProps)(ReplyDocument));
