import { userFilterCategories } from "../../../shared/lookup";
const workflows = [
    {
        id: 72,
        title: '$PM_NAME Project Orders',
        notificationName: 'Project Order',
        abbreviation: '$PM_ABBREVIATION-PRO-',
        notifyingParty: 'PM',
        notify: true,
        financialLimitEnabled: false,
        assetFieldsEnabled: false,
        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'PM Project Orders',
            currentModel: 'ProjectOrder',
            currentModelType: '',
            referenceType: 'ProjectOrder',
            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: false,
            configurableFinancialLimit: true,
            configurableAssets: true,
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'pm',
                create: 'create',
                list: 'list',
                default: 'list'
            }
        }
    },
    {
        id: 73,
        title: '$PM_NAME Instruction To Quote',
        notificationName: 'Instruction To Quote',
        abbreviation: '$PM_ABBREVIATION-ITQ-',
        notifyingParty: 'PM',
        quotingParty: 'SUPPLIER',
        quoteReplyingParty: 'PM',
        quoteNotificationName:'PO-Quote',
        quoteReferencePrefix:'PO-Quote-',

        notify: true,
        financialLimitEnabled: false,
        assetFieldsEnabled: false,
        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,

        includePdfjson: false,
        
        config: {
            description: 'PM Instruction To Quote',
            currentModel: 'Itq',
            currentModelType: '',
            referenceType: 'Itq',
            quotationType: 'ITQPM',
            quotationDocumentType: 'DocumentITQPMQ',

            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: false,
            quotationPossible: true,
            configurableFinancialLimit: true,
            configurableAssets: true,
            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.QUOTATION,
                userFilterCategories.QUOTE_REPLY,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'itq',
                create: 'create',
                list: 'list',
                default: 'list',
                quote:'itqquote'
            }
        }
    }
]

export default workflows;