import React, { Component } from 'react';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import DataGrid, { Editing, Scrolling, Export, HeaderFilter, SearchPanel, ColumnChooser, Form, Toolbar, Item as ToolbarItem } from 'devextreme-react/data-grid';
import * as activityCrud from '../../../../crud/activity.crud';
import * as contractDuck from '../../../../store/ducks/contract.duck';
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import { GetSecondaryDateTypes } from '../../../../shared/lookup';
import moment from 'moment';
import { injectIntl } from "react-intl";
import { dxDateBoxProps } from '../../../../shared/config/DxFormProperties';
import ContractSecondaryDateDetails from './ContractSecondaryDateDetails';
import { Item } from 'devextreme-react/form';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

const manuallyEditableActionTypes = ['KeyDate', 'Sectional'];

class ContractSecondaryDates extends Component {

    constructor(props) {
        super(props);
        this.allowEdit = this.props.protectedRouteProps.allowEdit;
        this.contractDateTypes = GetSecondaryDateTypes(props.lang);
        this.newKeyAndCompletionDateFormConfig = {
            buttonResource: 'GENERAL.FORMS.BUTTON_ADD',
            fields: [
                {
                    resource: 'CONTRACT.SECONDARY.SUBTITLE',
                    fields: [
                        { resource: 'CONTRACT.SECONDARY.FIELD.NAME', dataField: 'name', },
                        { resource: 'CONTRACT.SECONDARY.FIELD.TYPE', dataField: 'type', editorType: 'dxSelectBox', editorOptions: { dataSource: this.contractDateTypes, valueExpr: 'value', displayExpr: 'display' } },
                        { resource: 'CONTRACT.SECONDARY.FIELD.DATE', dataField: 'actiondatedisplay', editorType: 'dxDateBox' },
                    ]
                }
            ]
        };
        this.state = {
            secondaryDates: null,
            seletedItemDetails: null
        }
    }
    componentDidMount() {
        this.fetchSecondaryDates();
    }
    fetchSecondaryDates = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data: datesResp } = await activityCrud.getContractSecondaryDates(companyid, projectid, contractid);
            this.setState({ secondaryDates: JSON.parse(datesResp.aggregatelist) });
        }
        catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }
    }

    //editing row
    onRowUpdating = async (e) => {
        const { companyid, projectid, contractid } = this.props.contract;

        const newObject = { ...e.oldData, ...e.newData, companyid, projectid, contractid };
        e.cancel = this.saveSecondaryDatePromise(newObject);
    }


    saveSecondaryDatePromise = (data) => {
        return new Promise(async (resolve, reject) => {
            try {
                data.originalactiondate = data.actiondate;
                data.actiondatedisplay = moment(new Date(data.actiondate)).format("DD/MM/YYYY");
                await activityCrud.createActivity(data);
                resolve(false);
            } catch (error) {
                console.log(error);
                reject(error)
            }
        });
    }
    //deleting row
    onRowRemoving = async (e) => {
        const { companyid, projectid, contractid } = this.props.contract;
        const deleteObj = { ...e.data, companyid, projectid, contractid };
        deleteObj.deleted = 1;
        e.cancel = this.deleteSecondaryDatePromise(deleteObj);
    }

    deleteSecondaryDatePromise = (data) => {
        return new Promise(async (resolve, reject) => {
            try {
                data.actiondatedisplay = moment(new Date(data.actiondate)).format("DD/MM/YYYY");
                await activityCrud.createActivity(data);
                resolve(false);
            } catch (error) {
                console.log(error);
                reject(error)
            }
        });
    }

    //adding row

    onRowInserting = async (e) => {
        const { companyid, projectid, contractid } = this.props.contract;
        const newSecondaryDate = { ...e.data, companyid, projectid, contractid };
        e.data.originalactiondate = e.data.actiondate;
        e.cancel = this.createSecondaryDatePromise(newSecondaryDate, e);
    }
    createSecondaryDatePromise = (date, e) => {
        return new Promise(async (resolve, reject) => {
            try {

                date.actiondatedisplay = moment(date.actiondate).format('DD/MM/YYYY')
                date.createbyid = this.props.userProfile.accountid;
                const resp = await activityCrud.createActivity(date);
                e.data.activityscheduleid = resp.data;

                resolve(false);
            } catch (error) {
                console.log(error);
                reject(error)
            }
        })
    }

    onInitNewRow = async (e) => {
        e.data = { deleted: 0 };
    }

    onExporting = (e) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(this.props.intl.formatMessage({ id: 'REGISTER.EXPORTS.MAIN_SHEET' }));
        
        exportDataGrid({
          component: e.component,
          worksheet: worksheet,
        }).then(() => {  // Using arrow function here
          workbook.xlsx.writeBuffer()
            .then((buffer) => {  // Using arrow function here to preserve 'this'
              saveAs(
                new Blob([buffer], { type: 'application/octet-stream' }), 
                this.props.intl.formatMessage({ id: 'REGISTER.EXPORTS.CONTRACT_SECONDARY_DATES' }) + '.xlsx'
              );
            });
        });
      };
      

    allowDeleting = (e) => {

        return this.allowEdit && !e.row.data.lastamendingreference;
    }

    allowUpdating = (e) => {
        return this.allowEdit && !e.row.data.lastamendingreference && manuallyEditableActionTypes.includes(e.row.data.actiontype);
    }
    viewChangeLogClick = async (e) => {
        const clonedItem = { ...e.row.data };
        this.setState({ seletedItemDetails: clonedItem })
    }
    render() {
        if (!this.state.secondaryDates) {
            return (<PortletSpinner />);
        }
        
        return (
            <Portlet>
                <PortletHeader resource="CONTRACT.SECONDARY.TITLE" />
                <PortletBody>
                    <div style={{ width: '100%', height: '67vh' }}>
                        <DataGrid
                            dataSource={this.state.secondaryDates}
                            width='100%'
                            height='100%'
                            maxHeight='67vh'
                            allowColumnResizing={true}
                            hoverStateEnabled={true}
                            rowAlternationEnabled={false}
                            showBorders={true}
                            showColumnLines={true}
                            showRowLines={true}
                            remoteOperations={false}
                            allowColumnReordering={true}
                            columnResizingMode='widget'
                            onContextMenuPreparing={this.addGridContextMenuItems}
                            onRowUpdating={this.onRowUpdating}
                            onRowRemoving={this.onRowRemoving}
                            onInitNewRow={this.onInitNewRow}
                            onRowInserting={this.onRowInserting}
                            onExporting={this.onExporting}
                            // height={470}
                            style={{ maxHeight: '67vh' }}
                            name="contractSecondaryDates"
                            keyExpr="activityscheduleid"
                            loadPanel={{ showIndicator: !this.state.secondaryDates }}
                            columns={[
                                { caption: this.props.intl.formatMessage({ id: "CONTRACT.SECONDARY.FIELD.NAME" }), dataField: "name" },
                                { caption: this.props.intl.formatMessage({ id: "CONTRACT.SECONDARY.FIELD.TYPE" }), dataField: "actiontype", lookup: { dataSource: this.contractDateTypes, valueExpr: 'value', displayExpr: 'display' } },
                                { caption: "Original Date", dataField: "originalactiondate", dataType: "date", format: 'dd/MM/yyyy' },
                                { caption: this.props.intl.formatMessage({ id: "CONTRACT.SECONDARY.FIELD.DATE" }), dataField: "actiondate", dataType: "date", format: 'dd/MM/yyyy' },
                                { caption: this.props.intl.formatMessage({ id: "CONTRACT.SECONDARY.FIELD.LASTAMENDDATE" }), dataField: 'lastamenddate', dataType: "date", format: 'dd/MM/yyyy' },
                                { caption: this.props.intl.formatMessage({ id: "CONTRACT.SECONDARY.FIELD.LASTAMENDREFERENCE" }), dataField: 'lastamendingreference' },
                                { caption: "Source Model", dataField: "sourcedmodel", visible: false },
                                { caption: "Source Record ID", dataField: "sourcedrecordid", visible: false },
                                {
                                    caption: "Edit",
                                    name: "editButtons",
                                    type: "buttons",
                                    buttons: [
                                        { name: "edit" },
                                        { name: "delete" },
                                    ]

                                },
                                {
                                    caption: "Logs",
                                    name: "viewLogsButton",
                                    type: "buttons",
                                    buttons: [
                                        { name: "ViewChangeLog", hint: "View Change Logs", icon: "bulletlist", onClick: this.viewChangeLogClick },
                                    ]

                                }
                            ]}
                        >
                            <Editing
                                mode="popup"
                                allowUpdating={this.allowUpdating}
                                allowDeleting={this.allowDeleting}
                                allowAdding={this.allowEdit}
                                confirmDelete={true}
                                popup={{ title: this.props.intl.formatMessage({ id: "CONTRACT.SECONDARY.TITLE" }), height: 380, width: 600, showTitle: true }}
                                useIcons={true}
                                form={{
                                    items: [
                                        { dataField: "name", colSpan: 2, validationRules: [{ type: "required" }] },
                                        { dataField: "actiontype", colSpan: 2, editorType: 'dxSelectBox', validationRules: [{ type: "required" }], editorOptions: { dataSource: this.contractDateTypes.filter(item => manuallyEditableActionTypes.includes(item.value)), valueExpr: 'value', displayExpr: 'display' } },
                                        { dataField: "actiondate", colSpan: 2, editorType: 'dxDateBox', validationRules: [{ type: "required" }], datatype: 'date', placeholder: 'DD/MM/YYYY', editorOptions: { ...dxDateBoxProps } },
                                    ]
                                }}
                            />
                            <Export enabled={true} fileName={this.props.intl.formatMessage({ id: 'REGISTER.EXPORTS.CONTRACT_SECONDARY_DATES' })}/>
                            <ColumnChooser mode="select" enabled={true} />
                            <HeaderFilter visible={true} />
                            <SearchPanel visible={true} highlightCaseSensitive={false} defaultText="" />
                            <Scrolling mode="virtual" showScrollbar={true} useNative={true} columnRenderingMode="virtual" rowRenderingMode="virtual" />
                        </DataGrid>
                    </div>
                    <ContractSecondaryDateDetails
                        secondaryDate={this.state.seletedItemDetails}
                        onClose={() => { this.setState({ seletedItemDetails: null }) }}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}





const mapStateToProps = store => ({
    lang: store.i18n.lang,
    contract: store.contract.contract,
    userProfile: store.auth.userProfile
});

const mapDispatchToProps = {
    loadContract: contractDuck.actions.fetchStart,
}

export default injectIntl(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(ContractSecondaryDates)));
