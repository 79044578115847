import React from 'react';
import { Popup } from 'devextreme-react/popup';
import { injectIntl } from "react-intl";
import * as relationshipCrud from '../../../../crud/relationship.crud';
import { boolOptions, contractUserPartyOptions } from '../../../../shared/lookup';
import sanitizeHtml from 'sanitize-html-react';
import Common from '../../../../shared/common';
import DataGridBase from "../../../../partials/layout/DataGridBase";

class RelationChain extends React.Component {
    constructor() {
        super();
        this.state = {
            relations: null
        };
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.isOpen && this.props.isOpen) {
            this.getChainedRelationships();
        }
    }

    getChainedRelationships = async () => {
        relationshipCrud.getChainedRelationships(this.props.contractid, this.props.currentNotificationID, this.props.referenceType)
            .then(response => {
                let relations = response.data;
                relations = relations.filter(r => r.Draft !== 1);
                this.setState({ relations });
            })
            .catch(err => {
                console.log(err);
            });
    }

    onClose = () => {
        this.props.onClose();
    }

    calculateHTML = (cellInfo) => {
        const cleanHTML = sanitizeHtml(cellInfo.Description);
        return Common.HtmlToString(cleanHTML);
    }

    calculateUrl = (cellElement, cellInfo) => {
        const { data } = cellInfo;
        const url = process.env.PUBLIC_URL + this.props.currentWF.paths.basePath + data.PK;
    
        cellElement.innerHTML = `<a href="${url}" style="white-space: normal;"><strong>${data.ReferenceID}</strong></a>`;
    }

    render() {
        const columns = [
            { caption: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.REFERENCE_ID' }), dataField: "ReferenceID", cellTemplate: this.calculateUrl },
            { caption: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.NOTIFICATION_TITLE' }), dataField: "NotificationTitle", dataType: 'string' },
            { caption: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.SUBJECT' }), dataField: "Subject", dataType: 'string' },
            { caption: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.DESCRIPTION' }), dataField: "Description", dataType: 'string', calculateCellValue: this.calculateHTML },
            { caption: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.NOTIFIED_BY' }), dataField: "NotifiedBy", dataType: 'string' },
            { caption: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.DATE_NOTIFIED' }), dataField: "DateNotified", dataType: 'date', format: 'dd/MM/yyyy' },
            { caption: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.REPLY' }), dataField: "Reply", dataType: 'string', lookup: { dataSource: boolOptions, valueExpr: 'value', displayExpr: 'display' } },
            { caption: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.SENDING_PARTY' }), dataField: "Party", dataType: 'string', lookup: { dataSource: contractUserPartyOptions, valueExpr: 'id', displayExpr: 'display' } },
        ];

        return (
            <Popup
                visible={this.props.isOpen}
                onHiding={this.onClose}
                dragEnabled={true}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.RELATIONSHIP_CHAIN' })}
                width={'90%'}
                height='90vh'
            >
                <DataGridBase
                    version="2"
                    width='100%'
                    height='100%'
                    dataSource={this.state.relations}
                    keyExpr="PK"
                    hoverStateEnabled={false}
                    rowAlternationEnabled={true}
                    noDataText={'No Data'}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    remoteOperations={false}
                    columns={columns}
                    name="Relationship Chain"
                />
            </Popup>
        );
    }
}

export default injectIntl(RelationChain);