import React from 'react';
import BaseCreatePage from '../../_CustomComponents/BaseCreatePage';
import { connect } from "react-redux";
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import * as inspectionCrud from '../../../../crud/inspectionDefect.crud';
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import { Alert } from "react-bootstrap";
import moment from 'moment';
import { FormattedMessage } from "react-intl";
import { withCreatePageProps } from '../../../../shared/hoc/withCreatePageProps';
import Common from '../../../../shared/common';
import { actionCodes } from "../../../../shared/lookup";

class DefectCreatePage extends BaseCreatePage {
    constructor(props) {
        super(props);
        const { subject } = ((props.location.state || {}).itemCustomProps || {});
        this.clauseLabel = props.notificationType === '42.2CON' ? props.resources.SupplierClauseLabel : props.resources.SupervisorClauseLabel;
        const createFormConfig = {
            buttonResource: "GENERAL.FORMS.BUTTON_CREATE_DRAFT",
            fields: [
                { resource: 'GENERAL.FORMS.FIELD.SUBJECT', dataField: 'replyactiondetail', editorOptions: { defaultValue: subject } },
                { label: this.clauseLabel, dataField: 'inspectiondetails', editorType: 'dxHtmlEditor' }
            ]
        };
        if (props.plannedClosureEnabled && !props.NCSubmissionWFexist) {
            createFormConfig.fields.splice(1, 0,
                { resource: "GENERAL.FORMS.FIELD.PLANNED_CLOSURE", dataField: 'plannedclosuredate', editorType: 'dxDateBox' }
            )
        }
        this.state = {
            showAlert: true,
            createFormConfig: createFormConfig
        };
    }

    submitHandler = (notification, setSubmitting) => {
        notification.companyid = this.props.contract.companyid;
        notification.contractid = this.props.contract.contractid;
        notification.projectid = this.props.contract.projectid;
        notification.replystatus = 'Not Communicated';
        // notification.notificationdatedisplay = moment().format('DD/MM/YYYY');

        if (this.props.plannedClosureEnabled && !this.props.NCSubmissionWFexist) {
            const plannedClosureDate = moment(notification.plannedclosuredate);
            notification.plannedclosuredate = moment().set({ 'year': plannedClosureDate.year(), 'month': plannedClosureDate.month(), 'date': plannedClosureDate.date() }).toDate();
        }
        notification.type = this.props.notificationType;
        notification.actioncode = actionCodes.CREATE;
        notification.notifyingparty = this.props.workflowItem.notifyingParty;
        notification.actioningparty = this.props.workflowItem.resolveActioningParty;
        this.appendRelationship(notification);
        Common.PropertyConversionsBeforeNoticeSaveRequest(notification, this.props.customFields, this.props.contractUsers);
        inspectionCrud.saveInspectionDefect(notification)
            .then(response => {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_SUCCESS' }), { variant: 'success', });
                try {
                    const createdID = Number(response.data);
                    this.props.navigate(this.props.workflowItem.paths.basePath + createdID);
                }
                catch {
                    this.props.navigate(this.props.workflowItem.paths.listPath);
                }
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', key: "PersistentFormWarning", persist: true });
            });

    }

    render() {
        const { assetid } = ((this.props.location.state || {}).itemCustomProps || {});
        return (
            <Portlet>
                <PortletHeader title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.CREATE_TITLE' }, { ITEM_NAME: this.props.workflowItem.pageTitle })} />
                <PortletBody style={{ maxWidth: 1000 }}>
                    {this.state.showAlert && (
                        <Alert variant="secondary" onClose={() => this.setState({ showAlert: false })} dismissible>
                            <p>
                                <FormattedMessage id="GENERAL.FORMS.WARNING_DRAFT" />
                            </p>
                        </Alert>
                    )}
                    {this.props.resources.DefectsWarningBanner && (
                        <Alert variant="warning" >
                            <p>
                                {this.props.resources.DefectsWarningBanner}
                            </p>
                        </Alert>
                    )}
                    <BiDynamicForm
                        config={this.state.createFormConfig}
                        onSubmitHandler={this.submitHandler}
                        customFields={(this.props.customFields || {}).COMMUNICATION}
                        assetFieldsEnabled={this.props.workflowItem.assetFieldsEnabled}
                        assetid={assetid}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    contractUsers: store.contract.users
});

export default withCreatePageProps(connect(mapStateToProps)(DefectCreatePage));
