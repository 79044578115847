import { userFilterCategories } from "../../../shared/lookup";
const workflows = [
    {
        id: 38,
        title: '$PM_NAME Task Orders',
        notificationName: 'Task Order',
        abbreviation: '$PM_ABBREVIATION-Task-',
        notifyingParty: 'PM',

        notify: true,
        financialLimitEnabled: false,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'PM Task Orders',
            currentModel: 'TaskOrder',
            currentModelType: '',
            referenceType: 'TaskOrder',



            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: false,
            configurableFinancialLimit: true,
            configurableAssets: true,

            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'pm',
                create: 'create',
                list: 'list',
                default: 'list'
            }

        }
    },
    {
        id: 62,
        title: '$PM_NAME Request For Quote',
        notificationName: 'Request For Quote',
        abbreviation: '$PM_ABBREVIATION-RequestForQuote-',
        notifyingParty: 'PM',
        quotingParty: 'SUPPLIER',
        quoteReplyingParty: 'PM',
        quoteNotificationName:'TO-Quote',
        quoteReferencePrefix:'TO-Quote-',

        notify: true,
        financialLimitEnabled: false,
        assetFieldsEnabled: false,

        form_IncludeCompanyName: false,
        form_CompanyName: 'PARTY',
        form_CompanyLogo: 'PARTY',

        notifyAttachments: true,

        includePdfjson: false,

        config: {
            description: 'PM Request For Quote',
            currentModel: 'Rfq',
            currentModelType: '',
            referenceType: 'Rfq',
            quotationType: 'RFQPM',
            quotationDocumentType: 'DocumentRFQPMQ',


            showInRelations: true,
            showInCustomFields: true,
            notifyPossible: true,
            replyPossible: false,
            quotationPossible: true,
            configurableFinancialLimit: true,
            configurableAssets: true,

            userFilterCategories: [
                userFilterCategories.ACCESS,
                userFilterCategories.MAIN_NOTICE,
                userFilterCategories.QUOTATION,
                userFilterCategories.QUOTE_REPLY,
                userFilterCategories.NOTIFY
            ],
            paths: {
                base: 'rfq',
                create: 'create',
                list: 'list',
                default: 'list',
                quote:'rfqquote'
            }
        }
    }

]

export default workflows;