import React from 'react';
import { ListGroup, Badge } from "react-bootstrap";
import PendingActionItemRow from '../../../../partials/content/dxControls/PendingActionItemRow';
import { withRouter } from '../../../../shared/hoc/withRouter';
import { injectIntl } from "react-intl";


class NotifyArea2 extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.actionsObj !== nextProps.actionsObj) {
            return true;
        }
        return false;
    }
    render() {

        return (
            <ListGroup>
                {
                    this.props.actionsObj.items.map((actionItem, index) => (
                        <ListGroup.Item
                            key={this.props.skey + index}
                            style={{ padding: '0.75rem 0.25rem' }}
                            action

                            // onClick={() => {
                            //     if (this.props.actionsObj.workflow) {
                            //         if (this.props.panelCloseBtnRef) {
                            //             this.props.panelCloseBtnRef.current.click();
                            //         }
                            //         if (actionItem.resolvingrecordid) {
                            //             this.props.navigate(this.props.actionsObj.workflow.paths.basePath + actionItem.resolvingrecordid);
                            //         }
                            //         else {
                            //             this.props.navigate(this.props.actionsObj.workflow.paths.createPath);
                            //         }
                            //     }
                            // }}
                            variant="secondary">
                            <PendingActionItemRow
                                actionItem={actionItem}
                                workflow={this.props.actionsObj.workflow}
                                panelCloseBtnRef={this.props.panelCloseBtnRef}
                                contractid={this.props.contractid}
                                contract={this.props.contract}
                                contractTemplate={this.props.contract}
                            />
                        </ListGroup.Item>
                    ))
                }
            </ListGroup>
        );
    }
}



export default injectIntl(withRouter(NotifyArea2));