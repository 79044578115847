import axios from '../shared/axios';
import store from "../store/store";
import * as contractDuck from "../store/ducks/contract.duck";
import CrudHelper from "./helper";

// export function getContracts(images) {
//     if (process.env.REACT_APP_ENVIRONMENT !== 'DEV') {
//         images = false;
//     }
//     return axios.post(`/api/Contract/LoadContractsList?areaid=&hubid=&showimages=${images}`);
// }
export function getContracts() {
    return axios.get(`/api/Contract/RegisterList`);
}
export function getContractOptions(companyid) {
    return axios.get(`/api/Contract/LoadContractsForCompany`, { params: { companyid } });
}
export function getContract(contractid) {
    return axios.get('/api/Contract/Load?contractid=' + contractid);
}
export function getContractPicture(contractid) {
    return axios.get('/api/picture/getpicture?type=Contract&reference=' + contractid);
}
export function saveContract(data) {
    data = CrudHelper.appendCustomFields(data);
    return axios.post('/api/Contract/SaveContract', data);
}
export function updateContractTemplate(data) {
    return axios.post('/api/Contract/UpdateContractTemplate', data);
}
export function overrideContractTemplate(data) {
    return axios.post('/api/Contract/OverrideContractTemplate', data);
}
export function updateContractSpecifics(data) {
    return new Promise((resolve, reject) => {
        axios.post('/api/Contract/SaveContractSpecifics', data)
            .then(result => {
                store.dispatch({ type: contractDuck.actionTypes.CONTRACT_REFRESH_CONTRACT });
                resolve(result);
            })
            .catch(error => reject(error));
    });
}
// export function getContractConfig(contractid) {
//     return ContractConfig;
// }
// export function getContractTemplate(contractid) {
//     return ContractTemplate;
// }
export function setContractPicture(contractid, file) {
  const data = new FormData();
  data.append("UploadedImage", file);

  const headers = {
      'Content-Type': 'multipart/form-data'
  }

  return axios.post('/api/picture/uploadpicture?type=Contract&reference=' + contractid, data, {
      headers: headers
  });
}
export function getContractEmailRecipients(contractid) {
    return axios.get(`/api/ContractRecipient/LoadContractRecipientList?contractid=${contractid}`);
}
export function getContractEmailRecipient(contractRecipientId) {
    return axios.get(`/api/ContractRecipient/GetContractRecipient?contractrecipientid=${contractRecipientId}`);
}
export function saveContractEmailRecipient(data) {
    return axios.post(`/api/ContractRecipient/SaveContractRecipient`, data);
}
export function getContractHubUserList(companyid, hubId, userId) {
    return axios.post(
        `/api/ContractHubUser/LoadContractHubUserList?companyid=${companyid}&hubid=${hubId}&userid=${userId}`
    );
}
export function createContractHubUser(data) {
    return axios.post("/api/ContractHubUser/SaveContractHubUser", data);
}

export function deleteContract(contractid) {
    return axios.post(`/api/Contract/Delete?contractid=${contractid}`);
}

export function restoreDeletedContract(contractid) {
    return axios.post(`/api/Contract/RestoreDeleted?contractid=${contractid}`);
}

export function archiveContract(contractid) {
    return axios.post(`/api/Contract/Archive?contractid=${contractid}`);
}

export function restoreArchivedContract(contractid) {
    return axios.post(`/api/Contract/RestoreArchived?contractid=${contractid}`);
}

export function completeContract(contractid) {
    return axios.post(`/api/Contract/CompleteContract?contractid=${contractid}`);
}

export function restoreCompletedContract(contractid) {
    return axios.post(`/api/Contract/RestoreCompletedContract?contractid=${contractid}`);
}

export function cloneContract(contractid, newContractName, cloneContractUsers){
    const encodedNewContractName = encodeURIComponent(newContractName);
    return axios.post(`/api/Contract/CloneContract?contractid=${contractid}&newContractName=${encodedNewContractName}&cloneContractUsers=${cloneContractUsers}`);
}

export function getContractOptionsForProject(projectid, cancelToken) {
    return axios.get(`/api/Contract/LoadContractsForProject?projectid=${projectid}`, null, { cancelToken });
}