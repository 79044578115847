import PendingActionsConfig, { pendingActionTypes, PendingDrafts, PendingReplyDrafts } from "../../shared/config/PendingActionsConfig";
import { getVersion } from "../../crud/auth.crud";
import store from "../store";
import { allMessages } from "../../../_metronic/i18n/I18nProvider";
import { alert } from 'devextreme/ui/dialog';
import ProtectorHelper from "../../router/Protector/ProtectorHelper";
import { typeOptions as manualActionOptions } from '../../pages/admin/Contracts/Contract/ContractActions';


const analyticsMenuGroup = [

  { section: "NAVIGATION.ANALYTICS" },
  {
    title: "Power BI Reports",
    translate: "NAVIGATION.MENU.POWERBI_REPORTS",
    root: true,
    icon: "flaticon2-line-chart",
    page: "/reports/powerbi/reports"
  },
  {
    title: "Power BI Registers",
    translate: "NAVIGATION.MENU.POWERBI_REGISTERS",
    root: true,
    icon: "flaticon2-indent-dots",
    page: "/reports/powerbi/registers"
  },
  {
    title: "Power BI Exports",
    icon: "flaticon2-file",
    translate: "REPORTS.MESSAGE.POWERBIEXPORTS",
    page: "/reports/powerbi/exports",
  },
  {
    title: "Downloads",
    translate: "NAVIGATION.MENU.DOWNLOADS",
    root: true,
    icon: "flaticon-download-1",
    bullet: "dot",
    submenu: [
      {
        title: "Aggregated Reports",
        translate: "NAVIGATION.MENU.AGGREGATE",
        page: "/downloads/aggregate",
      },
    ],
  },
  {
    title: "Admin Reports",
    translate: "NAVIGATION.MENU.ADMIN_REPORTS",
    root: true,
    icon: "flaticon2-analytics-1",
    bullet: "dot",
    submenu: [
      {
        title: "System Users Report",
        translate: "NAVIGATION.MENU.ADMIN_REPORTS_SYSTEM_USER",
        root: false,
        page: "/reports/system-users",
      },
      {
        title: "Contract Users Report",
        translate: "NAVIGATION.MENU.ADMIN_REPORTS_CONTRACT_USER",
        page: "/reports/contract-users",
      },
      {
        title: "Contract Lead Users Report",
        translate: "NAVIGATION.MENU.ADMIN_REPORTS_CONTRACT_LEAD_USER",
        page: "/reports/contract-lead-users",
      }
    ]
  }
];
const contractDashboardMenuGroup = (prefix) => [
  { section: "NAVIGATION.MENU.CONTRACT" },
  {
    title: "DASHBOARD",
    translate: "NAVIGATION.MENU.DASHBOARD",
    root: true,
    icon: "flaticon2-analytics-2",
    page: prefix + "dashboard",
  },
  {
    title: "Reports",
    translate: "NAVIGATION.MENU.REPORTS",
    root: true,
    icon: "flaticon2-poll-symbol",
    bullet: "dot",
    submenu: [
      {
        title: "Contract Health Check",
        translate: "NAVIGATION.MENU.CONTRACT_HEALTH",
        page: prefix + "reports/health",
      },
      {
        title: "Contract RAG",
        translate: "NAVIGATION.MENU.CONTRACT_RAG",
        page: prefix + "reports/rag",
      },
    ],
  },
];
const contractSettingMenuGroup = (prefix) => [
  {
    title: "Contract",
    translate: "NAVIGATION.MENU.CONTRACT",
    root: true,
    icon: "flaticon2-gear",
    bullet: "dot",
    alwaysExpand: true,
    submenu: [
      {
        title: "Overview",
        translate: "NAVIGATION.MENU.OVERVIEW",
        page: prefix + "settings/overview"
      },
      {
        title: "Contract users",
        translate: "NAVIGATION.MENU.CONTRACT_USERS",
        page: prefix + "settings/users",
      },
      {
        title: "Contract Email Recipients",
        translate: "NAVIGATION.MENU.CONTRACT_MAIL_RCP",
        page: prefix + "settings/email-recipients",
      },
      {
        title: "Contract template",
        translate: "NAVIGATION.MENU.CONTRACT_TEMPLATE",
        page: prefix + "settings/template",
      },
      {
        title: "Other Dates",
        translate: "NAVIGATION.MENU.OTHER_DATES",
        page: prefix + "settings/secondary-dates"
      },
      {
        title: "Other Prices",
        translate: "NAVIGATION.MENU.OTHER_PRICES",
        page: prefix + "settings/secondary-prices"
      },
      {
        title: "Actions",
        translate: "NAVIGATION.MENU.CONTRACT_ACTIONS",
        page: prefix + "settings/actions"
      },
      {
        title: "Exports",
        translate: "CONTRACT.MENU.EXPORTS",
        page: prefix + "settings/exports",
      },
      {
        title: "Assets",
        translate: "NAVIGATION.MENU.ASSETS",
        page: prefix + "settings/assets",
      },
      {
        title: "Price Lists",
        translate: "NAVIGATION.MENU.PRICELISTS",
        page: prefix + "settings/price-list",
      },
      //POC
      // {
      //   title: "Preferences",
      //   translate: "NAVIGATION.MENU.PREFERENCES",
      //   page: prefix + "settings/preferences",
      // }
    ]
  }
];

const settingsMenuItems = (contractPrefix) => {
  const settingsGroupMenu = [{ section: "NAVIGATION.SETTINGS" }];
  if (contractPrefix) {
    settingsGroupMenu.push(...contractSettingMenuGroup(contractPrefix))
  }

  settingsGroupMenu.push(
    {
      title: "Companies",
      translate: "NAVIGATION.MENU.COMPANIES",
      root: false,
      icon: "flaticon2-world",
      page: "/admin/companies",
    },
    {
      title: "Projects",
      translate: "NAVIGATION.MENU.PROJECTS",
      root: false,
      icon: "flaticon2-folder",
      page: "/admin/projects",
    },
    {
      title: "Master",
      translate: "NAVIGATION.MENU.MASTER",
      root: true,
      icon: "flaticon2-console",
      submenu: [
        {
          title: "Master Workflows",
          translate: "NAVIGATION.MENU.MASTER_WORKFLOWS",
          page: "/admin/templates/master-workflows",
        },
        {
          title: "Master Templates",
          translate: "NAVIGATION.MENU.MASTER_TEMPLATES",
          page: "/admin/templates/master-templates",
        },
      ],
    },
    {
      title: "Users",
      translate: "NAVIGATION.MENU.USERS",
      root: false,
      icon: "flaticon2-group",
      submenu: [
        {
          title: "Manage System Users",
          translate: "NAVIGATION.MENU.MANAGE_SYSTEM_USERS",
          page: "/admin/users/manage-system-users",
        },
        {
          title: "Add User to Multiple Contracts",
          translate: "NAVIGATION.MENU.BULK_ADD_CONTRACT_USERS",
          page: "/admin/users/contract-add-bulk-users",
        }
      ],
    },
    {
      title: "System Settings",
      translate: "NAVIGATION.MENU.SYSTEM_SETTINGS",
      root: false,
      icon: "flaticon2-settings",
      page: "/admin/settings",
    },
  );
  return settingsGroupMenu;
}
export default class MenuItemsHelper {
  static filterAccessAllowed = (items, userProfile, contract) => {
    const retList = [];
    let section = null;
    items.forEach(item => {
      if (item.section) {
        section = item;
      }
      else if (item.page && ProtectorHelper.isPathAllowed(item.page, userProfile, contract)) {
        retList.push(item)
      }
      else if (item.submenu) {
        item.submenu = MenuItemsHelper.filterAccessAllowed(item.submenu, userProfile, contract)
        if (item.submenu && item.submenu.length > 0) {
          retList.push(item)
        }
      }
    });
    if (retList.length > 0 && section) {
      retList.unshift(section);
    }
    return retList;
  }
  static getMainMenuItems = (userProfile, contract, template) => {
    // const allowedPaths = ProtectorHelper.getAllowedPaths(userProfile);
    let asideItems = [
      {
        title: "CONTRACTS",
        translate: "NAVIGATION.MENU.CONTRACTS",
        root: true,
        icon: "flaticon2-architecture-and-city",
        page: "/contracts",
      },
    ];

    const copyOfAnalyticsMenuGroup = JSON.parse(JSON.stringify(analyticsMenuGroup));
    const analyticsMenuItemsFiltered = MenuItemsHelper.filterAccessAllowed(copyOfAnalyticsMenuGroup, userProfile);
    asideItems.push(...analyticsMenuItemsFiltered);


    let contractPrefix = '';
    if (contract && contract.contractid) {
      contractPrefix = "/contract/" + contract.contractid + "/";

      const contractDashboardMenuItems = contractDashboardMenuGroup(contractPrefix);
      const contractDashboardMenuItemsFiltered = MenuItemsHelper.filterAccessAllowed(contractDashboardMenuItems, userProfile, contract);
      asideItems.push(...contractDashboardMenuItemsFiltered);

      //NO ADDITIONAL ACCESS CHECK 
      asideItems.push(...MenuItemsHelper.getContractWorkflowMenuItems(template));
    }

    const settingMenuItems = settingsMenuItems(contractPrefix);
    const settingMenuItemsFiltered = MenuItemsHelper.filterAccessAllowed(settingMenuItems, userProfile, contract);
    asideItems.push(...settingMenuItemsFiltered);

    return asideItems;
  }
  static getContractWorkflowMenuItems = (template) => {
    let workflowMenuItems = template.workflowGroups.map((workFlowGroupItem) => {
      const mappedSubmenu = workFlowGroupItem.workflows.map((workflowItem) => {
        return {
          title: workflowItem.title,
          page: workflowItem.paths.defaultPath,
        };
      });

      return {
        title: workFlowGroupItem.title,
        root: true,
        icon: workFlowGroupItem.icon,
        bullet: "dot",
        submenu: mappedSubmenu,
      };
    });

    if (workflowMenuItems.length > 0) {
      //   const testGroup = [];
      //   testGroup.push({ section: "NAVIGATION.WORKFLOWS" },
      //   {
      //     title: 'workflows',
      //     root: true,
      //     icon: 'flaticon2-world',
      //     // bullet: "dot",
      //     submenu: workflowMenuItems,
      //   }
      //   )
      //   return testGroup;
      //ADD HEADER IF ITEMS EXIST
      workflowMenuItems.unshift({ section: "NAVIGATION.WORKFLOWS" })
    }
    return workflowMenuItems;
  }

  static getSmartLinkItems = (links) => {
    let smartLinkItems = [{ section: "NAVIGATION.SMART_LINKS" }];

    if (links.length) {
      // smartLinkItems = 

      links.map((item) => {
        return smartLinkItems.push({
          title: item.Name,
          root: true,
          icon: "flaticon-questions-circular-button",
          page: item.URL,
          target: item.Target,
        });
      });
    }
    const aboutOnClick = async (e) => {
      e.preventDefault();
      let apiVersion = "";
      try {
        const response = await getVersion();
        apiVersion = response.data.apiVersion;
      } catch (error) {
        apiVersion = "(Error fetching API version!)"
      }


      const storeState = store.getState();
      const message = `${allMessages[storeState.i18n.lang].SYSTEM_UI_VERSION}: ${process.env.REACT_APP_VERSION}<br>${allMessages[storeState.i18n.lang].SYSTEM_API_VERSION}: ${apiVersion}`;
      await alert(`<div style="max-width:50vw;min-width:300px">${message}</div>`, allMessages[storeState.i18n.lang].SYSTEM_VERSION_TITLE);
      // store.dispatch({ type: applicationDuck.actionTypes.APPLICATION_SET_SYSTEM_MESSAGE, payload: { title: allMessages[storeState.i18n.lang].SYSTEM_VERSION_TITLE, message: message } });
    }

    smartLinkItems.push({ title: 'About', root: true, icon: 'flaticon-questions-circular-button', page: '#', target: '#', onClick: aboutOnClick })
    if (process.env.REACT_APP_ACCESSIBE_ENABLED === "true") {
      smartLinkItems.push({ title: 'Accessibility', root: true, icon: 'flaticon-questions-circular-button', page: '#', target: '#', onClick: (e) => e.preventDefault(), attributes: { "data-acsb-custom-trigger": "true" } });
    }
    return smartLinkItems;
  }

  static getContractSidePanelConfig = (template, contractid, pendingActions, pendingActionsShowAllPreference) => {
    const possibleManualActionTypes = manualActionOptions.filter(t => (!t.wfid || (t.wfid > 0 && template.accessibleWfIds.includes(t.wfid)))).map(t => t.value);

    const sidePanelConfig = {
      supplierActions: [],
      supplierItems: [],
      supplierDrafts: [],
      supplierReplyDrafts: [],
      pmActions: [],
      pmItems: [],
      pmDrafts: [],
      pmReplyDrafts: [],
      supervisorActions: [],
      supervisorItems: [],
      supervisorDrafts: [],
      supervisorReplyDrafts: [],
      clientActions: [],
      clientItems: [],
      clientDrafts: [],
      clientReplyDrafts: [],
      availableActionCodes: []
    };

    template.workflowGroups.forEach(workFlowGroupItem => {
      let ActionReminders;
      if (workFlowGroupItem.placeholders) {
        ActionReminders = workFlowGroupItem.placeholders.find(
          (ph) => ph.replacementKey === "ActionReminders"
        );
      }
      if (!ActionReminders) {
        alert("getSidePanelConfig.ActionReminders missing for workflow group:" + workFlowGroupItem.id);
        console.log("getSidePanelConfig.ActionReminders missing for workflow group:" + workFlowGroupItem.id);
      }
      workFlowGroupItem.workflows.forEach(workflowItem => {
        try {
          if ((workflowItem.userFilters || {}).accessAllowed) {

            //1-DRAFTS
            const drafts = PendingDrafts.filter(drf => drf.workflowid === workflowItem.id);
            drafts.forEach(draft => {
              const draftItem = {
                ...draft,
                title: workflowItem.title,
                navigate: draft.navigate ? draft.navigate.replace("$contractid", contractid) : workflowItem.paths.listPath
              };
              const rscItm = ActionReminders.values.find((x) => x.field === draftItem.prop);
              if (rscItm) {
                draftItem.title = rscItm.display;
              }
              sidePanelConfig[workflowItem.notifyingParty.toLowerCase() + "Drafts"].push(draftItem)

            });

            //2- REPLY DRAFTS
            if (workflowItem.replyEnabled) {
              const replyDrafts = PendingReplyDrafts.filter(rdrf => rdrf.workflowid === workflowItem.id);
              replyDrafts.forEach(replyDraft => {
                const replyDraftItem = {
                  ...replyDraft,
                  title: workflowItem.title,
                  navigate: replyDraft.navigate ? replyDraft.navigate.replace("$contractid", contractid) : workflowItem.paths.listPath
                };
                const rscItm = ActionReminders.values.find((x) => x.field === replyDraftItem.prop);
                if (rscItm) {
                  replyDraftItem.title = rscItm.display;
                }
                const party = replyDraftItem.pendingAction === pendingActionTypes.QUOTE_REPLY ? workflowItem.quoteReplyingParty : workflowItem.replyingParty;
                sidePanelConfig[party.toLowerCase() + "ReplyDrafts"].push(replyDraftItem)
              });
            }

            //3- ACTIONS
            const actions = PendingActionsConfig.filter(act => act.workflowid === workflowItem.id);
            actions.forEach(action => {
              const actionItem = { ...action }
              const rscItm = ActionReminders.values.find((x) => x.field === actionItem.resource);
              if (rscItm) {
                actionItem.title = rscItm.display;
                actionItem.isActive = rscItm.isActive;
                actionItem.workflow = workflowItem;
              }
              else {
                alert("getSidePanelConfig.ActionReminders missing resource for workflow group:" + workFlowGroupItem.id + "_" + action.resource);
                console.log("getSidePanelConfig.ActionReminders missing resource for workflow group:" + workFlowGroupItem.id + "_" + action.resource);
              }
              if (actionItem.isActive) {
                ///CHECK IF ANY SPECIFIC ACTION IS SET TO BE ACTIONED BY ANY OTHER PARTIES
                const otherPartiesSet = new Set();
                pendingActions.filter(itm => itm.act === actionItem.actionCode)
                  .forEach(itm => {
                    if (itm.pty) {
                      otherPartiesSet.add(itm.pty);
                    }
                  })
                switch (actionItem.pendingAction) {
                  case pendingActionTypes.REPLY:
                    if (workflowItem.replyEnabled) {
                      let actionParty = actionItem.pty ? actionItem.pty : workflowItem.replyingParty;
                      sidePanelConfig[actionParty.toLowerCase() + "Items"].push(actionItem)
                      sidePanelConfig.availableActionCodes.push(actionItem.actionCode);
                      actionItem.itemsList = pendingActions.filter(pact => (actionItem.actionCode === pact.act && (!pact.pty || pact.pty === actionParty)));
                      otherPartiesSet.forEach(pty => {
                        if (pty !== actionParty) {
                          //ACTION ALSO NEEDS TO APPEAR ON OTHER PARTIES ITEMS
                          //BUT ONLY ITEMS ASSIGNED TO THAT PARTY
                          const actionOnOtherPty = { ...actionItem }
                          actionOnOtherPty.itemsList = pendingActions.filter(pact => (actionItem.actionCode === pact.act && pact.pty === pty));
                          sidePanelConfig[pty.toLowerCase() + "Items"].push(actionOnOtherPty)
                        }
                      });
                    }
                    break;
                  case pendingActionTypes.QUOTE:
                    {
                      let actionParty = actionItem.pty ? actionItem.pty : workflowItem.quotingParty;
                      sidePanelConfig[actionParty.toLowerCase() + "Items"].push(actionItem)
                      sidePanelConfig.availableActionCodes.push(actionItem.actionCode);
                      actionItem.itemsList = pendingActions.filter(pact => (actionItem.actionCode === pact.act && (!pact.pty || pact.pty === actionParty)));
                      otherPartiesSet.forEach(pty => {
                        if (pty !== actionParty) {
                          //ACTION ALSO NEEDS TO APPEAR ON OTHER PARTIES ITEMS
                          //BUT ONLY ITEMS ASSIGNED TO THAT PARTY
                          const actionOnOtherPty = { ...actionItem }
                          actionOnOtherPty.itemsList = pendingActions.filter(pact => (actionItem.actionCode === pact.act && pact.pty === pty));
                          sidePanelConfig[pty.toLowerCase() + "Items"].push(actionOnOtherPty)
                        }
                      });
                    }
                    break;
                  case pendingActionTypes.QUOTE_REPLY:
                    {
                      let actionParty = actionItem.pty ? actionItem.pty : workflowItem.quoteReplyingParty;
                      sidePanelConfig[actionParty.toLowerCase() + "Items"].push(actionItem)
                      sidePanelConfig.availableActionCodes.push(actionItem.actionCode);
                      actionItem.itemsList = pendingActions.filter(pact => (actionItem.actionCode === pact.act && (!pact.pty || pact.pty === actionParty)));
                      otherPartiesSet.forEach(pty => {
                        if (pty !== actionParty) {
                          //ACTION ALSO NEEDS TO APPEAR ON OTHER PARTIES ITEMS
                          //BUT ONLY ITEMS ASSIGNED TO THAT PARTY
                          const actionOnOtherPty = { ...actionItem }
                          actionOnOtherPty.itemsList = pendingActions.filter(pact => (actionItem.actionCode === pact.act && pact.pty === pty));
                          sidePanelConfig[pty.toLowerCase() + "Items"].push(actionOnOtherPty)
                        }
                      });
                    }
                    break;
                  case pendingActionTypes.ACTION:
                    {
                      let actionParty = actionItem.pty ? actionItem.pty : workflowItem.actioningParty;
                      sidePanelConfig[actionParty.toLowerCase() + "Items"].push(actionItem)
                      sidePanelConfig.availableActionCodes.push(actionItem.actionCode);
                      actionItem.itemsList = pendingActions.filter(pact => (actionItem.actionCode === pact.act && (!pact.pty || pact.pty === actionParty)));
                      otherPartiesSet.forEach(pty => {
                        if (pty !== actionParty) {
                          //ACTION ALSO NEEDS TO APPEAR ON OTHER PARTIES ITEMS
                          //BUT ONLY ITEMS ASSIGNED TO THAT PARTY
                          const actionOnOtherPty = { ...actionItem }
                          actionOnOtherPty.itemsList = pendingActions.filter(pact => (actionItem.actionCode === pact.act && pact.pty === pty));
                          sidePanelConfig[pty.toLowerCase() + "Items"].push(actionOnOtherPty)
                        }
                      });
                    }
                    break;
                  // case pendingActionTypes.ASSESSMENT:
                  //   sidePanelConfig[workflowItem.replyingParty.toLowerCase() + "Items"].push(actionItem)
                  //   sidePanelConfig.availableActionCodes.push(actionItem.actionCode);
                  //   break;
                  default:
                    throw Error("Unknown pendingActionTypes" + actionItem.pendingAction);
                }
              }
            });
          }
        } catch (err) {
          console.log("Error in getSidePanelConfig.WF:" + workflowItem.id, workflowItem)
          throw err;
        }
      });
    });
    // const allManualActions = pendingActions.filter(pact => (
    //   manuelActionsEnabled &&
    //   pact.act === "ACTION" &&
    //   possibleManualActionTypes.includes(pact.typ) &&
    //   (pendingActionsShowAllPreference || pact.rcr !== 1 || new Date(pact.rdt) <= moment().add(1, "months"))
    // ));
    // let supplierActions = allManualActions.filter(pact => (pact.pty === "SUPPLIER"));
    // sidePanelConfig.supplierActionsCount = supplierActions.length;
    // let pmActions = allManualActions.filter(pact => (pact.pty === "PM"));
    // sidePanelConfig.pmActionsCount = pmActions.length;
    // let supervisorActions = allManualActions.filter(pact => (pact.pty === "SUPERVISOR"));
    // sidePanelConfig.supervisorActionsCount = supervisorActions.length;
    // let clientActions = allManualActions.filter(pact => (pact.pty === "CLIENT"));
    // sidePanelConfig.clientActionsCount = clientActions.length;

    ///////////////////////////////////////////////////////////////////////
    // const supplierActionsGrouped = Common.GroupBy(supplierActions, 'typ');
    // supplierActions = Object.keys(supplierActionsGrouped).map(maType => {
    //   const typeDef = manualActionOptions.find(mao => mao.value === maType);
    //   return {
    //     actionCode: maType,
    //     itemsList: supplierActionsGrouped[maType],
    //     workflowid: typeDef.wfid,
    //     workflow: template.workflowsQA[typeDef.wfid],
    //     title: typeDef.wfid ? template.workflowsQA[typeDef.wfid].title : typeDef.display
    //   }
    // });
    // sidePanelConfig.supplierActions = supplierActions;
    ///////////////////////////////////////////////////////////////////////
    // const pmActionsGrouped = Common.GroupBy(pmActions, 'typ');
    // pmActions = Object.keys(pmActionsGrouped).map(maType => {
    //   const typeDef = manualActionOptions.find(mao => mao.value === maType);
    //   return {
    //     actionCode: maType,
    //     itemsList: pmActionsGrouped[maType],
    //     workflowid: typeDef.wfid,
    //     workflow: template.workflowsQA[typeDef.wfid],
    //     title: typeDef.wfid ? template.workflowsQA[typeDef.wfid].title : typeDef.display
    //   }
    // });
    // sidePanelConfig.pmActions = pmActions;
    ///////////////////////////////////////////////////////////////////////
    // const supervisorActionsGrouped = Common.GroupBy(supervisorActions, 'typ');
    // supervisorActions = Object.keys(supervisorActionsGrouped).map(maType => {
    //   const typeDef = manualActionOptions.find(mao => mao.value === maType);
    //   return {
    //     actionCode: maType,
    //     itemsList: supervisorActionsGrouped[maType],
    //     workflowid: typeDef.wfid,
    //     workflow: template.workflowsQA[typeDef.wfid],
    //     title: typeDef.wfid ? template.workflowsQA[typeDef.wfid].title : typeDef.display
    //   }
    // });
    // sidePanelConfig.supervisorActions = supervisorActions;
    ///////////////////////////////////////////////////////////////////////
    // const clientActionsGrouped = Common.GroupBy(clientActions, 'typ');
    // clientActions = Object.keys(clientActionsGrouped).map(maType => {
    //   const typeDef = manualActionOptions.find(mao => mao.value === maType);
    //   return {
    //     actionCode: maType,
    //     itemsList: clientActionsGrouped[maType],
    //     workflowid: typeDef.wfid,
    //     workflow: template.workflowsQA[typeDef.wfid],
    //     title: typeDef.wfid ? template.workflowsQA[typeDef.wfid].title : typeDef.display
    //   }
    // });
    // sidePanelConfig.clientActions = clientActions;
    ///////////////////////////////////////////////////////////////////////

    return sidePanelConfig;
  }
  static getContractQuickActionsMenuItems = (template) => {
    const quickActionsMenu = [];
    if (template.workflowGroups.length > 0) {
      template.workflowGroups.forEach((workFlowGroupItem) => {
        if (workFlowGroupItem.workflows.length > 0) {
          workFlowGroupItem.workflows.forEach((workflowItem) => {
            if ((workflowItem.userFilters.mainNotice || {}).createAllowed && workflowItem.paths.createPath) {
              quickActionsMenu.push({
                title: workflowItem.title,
                icon: workFlowGroupItem.icon,
                page: workflowItem.paths.createPath
              });
            }
          });
        }
      });
    }

    return quickActionsMenu;
  }
}
