import React from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";
import { injectIntl } from "react-intl";
import ManualActionGroup from './ManualActionGroup';

const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false
};


class TabAction extends React.Component {

    render() {
        const { actionsObj } = this.props;
        return (
            <PerfectScrollbar
                ref={(ref) => { this._scrollBarRef = ref; }}
                options={perfectScrollbarOptions}
                style={{ maxHeight: "calc(100vh - 100px)", position: "relative" }}>
                <div className="kt-notification">
                    <ManualActionGroup
                        key={this.props.key + "GRP_1"}
                        title={this.props.intl.formatMessage({ id: 'OPTIONS.ACTIONS.GRP_1' })}
                        actionsObj={actionsObj.GRP_1}
                        panelCloseBtnRef={this.props.panelCloseBtnRef}
                        isOpen={this.props.isOpen}
                    />
                    <ManualActionGroup
                        key={this.props.key + "GRP_2"}
                        title={this.props.intl.formatMessage({ id: 'OPTIONS.ACTIONS.GRP_2' })}
                        actionsObj={actionsObj.GRP_2}
                        panelCloseBtnRef={this.props.panelCloseBtnRef}
                        isOpen={this.props.isOpen}
                    />
                    <ManualActionGroup
                        key={this.props.key + "GRP_3"}
                        title={this.props.intl.formatMessage({ id: 'OPTIONS.ACTIONS.GRP_3' })}
                        actionsObj={actionsObj.GRP_3}
                        panelCloseBtnRef={this.props.panelCloseBtnRef}
                        isOpen={this.props.isOpen}
                    />
                    <ManualActionGroup
                        key={this.props.key + "GRP_4"}
                        title={this.props.intl.formatMessage({ id: 'OPTIONS.ACTIONS.GRP_4' })}
                        actionsObj={actionsObj.GRP_4}
                        panelCloseBtnRef={this.props.panelCloseBtnRef}
                        isOpen={this.props.isOpen}
                    />
                    <ManualActionGroup
                        key={this.props.key + "GRP_5"}
                        title={this.props.intl.formatMessage({ id: 'OPTIONS.ACTIONS.GRP_5' })}
                        actionsObj={actionsObj.GRP_5}
                        panelCloseBtnRef={this.props.panelCloseBtnRef}
                        isOpen={this.props.isOpen}
                    />
                    <ManualActionGroup
                        key={this.props.key + "GRP_6"}
                        title={this.props.intl.formatMessage({ id: 'OPTIONS.ACTIONS.GRP_6' })}
                        actionsObj={actionsObj.GRP_6}
                        panelCloseBtnRef={this.props.panelCloseBtnRef}
                        isOpen={this.props.isOpen}
                    />

                </div>
            </PerfectScrollbar>
        );
    }
}

export default injectIntl(TabAction);