import React, { Component } from 'react';
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import DataGrid, { Editing, Scrolling, Export, HeaderFilter, Button, Column, SearchPanel, ColumnChooser, Lookup, Form, Toolbar, Item as ToolbarItem } from 'devextreme-react/data-grid';
import * as contractOtherPriceCrud from '../../../../crud/contractOtherPrice.crud';
import * as contractDuck from '../../../../store/ducks/contract.duck';
import PortletSpinner from '../../../../partials/layout/PortletSpinner';
import { GetSecondaryPriceTypes, GetCurrencyCodes } from '../../../../shared/lookup';
import { injectIntl } from "react-intl";
import { listAccountsAllowedEditSecondaryAfterAmended } from '../../../../router/Protector/AccessExceptions';
import ContractSecondaryPriceDetails from './ContractSecondaryPriceDetails';
import Common from '../../../../shared/common';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

class ContractSecondaryPrices extends Component {
    constructor(props) {
        super(props);
        this.allowEdit = this.props.protectedRouteProps.allowEdit;
        this.contractDateTypes = GetSecondaryPriceTypes(props.lang);
        this.currencyCodes = GetCurrencyCodes();
        this.state = {
            secondaryPrices: null,
            seletedItemDetails: null
        }
    }

    componentDidMount() {
        this.fetchSecondaryPrices();
    }

    fetchSecondaryPrices = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data: datesResp } = await contractOtherPriceCrud.getContractOtherPrices(companyid, projectid, contractid);
            this.setState({ secondaryPrices: datesResp });
        }
        catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }
    }

    //editing row
    onRowUpdating = async (e) => {
        const { companyid, projectid, contractid } = this.props.contract;

        //Original price only changes if its not amended
        //same logic applied in API
        if (!e.oldData.lastamendingreferenceid) {
            e.newData.originalprice = e.newData.price;
        }

        const newObject = { ...e.oldData, ...e.newData, companyid, projectid, contractid };
        e.cancel = this.updateSecondaryPricePromise(newObject);
    }

    updateSecondaryPricePromise = (data) => {
        return new Promise(async (resolve, reject) => {
            try {
                data.originalprice = data.price;
                await contractOtherPriceCrud.updateContractOtherPrice(data);
                resolve(false);
            } catch (error) {
                console.log(error);
                reject(error)
            }
        });
    }

    //deleting row
    onRowRemoving = async (e) => {
        e.cancel = this.deleteSecondaryPricePromise(e.data.contractotherpriceid);
    }

    deleteSecondaryPricePromise = (contractotherpriceid) => {
        return new Promise(async (resolve, reject) => {
            try {
                await contractOtherPriceCrud.deleteItem(contractotherpriceid);
                resolve(false);
            } catch (error) {
                console.log(error);
                reject(error)
            }
        });
    }

    //adding row
    onRowInserting = async (e) => {
        const { companyid, projectid, contractid } = this.props.contract;
        e.data.companyid = companyid;
        e.data.projectid = projectid;
        e.data.contractid = contractid;
        e.data.originalprice = e.data.price;
        e.data.createbyid = this.props.userProfile.accountid;
        const newSecondaryPrice = { ...e.data };
        e.cancel = this.createSecondaryPricePromise(newSecondaryPrice, e);
    }

    createSecondaryPricePromise = (data, e) => {
        return new Promise(async (resolve, reject) => {
            try {
                const resp = await contractOtherPriceCrud.createContractOtherPrice(data);
                e.data.contractotherpriceid = resp.data;
                resolve(false);
            } catch (error) {
                console.log(error);
                reject(error)
            }
        })
    }

    onInitNewRow = async (e) => {
        e.data = {};
    }

    onExporting = (e) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(this.props.intl.formatMessage({ id: 'REGISTER.EXPORTS.MAIN_SHEET' }));
        
        exportDataGrid({
          component: e.component,
          worksheet: worksheet,
        }).then(() => {  // Using arrow function here
          workbook.xlsx.writeBuffer()
            .then((buffer) => {  // Using arrow function here to preserve 'this'
              saveAs(
                new Blob([buffer], { type: 'application/octet-stream' }), 
                this.props.intl.formatMessage({ id: 'REGISTER.EXPORTS.CONTRACT_SECONDARY_PRICES' }) + '.xlsx'
              );
            });
        });
      };
      

    allowDeleting = (e) => {
        if (listAccountsAllowedEditSecondaryAfterAmended.includes(this.props.userProfile.accounttype)) {
            //system admin exception
            return true;
        }

        return this.allowEdit && !e.row.data.lastamendingreferenceid;
    }

    allowUpdating = (e) => {
        if (listAccountsAllowedEditSecondaryAfterAmended.includes(this.props.userProfile.accounttype)) {
            //system admin exception
            return true;
        }
        return this.allowEdit && !e.row.data.lastamendingreferenceid;
    }
    viewChangeLogClick = async (e) => {
        const clonedItem = { ...e.row.data };
        this.setState({ seletedItemDetails: clonedItem })

    }
    render() {
        if (!this.state.secondaryPrices) {
            return (<PortletSpinner />);
        }
        return (
            <Portlet>
                <PortletHeader resource="CONTRACT.SECONDARY_PRICES.TITLE" />
                <PortletBody>
                    <div style={{ width: '100%', height: '67vh' }}>
                        <DataGrid
                            dataSource={this.state.secondaryPrices}
                            width='100%'
                            height='100%'
                            maxHeight='67vh'
                            allowColumnResizing={true}
                            hoverStateEnabled={true}
                            rowAlternationEnabled={false}
                            showBorders={true}
                            showColumnLines={true}
                            showRowLines={true}
                            remoteOperations={false}
                            allowColumnReordering={true}
                            columnResizingMode='widget'
                            onContextMenuPreparing={this.addGridContextMenuItems}
                            onRowUpdating={this.onRowUpdating}
                            onRowRemoving={this.onRowRemoving}
                            onInitNewRow={this.onInitNewRow}
                            onRowInserting={this.onRowInserting}
                            onExporting={this.onExporting}
                            style={{ maxHeight: '67vh' }}
                            name="contractSecondaryPrices"
                            keyExpr="contractotherpriceid"
                            loadPanel={{ showIndicator: !this.state.secondaryPrices }}
                        >
                            <Editing
                                mode="popup"
                                allowUpdating={this.allowUpdating}
                                allowDeleting={this.allowDeleting}
                                allowAdding={this.allowEdit}
                                confirmDelete={true}
                                popup={{ title: this.props.intl.formatMessage({ id: "CONTRACT.SECONDARY_PRICES.TITLE" }), height: 380, width: 600, showTitle: true }}
                                useIcons={true}
                                form={{
                                    items: [
                                        { dataField: "name", colSpan: 2, validationRules: [{ type: "required" }] },
                                        {
                                            dataField: "currency", colSpan: 2, editorType: 'dxSelectBox', validationRules: [{ type: "required" }], editorOptions: {
                                                dataSource: this.currencyCodes,
                                                valueExpr: 'code',
                                                displayExpr: 'name',
                                                searchExpr: 'name',
                                                defaultValue: 'GBP',
                                                searchEnabled: true,
                                                searchMode: 'contains',
                                                searchTimeout: 200,
                                                minSearchLength: 0,
                                            }
                                        },
                                        { dataField: "price", colSpan: 2, editorType: 'dxNumberBox', validationRules: [{ type: "required" }], editorOptions: { format: '#,##0.00', placeholder: '0.00' } },
                                        { dataField: "type", colSpan: 2, editorType: 'dxSelectBox', validationRules: [{ type: "required" }], editorOptions: { dataSource: this.contractDateTypes/*, valueExpr: 'display', displayExpr: 'display'*/ } }
                                    ]
                                }}
                            />
                            <Column dataField="name" caption={this.props.intl.formatMessage({ id: "CONTRACT.SECONDARY.FIELD.NAME" })} />
                            <Column dataField="type" caption={this.props.intl.formatMessage({ id: "CONTRACT.SECONDARY.FIELD.TYPE" })}>
                                <Lookup
                                    dataSource={this.contractDateTypes}
                                />
                            </Column>
                            <Column
                                dataField="currency"
                                caption={this.props.intl.formatMessage({ id: "CONTRACT.SECONDARY.FIELD.CURRENCY" })} />
                            <Column
                                dataField="originalprice"
                                caption={this.props.intl.formatMessage({ id: "CONTRACT.SECONDARY.FIELD.ORIGINAL_PRICE" })}
                                cellRender={(e) => Common.FormatCurrencyNum(e.data.originalprice, e.data.currency)}
                            />

                            <Column
                                dataField="price"
                                caption={this.props.intl.formatMessage({ id: "CONTRACT.SECONDARY.FIELD.PRICE" })}
                                cellRender={(e) => Common.FormatCurrencyNum(e.data.price, e.data.currency)}
                            />
                            <Column dataField="lastamendingdate" dataType="date" format='dd/MM/yyyy' caption={this.props.intl.formatMessage({ id: "CONTRACT.SECONDARY.FIELD.LASTAMENDDATE" })} />
                            <Column dataField="lastamendingreferenceid" caption={this.props.intl.formatMessage({ id: "CONTRACT.SECONDARY.FIELD.LASTAMENDREFERENCE" })} />
                            <Column caption='Edit' name="editButtons" type="buttons" >
                                <Button name="edit" />
                                <Button name="delete" />
                            </Column>
                            <Column caption='Logs' name="viewLogsButton" type="buttons" >
                                <Button name="ViewChangeLog" hint="View Change Logs" icon="bulletlist" visible={true} disabled={false} onClick={this.viewChangeLogClick} />
                            </Column>
                            <Export enabled={true} fileName={this.props.intl.formatMessage({ id: 'REGISTER.EXPORTS.CONTRACT_SECONDARY_PRICES' })}/>
                            <HeaderFilter visible={true} />
                            <SearchPanel visible={true} highlightCaseSensitive={false} defaultText="" />
                            <Scrolling mode="virtual" showScrollbar={true} useNative={true} columnRenderingMode="virtual" rowRenderingMode="virtual" />
                        </DataGrid>
                    </div>
                    <ContractSecondaryPriceDetails
                        secondaryPrice={this.state.seletedItemDetails}
                        onClose={() => { this.setState({ seletedItemDetails: null }) }}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    lang: store.i18n.lang,
    contract: store.contract.contract,
    userProfile: store.auth.userProfile
});

const mapDispatchToProps = {
    loadContract: contractDuck.actions.fetchStart,
}

export default injectIntl(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(ContractSecondaryPrices)));