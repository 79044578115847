import React from 'react';
import BaseCreatePage from '../../_CustomComponents/BaseCreatePage';
import { connect } from "react-redux";
import * as rfqQuotationCrud from '../../../../crud/rfqQuotation.crud';
import { Portlet, PortletBody, PortletHeader } from "../../../../partials/content/Portlet";
import { Alert } from "react-bootstrap";
import moment from 'moment';
import { FormattedMessage } from "react-intl";
import PageToolbar from '../../_CustomComponents/PageToolbar';
import BiDynamicForm from '../../../../partials/layout/BiDynamicForm';
import { withCreatePageProps } from '../../../../shared/hoc/withCreatePageProps';
import { paymentPeriods } from '../../../../shared/lookup';
import Common from '../../../../shared/common';
import { actionCodes } from "../../../../shared/lookup";
class QuoteCreatePage extends BaseCreatePage {
    constructor(props) {
        super(props);
        this.validatorRef = props.validatorRef ? props.validatorRef : React.createRef();

        this.createDefaultSubject = props.rfq ? props.rfq.subject : "";
        this.createDefaultStartDate = props.rfq ? props.rfq.startdate : "";
        this.createDefaultCompletionDate = props.rfq ? props.rfq.completiondate : "";
        this.createDefaultDelayDamagesTotal = props.rfq ? props.rfq.delaydamagestotal : "";
        this.createDefaultDelayDamagesType = props.rfq ? props.rfq.delaydamagestype : "";
        this.createDefaultDescription = props.rfq ? props.rfq.description : "";
        this.paymentPeriods = paymentPeriods.map(p => (this.props.intl.formatMessage({ id: "GENERAL.FORMS." + p })));

        const calculateTotal = (obj) => {
            const prc1 = obj.totallistprice ? obj.totallistprice : 0;
            const prc2 = obj.totalassessed ? obj.totalassessed : 0;
            return prc1 + prc2;
        }

        //NAVIGATE TO QUOTE TAB
        if (props.notificationType === 'RFQPM') {
            //INSTRUCTION
            this.timer = setTimeout(() => {
                props.parentTabPanel.instance.option('selectedIndex', 1);
            }, 200);
        }


        const createFormConfig = {
            currencyCode: this.props.contract.currency,
            buttonResource: "GENERAL.FORMS.BUTTON_CREATE_DRAFT",
            fields: [
                { resource: 'GENERAL.FORMS.FIELD.SUBJECT', dataField: 'subject', editorOptions: { defaultValue: this.createDefaultSubject, readOnly: true } }
            ]
        };

        if (this.props.resources.QuoteNoteRequestForQuote) {
            createFormConfig.fields.push(
              { note: this.props.resources.QuoteNoteRequestForQuote.replace('$reference', this.props.requestForQuote.referenceid), valueType: 'NOTE' },
            );
          }

        if (props.fields.Description) {
            createFormConfig.fields.push({ label: props.fields.Description, dataField: 'description', editorType: 'dxHtmlEditor', editorOptions: {  defaultValue: this.createDefaultDescription, readOnly: true } })
        }
        if (props.fields.Comments) {
            createFormConfig.fields.push({ label: props.fields.Comments, dataField: 'comments', editorType: 'dxHtmlEditor'  })
        }
        if (props.fields.ItemsList) {
            createFormConfig.fields.push({ label: props.fields.ItemsList, dataField: 'itemslist' })
        }
        if (props.fields.TotalListPrice) {
            createFormConfig.fields.push({ label: props.fields.TotalListPrice, dataField: 'totallistprice', editorType: 'dxNumberBox' })
        }
        if (props.fields.RequestForQuoteDescription) {
            createFormConfig.fields.push({ label: props.fields.RequestForQuoteDescription, dataField: 'requestforquotedescription' })
        }
        if (props.fields.TotalAssessed) {
            createFormConfig.fields.push({ label: props.fields.TotalAssessed, dataField: 'totalassessed', editorType: 'dxNumberBox' })
        }
        if (props.fields.TotalPrice) {
            createFormConfig.fields.push({ label: props.fields.TotalPrice, dataField: 'totalprice', editorType: 'dxNumberBox', value: calculateTotal })
        }
        if (props.fields.StartDate) {
            if (props.configurableRFQFields.StartDate){
                createFormConfig.fields.push({ label: props.fields.StartDate, dataField: 'startdate', editorType: 'dxDateBox',  editorOptions: { defaultValue: this.createDefaultStartDate, readOnly: true } });
            } else {
                createFormConfig.fields.push({ label: props.fields.StartDate, dataField: 'startdate', editorType: 'dxDateBox',  editorOptions: { defaultValue: this.createDefaultStartDate } });
            }
        }
        if (props.fields.CompletionDate) {
            var completionDateField = { label: props.fields.CompletionDate, dataField: 'completiondate', editorType: 'dxDateBox', editorOptions: { defaultValue: this.createDefaultCompletionDate } }
            
            if (props.configurableRFQFields.CompletionDate){
                completionDateField = { label: props.fields.CompletionDate, dataField: 'completiondate', editorType: 'dxDateBox', editorOptions: { defaultValue: this.createDefaultCompletionDate, readOnly: true } }
            }

            if (props.fields.StartDate) {
                completionDateField.validation = { type: 'custom', validationCallback: (obj, value) => (obj.startdate <= value), warning: 'Completion date can not be earlier than start date'}
            }

            createFormConfig.fields.push(completionDateField);
        }
        if (props.fields.DelayAmount) {
            if (props.configurableRFQFields.DelayAmount){
                createFormConfig.fields.push({ label: props.fields.DelayAmount, dataField: 'delayamount', required: false, editorType: 'dxNumberBox',  editorOptions: { defaultValue: this.createDefaultDelayDamagesTotal, readOnly: true } })
            } else {
                createFormConfig.fields.push({ label: props.fields.DelayAmount, dataField: 'delayamount', required: false, editorType: 'dxNumberBox',  editorOptions: { defaultValue: this.createDefaultDelayDamagesTotal } })
            }
        }
        if (props.fields.DelayPaymentPeriod) {
            if (props.configurableRFQFields.DelayPaymentPeriod){
                createFormConfig.fields.push({ label: props.fields.DelayPaymentPeriod, dataField: 'delaypaymentperiod', editorType: 'dxSelectBox', editorOptions: { dataSource: this.paymentPeriods, defaultValue: this.createDefaultDelayDamagesType, readOnly: true } })
            } else {
                createFormConfig.fields.push({ label: props.fields.DelayPaymentPeriod, dataField: 'delaypaymentperiod', editorType: 'dxSelectBox', editorOptions: { dataSource: this.paymentPeriods } })
            }
        }

        this.state = {
            showAlert: true,
            createFormConfig: createFormConfig
        };
    }

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }
    submitHandler = (notification, setSubmitting) => {
        notification.companyid = this.props.contract.companyid;
        notification.contractid = this.props.contract.contractid;
        notification.projectid = this.props.contract.projectid;
        notification.type = this.props.notificationType;
        notification.requestforquoteid = this.props.params.notificationid;
        notification.status = 2;
        notification.totalpricequotetime = this.props.contract.currentcontractvalue;
        notification.requestforquotereference = this.props.rfq.referenceid;
        
        if (notification.startdate) {
            notification.startdatedisplay = moment(notification.startdate).format('DD/MM/YYYY');
        }

        if (notification.completiondate) {
            notification.completiondatedisplay = moment(notification.completiondate).format('DD/MM/YYYY');
        }
        notification.actioncode = actionCodes.QUOTE_CREATE;
        notification.notifyingparty = this.props.workflowItem.notifyingParty;
        notification.replyingparty = this.props.workflowItem.replyingParty;
        Common.PropertyConversionsBeforeNoticeSaveRequest(notification, (this.props.customFieldsCommon || {}), this.props.contractUsers);
        rfqQuotationCrud.saveRfqQuote(notification)
            .then(response => {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.DRAFT_SUCCESS' }), { variant: 'success', });

                try {
                    const createdID = Number(response.data);
                    this.props.navigate(this.props.workflowItem.paths.basePath + createdID);
                } catch {
                    this.props.navigate(this.props.workflowItem.paths.listPath);
                }
            })
            .catch(err => {
                this.props.enqueueSnackbar(err.toString(), { variant: 'error', key: "PersistentFormWarning", persist: true });
            });

    }


    render() {
 
        return (
            <Portlet>
                <PortletHeader title={this.props.intl.formatMessage({ id: 'GENERAL.FORMS.CREATE_TITLE' }, { ITEM_NAME: this.props.workflowItem.pageTitle })} />
                <PortletBody style={{ maxWidth: 1000 }}>
                    {this.state.showAlert && (
                        <Alert variant="secondary" onClose={() => this.setState({ showAlert: false })} dismissible>
                            <p>
                                <FormattedMessage id="GENERAL.FORMS.WARNING_DRAFT" />
                            </p>
                        </Alert>
                    )}
                    <BiDynamicForm
                        validatorRef={this.validatorRef}
                        item={this.props.item}
                        config={this.state.createFormConfig}
                        onSubmitHandler={this.submitHandler}
                        customFields={(this.props.customFieldsCommon || {}).RFQQUOTE}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = store => ({
    contractUsers: store.contract.users
});

export default withCreatePageProps(connect(mapStateToProps)(QuoteCreatePage));
