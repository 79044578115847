/* eslint-disable no-script-url,array-callback-return */
import React from "react";
// import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';
import { connect } from "react-redux";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import Spinner from '../partials/layout/Spinner';
import { addWeeks } from 'date-fns';
import { withRouter } from '../shared/hoc/withRouter';
import { OverlayTrigger, Popover } from "react-bootstrap";
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/bootstrap/main.css';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import nlLocale from '@fullcalendar/core/locales/nl';
import { injectIntl } from "react-intl";

const popover = (event, intl) => (
  <Popover id="popover-basic">
    <Popover.Title as="h3">{event.extendedProps.header}</Popover.Title>
    <Popover.Content>
      <div><strong>Group: </strong>{intl.formatMessage({ id: 'OPTIONS.ACTIONS.GRP_' + event.extendedProps.groupcategory })}<br /></div>
      {event.extendedProps.reference && (<div><strong>{intl.formatMessage({ id: "GENERAL.FORMS.FIELD.REFERENCE" })}: </strong>{event.extendedProps.reference}<br /></div>)}
      {event.extendedProps.description && (<><strong>{intl.formatMessage({ id: "GENERAL.FORMS.FIELD.TITLE" })}: </strong>{event.extendedProps.description}<br /></>)}
    </Popover.Content>
  </Popover>
);


class ConrtractCalendarAction extends React.Component {
  constructor(props) {
    super(props);
    this.calendarRef = React.createRef();
    this.state = {
      dates: null
    }
  }

  componentDidMount() {
    this.setDates();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.manualActions !== this.props.manualActions) {
      this.setDates();
    }

    // if (this.calendarRef.current) {
    //   this.timer2 = setTimeout(() => { if (this.calendarRef.current) { this.calendarRef.current.calendar.rerenderEvents(); } }, 1000);
    // }
  }
  setDates = () => {
    if (this.props.manualActions) {


      const calendarDates = this.props.manualActions.items.map(action => {


        if (action.duedate) {
          const actionDate = new Date(action.duedate);
          let url = null;
          const srcWF = this.props.contractTemplate.workflowsQA[action.sourceworkflowid];
          const rslWF = this.props.contractTemplate.workflowsQA[action.resolvingworkflowid];
          if (rslWF) {
            if (action.resolvingrecordid) {
              url = rslWF.paths.basePath + action.resolvingrecordid
            }
            else {
              url = rslWF.paths.createPath
            }
          }
          let className = 'bg-success';
          if (actionDate < new Date()) {
            className = 'bg-danger';
          }
          else if (actionDate < addWeeks(new Date(), 1)) {
            className = 'bg-warning';
          }
          // const recordID = actionMenuItem.workflow.abbreviation + Common.Lpad(action.seq, 5);
          let keydef = null;
          let header = null;
          let referenceid = null;

          if (action.resolvingworkflowid) {
            header = this.props.contractTemplate.workflowsQA[action.resolvingworkflowid]?.pageTitle;
          }
          else {
            header = this.props.contractTemplate.availableActionTypesTypeDisplayObj[action.type];
          }

          if (action.sourcereferenceid) {
            keydef = action.sourcereferenceid;
          }
          else {
            keydef = header;
          }


          if (action.sourcereferenceid) {
            referenceid = action.sourcereferenceid;
          }
          else if (action.resolvingreferenceid) {
            referenceid = action.resolvingreferenceid;
          }

          // switch (action.groupcategory) {
          //   case 1:
          //     break;
          //   case 2:
          //     keydef = action.sourcereferenceid;
          //     break;
          //   case 3:
          //     keydef = action.sourcereferenceid;
          //     break;
          //   case 4:
          //     keydef = action.sourcereferenceid;
          //     break;
          //   case 5:
          //     keydef = action.sourcereferenceid;
          //     break;
          //   case 6:
          //     keydef = action.sourcereferenceid;
          //     break;
          //   default:
          //     break;
          // }

          if (action.duedate) {
            return {
              title: keydef,
              reference: referenceid,
              groupcategory: action.groupcategory,
              date: actionDate,
              url: url,
              allDay: true,
              classNames: [className],
              header: header,
              description: action.subject,
            };
          }
        }


      });
      this.setState({ dates: calendarDates });

    }
  }



  eventClick = (args) => {
    args.jsEvent.preventDefault();
    // console.log(args.event.extendedProps.overlayRef.current);
    if (args.event.url && args.event.url !== "null") {
      this.props.navigate(args.event.url);
    }
  }
  eventRender = ({ event, el, view }) => {
    const component = (
      <OverlayTrigger
        // ref={event.extendedProps.overlayRef}
        key={event.title}
        trigger={['hover', 'focus']}
        // container={this.calendarRef.current}
        overlay={popover(event, this.props.intl)}
        placement="auto"
        rootClose={true}
        rootCloseEvent="mousedown"
      >
        <div className="fc-content">
          <span className="fc-title">{event.title}</span>
        </div>
      </OverlayTrigger>
    );
    const root = createRoot(el);
    root.render(component);
    return el;
  }
  render() {
    if (!this.state.dates) {
      return (<Spinner />);
    }

    return (
      <FullCalendar
        ref={this.calendarRef}
        locales={[nlLocale]}
        locale={this.props.lang}
        defaultView="dayGridMonth"
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrapPlugin]}
        weekends={true}
        // editable={true}
        header={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',

        }}
        events={this.state.dates}
        // dateClick={null}
        eventClick={this.eventClick}
        // eventPositioned={this.handleEventPositioned}
        eventRender={this.eventRender}
        eventLimit={true}
        themeSystem="bootstrap"
      />
    )
  }
}

const mapStateToProps = store => ({
  lang: store.i18n.lang,
  contract: store.contract.contract,
  manualActions: store.contract.manualActions,
  contractTemplate: store.contract.template,
});



export default injectIntl(withRouter(connect(mapStateToProps)(ConrtractCalendarAction)));