/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import StickyToolbar from "../../../partials/layout/StickyToolbar";
import ContractSidePanelNotify from './ContractSidePanelNotify';
import ContractSidePanelAction from './ContractSidePanelAction';
// import ContractSidePanelBlog from './ContractSidePanelBlog';
// import ContractSidePanelDiscourseRSS from './ContractSidePanelDiscourseRSS';
import { Form } from 'react-bootstrap';
import Switch, { SwitchTypes } from 'devextreme-react/switch';
import * as preferencesDuck from '../../../store/ducks/preferences.duck';
import { injectIntl, FormattedMessage } from "react-intl";
import Common from '../../../shared/common';

class ContractSidePanel extends React.Component {
    constructor(props) {
        super(props);
        this.actionsEnabled = Common.IsActionEnabled(props.contract.companyid)
        this.panelCloseBtnRef1 = React.createRef();
        this.panelCloseBtnRef2 = React.createRef();
        this.panelCloseBtnRef3 = React.createRef();
        this.isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'DEV';
        this.state = {
            isPanel1Open: false,
            isPanel2Open: false,
            isPanel3Open: false,
        };
    }
    render() {
        return (
            <>
                <StickyToolbar
                    actionsEnabled={this.actionsEnabled}
                    onToggle={(panelNum, isOpen) => { panelNum === 1 ? this.setState({ isPanel1Open: isOpen }) : this.setState({ isPanel2Open: isOpen }); }}
                    panelCloseBtnRef1={this.panelCloseBtnRef1}
                    panelCloseBtnRef2={this.panelCloseBtnRef2}
                    panelCloseBtnRef3={this.panelCloseBtnRef3}
                    warningCount={(this.props.contractDashboard || {}).warningTotalCount}
                    actionCount={this.props.manualActions?.items?.length}
                />

                <div id="kt_quick_panel_1" className="kt-quick-panel" style={{ overflow: 'hidden' }}>
                    <a href="#" className="kt-quick-panel__close" id="kt_quick_panel_close_btn_1">
                        <i className="flaticon2-delete" />
                    </a>

                    <ContractSidePanelNotify
                        isOpen={this.state.isPanel1Open}
                        contractid={this.props.contractid}
                        panelCloseBtnRef={this.panelCloseBtnRef1}
                    />
                </div>
                {
                    this.actionsEnabled && (
                        <div id="kt_quick_panel_2" className="kt-quick-panel" style={{ overflow: 'hidden' }}>
                            <a href="#" className="kt-quick-panel__close" id="kt_quick_panel_close_btn_2">
                                <i className="flaticon2-delete" />
                            </a>

                            <div className="kt-quick-panel__filter">
                                <span style={{ paddingRight: 5, fontWeight: 400 }}><FormattedMessage id="CONTRACT.DASHBOARD.FILTER_ACTIONS" /></span>
                                <Switch
                                    defaultValue={this.props.pendingActionsShowAll}
                                    onValueChanged={(e) => { this.props.setPrefPendingActionFilter(e.value) }}
                                />
                            </div>

                            <ContractSidePanelAction
                                isOpen={this.state.isPanel2Open}
                                contractid={this.props.contractid}
                                panelCloseBtnRef={this.panelCloseBtnRef2}
                            />
                        </div>
                    )
                }
                {/* {
                    this.isDevelopment && (
                        <div id="kt_quick_panel_2" className="kt-quick-panel" style={{ overflow: 'hidden' }}>
                            <a href="#" className="kt-quick-panel__close" id="kt_quick_panel_close_btn_2">
                                <i className="flaticon2-delete" />
                            </a>
                            <ContractSidePanelBlog
                                isOpen={this.state.isPanel2Open}
                                contractid={this.props.contractid}
                                panelCloseBtnRef={this.panelCloseBtnRef2}
                            />
                        </div>
                    )
                } */}
                {/* {
                    this.isDevelopment && (
                        <div id="kt_quick_panel_3" className="kt-quick-panel" style={{ overflow: 'hidden' }}>
                            <a href="#" className="kt-quick-panel__close" id="kt_quick_panel_close_btn_3">
                                <i className="flaticon2-delete" />
                            </a>
                            <ContractSidePanelDiscourseRSS
                                isOpen={this.state.isPanel3Open}
                                contractid={this.props.contractid}
                                panelCloseBtnRef={this.panelCloseBtnRef3}
                            />
                        </div>
                    )
                } */}

            </>
        );
    }
}

const mapStateToProps = store => ({
    contractDashboard: store.contract.dashboard,
    contract: store.contract.contract,
    contractTemplate: store.contract.template,
    sidePanel: store.contract.sidePanel,
    pendingActionsShowAll: store.preferences.pendingActionsShowAll,
    manualActions: store.contract.manualActions,
});
const mapDispatchToProps = {
    setPrefPendingActionFilter: preferencesDuck.actions.setPendingActionFilter,
}
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ContractSidePanel))
