import React from "react";

 class ActionDropdownToggle extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.props.onClick(e);
  }

  render() {
    return (
      <div
        ref={this.props.innerRef}
        onClick={this.handleClick}
      >
        {this.props.children}
      </div>
    );
  }
}


export default React.forwardRef((props, ref) => <ActionDropdownToggle 
innerRef={ref} {...props}
/>);