import React, { useState, useRef, useEffect } from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";

export default function AuthPage() {
//   useEffect(()=>{
//     console.log("AuthPage Mounted!")
// },[])
  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          id="kt_login"
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div
              className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
              style={{
                backgroundImage: `url(${toAbsoluteUrl("/media/bg/bi-1.png")})`,
                minHeight: 200
              }}
            >
            </div>
            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
              <Routes caseSensitive>
                <Route path="/login" element={<Login/>} sensitive/>
                <Route path="/forgot-password" element={<ForgotPassword/>} sensitive/>
                <Route path="*"element={<Navigate replace to="/auth/login" />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
