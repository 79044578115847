/* eslint-disable react/no-direct-mutation-state */
import React from 'react';
import { connect } from "react-redux";
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { injectIntl } from "react-intl";
import Form, { Item as FormItem, SimpleItem, RequiredRule, GroupItem, RangeRule } from 'devextreme-react/form';
import { TextBox } from 'devextreme-react';
import { dxDateBoxProps } from '../../../../../shared/config/DxFormProperties';
import { withSnackbar } from 'notistack';
import DropDownBox from 'devextreme-react/drop-down-box';
import moment from 'moment';
import * as actionCrud from '../../../../../crud/action.crud';
import NoticeSelectionModal from './CommunicationPicker';

export const RESPONSES = {
    SAVE: 'SAVE',
    CANCEL: 'CANCEL',
};

const radioOptions = [
    { value: 0, display: "No" },
    { value: 1, display: "Yes" }
]
export const recurrencyOptions = [
    { value: 'WEEKLY', display: "Weekly" },
    { value: 'MONTHLY', display: "Monthly" },
];
export const weeklyOptions = [
    { value: 'MONDAY', display: "Monday" },
    { value: 'TUESDAY', display: "Tuesday" },
    { value: 'WEDNESDAY', display: "Wednesday" },
    { value: 'THURSDAY', display: "Thursday" },
    { value: 'FRIDAY', display: "Friday" },
    { value: 'SATURDAY', display: "Saturday" },
    { value: 'SUNDAY', display: "Sunday" },
];


class ContractActionEditModal extends React.Component {
    constructor(props) {
        super(props);
        this.validatorRef = React.createRef();
        this.validatorName = 'ContractActionEditModalValidator';
        this.formRef = React.createRef();
        this.defaultFields = null;

        this.state = {
            isbusy: false,
            changed: false,
            action: null,
            showSourceEditor: false,
            showRelatedEditor: false
        }

    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps) {
            if (prevProps.action !== this.props.action) {
                this.setActionItem();
            }
        }
    }


    setActionItem = () => {
        if (this.props.action && this.props.action.actionid > 0) {
            const action = { ...this.props.action };
            this.formData = { ...this.props.action };
            this.setState({ action });
        }
        // else {
        //     this.setState({ action: { ...emptyCustomField } });
        // }
    }
    onClose = () => {
        if (this.props.visible) {
            this.props.onDialogResult();
            this.setState({ action: null, changed: false });
        }
    }
    saveItem = async () => {
        try {
            if (this.formRef?.current) {
                const validationRes = this.formRef.current.instance.validate();
                if (validationRes.isValid) {
                    this.setState({ isbusy: true }, async () => {
                        try {
                            const requestObj = {
                                actionid: this.props.action.actionid,
                                resolvingworkflowid: this.formData.resolvingworkflowid,
                                resolvingreferenceid: this.formData.resolvingreferenceid,
                                resolvingrecordid: this.formData.resolvingrecordid,
                                sourceworkflowid: this.formData.sourceworkflowid,
                                sourcereferenceid: this.formData.sourcereferenceid,
                                sourcerecordid: this.formData.sourcerecordid,
                            };

                            await actionCrud.update(requestObj);
                            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'ACTION.MESSAGE.UPDATED' }), { variant: 'success', });
                            this.props.onDialogResult(RESPONSES.SAVE);
                            this.setState({ action: null, isbusy: false });
                        } catch (err) {
                            this.setState({ isbusy: false });
                            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                        }
                    })
                }
            }
        } catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }

        return;
    }
    validateForm = () => {
        const validationResult = this.validatorRef.current.instance.validate();
        return validationResult.isValid;
    }

    formFieldDataChanged = (e) => {
        this.formData = e.component.option("formData");
        if (!this.state.changed) {
            this.setState({ changed: true });
        }
    }
    onEditSourceClicked = (e) => {
        this.setState({ showSourceEditor: true });
    }
    onClearSourceClicked = () => {
        const newFormData = { ...this.formData }
        const newAction = { ...this.state.action }

        newFormData.sourceworkflowid = null;
        newFormData.sourcereferenceid = null;
        newFormData.sourcerecordid = null;
        newAction.sourcereferenceid = null;

        this.formData = newFormData;
        this.setState({ action: newAction, changed: true });

    }
    onClearRelatedClicked = () => {
        const newFormData = { ...this.formData }
        const newAction = { ...this.state.action }

        newFormData.resolvingworkflowid = null;
        newFormData.resolvingreferenceid = null;
        newFormData.resolvingrecordid = null;
        newAction.resolvingreferenceid = null;

        this.formData = newFormData;
        this.setState({ action: newAction, changed: true });

    }
    onEditRelatedClicked = (e) => {
        this.setState({ showRelatedEditor: true });
    }
    onSourceEditorDialogResult = (selectedItem) => {
        if (selectedItem) {
            const newFormData = { ...this.formData }
            const newAction = { ...this.state.action }
            if (this.state.showSourceEditor) {

                newFormData.sourceworkflowid = selectedItem.workflowid;
                newFormData.sourcereferenceid = selectedItem.referenceid;
                newFormData.sourcerecordid = selectedItem.recordid;
                newAction.sourcereferenceid = selectedItem.referenceid;
            }
            else if (this.state.showRelatedEditor) {

                newFormData.resolvingworkflowid = selectedItem.workflowid;
                newFormData.resolvingreferenceid = selectedItem.referenceid;
                newFormData.resolvingrecordid = selectedItem.recordid;
                newAction.resolvingreferenceid = selectedItem.referenceid;
            }
            this.formData = newFormData;
            this.setState({ showSourceEditor: false, showRelatedEditor: false, action: newAction, changed: true });
        }
        else {
            this.setState({ showSourceEditor: false, showRelatedEditor: false });
        }

    }
    render() {
        if (!this.props.visible) {
            return null;
        }
        return (
            <Popup
                id='editActionPopup'
                visible={this.props.visible}
                onHiding={this.onClose}
                dragEnabled={true}
                hideOnOutsideClick={false}
                showTitle={true}
                title={this.props.intl.formatMessage({ id: "ACTIONS.POPUP.EDIT.TITLE" })}
                width={800}
                disabled={this.state.isbusy}
                height='auto'>
                <Form
                    ref={this.formRef}
                    formData={this.state.action}
                    onFieldDataChanged={this.formFieldDataChanged}
                    labelMode='outside'
                    labelLocation='top'
                >
                    <GroupItem colCount={2}>
                        <FormItem
                            colSpan={1}
                            label={{ text: this.props.intl.formatMessage({ id: 'ACTIONS.FIELD.TYPE' }) }}
                            dataField="type"
                            editorType='dxSelectBox'
                            editorOptions={{ dataSource: this.props.contractTemplate.availableActionTypes, valueExpr: 'value', displayExpr: 'display', readOnly: true }}
                            isRequired
                        />
                        <FormItem
                            colSpan={1}
                            label={{ text: this.props.intl.formatMessage({ id: 'ACTIONS.FIELD.OWNER_PARTY' }) }}
                            dataField="ownerparty"
                            editorType='dxSelectBox'
                            editorOptions={{ dataSource: this.props.contractTemplate.parties.existingOptions, valueExpr: 'id', displayExpr: 'display', readOnly: true }}
                            isRequired

                        />

                        <SimpleItem
                            colSpan={1}
                            label={{ text: this.props.intl.formatMessage({ id: 'ACTIONS.FIELD.CREATE_BY_REFERENCE' }) }}
                            dataField="sourcereferenceid"
                        >
                            <TextBox
                                value={this.state.action?.sourcereferenceid}
                                readOnly={true}
                                buttons={[
                                    {
                                        location: 'after',
                                        name: 'Clear',

                                        options: {
                                            hint: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.CLEAR' }),
                                            type: "normal",
                                            stylingMode: "contained",
                                            disabled: false,
                                            icon: 'clear',
                                            visible: !!this.state.action?.sourcereferenceid,
                                            onClick: this.onClearSourceClicked,
                                        }
                                    },
                                    {
                                        location: 'after',
                                        name: 'Edit',
                                        options: {
                                            hint: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_EDIT' }),
                                            type: "normal",
                                            stylingMode: "contained",
                                            disabled: false,
                                            icon: 'edit',
                                            onClick: this.onEditSourceClicked,
                                        }
                                    }
                                ]}
                            />
                        </SimpleItem>
                        {
                            this.state.action?.completed && (
                                <SimpleItem
                                    colSpan={1}
                                    label={{ text: this.props.intl.formatMessage({ id: 'ACTIONS.FIELD.RESOLVED_BY_REFERENCE' }) }}
                                    dataField="resolvingreferenceid"
                                >
                                    <TextBox
                                        value={this.state.action?.resolvingreferenceid}
                                        readOnly={true}
                                        buttons={[
                                            {
                                                location: 'after',
                                                name: 'Clear',
                                                options: {
                                                    hint: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.CLEAR' }),
                                                    type: "normal",
                                                    stylingMode: "contained",
                                                    disabled: false,
                                                    icon: 'clear',
                                                    visible: !!this.state.action?.resolvingreferenceid,
                                                    onClick: this.onClearRelatedClicked,
                                                }
                                            },
                                            {
                                                location: 'after',
                                                name: 'Edit',

                                                options: {
                                                    hint: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_EDIT' }),
                                                    type: "normal",
                                                    stylingMode: "contained",
                                                    disabled: false,
                                                    icon: 'edit',
                                                    onClick: this.onEditRelatedClicked,
                                                }
                                            }
                                        ]}
                                    />
                                </SimpleItem>
                            )
                        }
                    </GroupItem>

                    <GroupItem name='DateFields' colCount={2}>
                        <FormItem
                            label={{ text: this.props.intl.formatMessage({ id: 'ACTIONS.FIELD.DUE_DATE' }) }}
                            dataField="duedate"
                            editorType="dxDateBox"
                            editorOptions={{
                                ...dxDateBoxProps,
                                min: moment()._d,
                                max: moment().add(10, 'years')._d,
                                calendarOptions: {
                                    firstDayOfWeek: 1,
                                    min: moment()._d,
                                    max: moment().add(10, 'years')._d,
                                },
                                readOnly: true

                            }}
                            isRequired={true}
                        />
                        {
                            this.state.action?.completed && (
                                <FormItem
                                    label={{ text: this.props.intl.formatMessage({ id: 'ACTIONS.FIELD.RESOLVED_DATE' }) }}
                                    dataField="resolveddate"
                                    editorType="dxDateBox"
                                    editorOptions={{
                                        ...dxDateBoxProps,
                                        min: moment()._d,
                                        max: moment().add(10, 'years')._d,
                                        calendarOptions: {
                                            firstDayOfWeek: 1,
                                            min: moment()._d,
                                            max: moment().add(10, 'years')._d,
                                        },
                                        readOnly: true

                                    }}
                                />
                            )
                        }

                    </GroupItem>
                    <GroupItem>
                        <FormItem
                            defaultValue={this.props.defaultSubject}
                            label={{ text: this.props.intl.formatMessage({ id: 'ACTIONS.FIELD.SUBJECT' }) }}
                            dataField="subject"
                            editorType="dxTextBox"
                            editorOptions={{ maxLength: 100, readOnly: true }}
                            isRequired
                        />
                        <FormItem
                            label={{ text: this.props.intl.formatMessage({ id: 'ACTIONS.FIELD.DESCRIPTION' }) }}
                            dataField="description"
                            editorType="dxTextArea"
                            editorOptions={{ height: 100, maxLength: 250, readOnly: true }}
                        />
                    </GroupItem>
                </Form>
                <NoticeSelectionModal
                    visible={this.state.showSourceEditor || this.state.showRelatedEditor}
                    onDialogResult={this.onSourceEditorDialogResult}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{ text: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_CANCEL' }), onClick: this.onClose }}>
                </ToolbarItem>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    disabled={!this.state.changed}
                    options={{
                        text: this.props.intl.formatMessage({ id: 'ACTIONS.BUTTON.UPDATE_ACTION' }),
                        icon: this.state.isbusy ? 'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--dark' : 'save',
                        onClick: this.saveItem
                    }}>
                </ToolbarItem>
            </Popup >
        );
    }
}

const mapStateToProps = store => ({
    contract: store.contract.contract,
    contractTemplate: store.contract.template,
});

export default connect(mapStateToProps)(withSnackbar(injectIntl(ContractActionEditModal)));