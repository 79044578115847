/* eslint-disable no-eval, react/no-direct-mutation-state */
import React from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as projectsCrud from '../../../crud/projects.crud';
import * as contractsCrud from '../../../crud/contracts.crud';
import { withSnackbar } from 'notistack';
import * as commonCrud from '../../../crud/common.crud';
import axios from 'axios';
import { Validator, RequiredRule, } from 'devextreme-react/validator';
import { SelectBox, DropDownBox, Switch } from 'devextreme-react';
import DataGrid, { Column, SearchPanel, HeaderFilter, FilterPanel, FilterRow, Selection, Scrolling, ColumnChooser, ColumnFixing } from 'devextreme-react/data-grid';

export const RESPONSE = {
    OK: 'OK',
    CANCEL: 'CANCEL',
    CREATE: 'CREATE'
};

class ActionCopyModal extends React.Component {
    constructor(props) {
        super(props);
        this.validatorRef = React.createRef();
        this.validatorName = 'ActionCopyModalValidator';
        this.formRef = React.createRef();
        this.dataGrid = React.createRef();
        this.contractsDropdown = React.createRef();
        this.contractGridColumns = [
            { caption: this.props.intl.formatMessage({ id: "CONTRACT.FIELD.ID" }), dataField: "contractid" },
            { caption: this.props.intl.formatMessage({ id: "CONTRACT.FIELD.NAME" }), dataField: "contractname" },
            { caption: this.props.intl.formatMessage({ id: "CONTRACT.FIELD.PROJECT_NAME" }), dataField: "projectname" },
            { caption: this.props.intl.formatMessage({ id: "CONTRACT.FIELD.CLIENT.COMPANY" }), dataField: "clientcompany" },
            { caption: this.props.intl.formatMessage({ id: "CONTRACT.FIELD.SUPPLIER.COMPANY" }), dataField: "suppliercompany" },
            { caption: this.props.intl.formatMessage({ id: "CONTRACT.FIELD.PM.COMPANY" }), dataField: "pmcompany" },
            { caption: this.props.intl.formatMessage({ id: "CONTRACT.FIELD.SUPERVISOR.COMPANY" }), dataField: "supervisorcompany" }
        ];
        this.formData = {}
        this.defaultFields = null;

        const projects = this.getProjectOptions();

        this.state = { 
            copyattachment: false,
            projects,
            contractsList: null,
            isbusy: false,
            isnotificationcopied: false,
            newDraftsIds: null
         }
    }

    getProjectOptions = () => {
        const datasource = {
            loadMode: 'raw',
            cacheRawData: true,
            load: () => {
                var promise = new Promise((resolve, reject) => {
                    projectsCrud.getCutDownProjectsList(this.props.companyId)
                        .then(response => {
                            let projectList = [];

                            if (response.data && response.data.length > 0) {
                                projectList = response.data
                                .filter(project => project.projectid === this.props.projectId)
                                .map(project => ({ 
                                    projectid: project.projectid, 
                                    display: (project.projectcode ? `[${project.projectcode}] - ` : "") + `${project.name}` }));
                            }
                            else {
                                this.props.enqueueSnackbar('No project found for selected the company', { variant: 'warning', });
                            }

                            resolve(projectList);
                        })
                        .catch(err => {
                            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                            reject(err);
                        });
                });

                return promise;
            }
        }

        return datasource;
    }

    componentDidUpdate(prevProps) {
        // Run activation logic only when `visible` changes from false to true
        if (!prevProps.visible && this.props.visible) {
            this.updateContractsList();
        }
    }

    contractsGridOnSelectionChanged = (e, b) => {
        const { selectedContracts } = this.state;
        //The newly selected contracts
        const newlySelectedContracts = e.selectedRowKeys.filter(key => !selectedContracts || !selectedContracts.includes(key));

        //The contracts that are no longer selected
        const deselectedContracts = selectedContracts ? selectedContracts.filter(key => !e.selectedRowKeys.includes(key)) : [];
        if (newlySelectedContracts.length > 0 || deselectedContracts.length > 0) {
            //Update the selectedContracts state
            const updatedSelectedContracts = selectedContracts ? [...selectedContracts, ...newlySelectedContracts].filter(key => !deselectedContracts.includes(key)) : [...newlySelectedContracts];
            this.setState({ selectedContracts: updatedSelectedContracts });
        }
    }

    updateContractsList = async () => {
        try {
            if (this.requestSource) {
                this.requestSource.cancel('Operation canceled due to new request.');
            }
            this.requestSource = axios.CancelToken.source();
            
            this.setState({ contractsList: [] }, async () => {
                let { data: contractsList } = await contractsCrud.getContractOptionsForProject(this.props.projectId, this.requestSource.token);
                if (!Array.isArray(contractsList)) {
                    contractsList = [];
                }
                if (contractsList.length === 0) {
                    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "NOTIFICATION.POPUP.WARNING_NO_CONTRACT" }), { variant: "warning" });
                }
                this.setState({ contractsList });
            })
        }
        catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
            this.setState({ contractsList: null });
        }
    }

    onClose = () => {
        if (this.props.visible) {
            if (this.formRef.current?.instance) {
                this.formRef.current.instance.clear();
            }

            this.props.onDialogResult();
            this.formData = {}
            this.setState({ copyattachment: false, isbusy: false });
        }
    }

    saveItem = async () => {
        try {
            this.setState({ isbusy: true }, async () => {
                try {
                    const payload = {
                        groupid: this.props.workflowItem.groupID,
                        contractids: this.state.selectedContracts,
                        notificationid: this.props.params.notificationid,
                        projectid: this.props.projectId,
                        copyattachment: this.state.copyattachment,
                        documenttype: this.props.documentType
                    }

                    const { data: newObjIDs } = await commonCrud.copyNotificationToOtherContracts(payload);
                    
                    if (newObjIDs.length > 0) {
                        this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'NOTIFICATION.POPUP.COPY.SUCCESSFUL' }), { variant: 'success', });
                        this.setState({ isnotificationcopied: true, newDraftsIds: newObjIDs });
                    }
                    else {
                        this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'NOTIFICATION.POPUP.COPY.FAILED' }), { variant: 'warning', });
                    }

                    this.setState({
                        copyattachment: false,
                        projects: null,
                        contractsList: null,
                        isbusy: false
                    });
                } catch (err) {
                    this.setState({ isbusy: false });
                    this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                }
            })
        } catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }

        return;
    }

    onDropdownValueChanged = (e) => {
        if (e.value == null) {
            //selection cleared
            this.dataGrid.current.instance.clearSelection();
        }
    }

    onCopyAttachmentChanged = (e) => {
        this.setState({ copyattachment: e.value })
    }

    onLinkClicked = (e) => {
        const navigationPath = this.props.workflowItem.paths.basePathTemplate.replace(":contractid", e.row.data.contractid) + Number(e.row.data.notificationid);
        window.open(navigationPath, '_blank');
    }

    render() {
        if (!this.props.visible) {
            return null;
        }

        if (this.state.isnotificationcopied && this.state.newDraftsIds) {
            return (
                <Popup
                    visible={this.props.visible}
                    onHiding={this.onClose}
                    dragEnabled={true}
                    hideOnOutsideClick={true}
                    showTitle={true}
                    title={this.props.intl.formatMessage({ id: 'NOTIFICATION.POPUP.COPY.COPIED.DRAFTS' })}
                    width={600}
                    height='auto'
                >
                    <DataGrid
                        ref={this.gridRef}
                        //width='100%'
                        height='100%'
                        dataSource={this.state.newDraftsIds}
                        keyExpr="contractid"
                        hoverStateEnabled={false}
                        rowAlternationEnabled={true}
                        noDataText={'No Data'}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        remoteOperations={false}
                        columns={[
                            {
                                caption: this.props.intl.formatMessage({ id: 'NOTIFICATION.POPUP.COPY.COPIED.CONTRACTID' }),
                                dataField: "contractid"
                            },
                            {
                                caption: this.props.intl.formatMessage({ id: 'NOTIFICATION.POPUP.COPY.COPIED.NOTIFICATIONID' }),
                                dataField: "notificationid"
                            },
                            {
                                type: "buttons",
                                buttons: [{ hint: 'Go to draft', icon: 'link', onClick: this.onLinkClicked }]
                            }
                        ]}
                        >
                        <HeaderFilter visible={true} />
                        <FilterPanel visible={true} />
                        <ColumnFixing enabled={true} />
                        <Scrolling mode="virtual" showScrollbar={true} useNative={true} columnRenderingMode="virtual" rowRenderingMode="virtual" />
                    </DataGrid>

                </Popup>
            )
        }

        return (
            <Popup
                id='createActionDuplicatePopup'
                visible={this.props.visible}
                onHiding={this.onClose}
                dragEnabled={true}
                hideOnOutsideClick={false}
                showTitle={true}
                title={this.props.intl.formatMessage({ id: "NOTIFICATION.POPUP.COPY.TITLE" })}
                width={600}
                disabled={this.state.isbusy}
                height='auto'>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <div style={{ flex: 1, paddingRight: '10px' }}>
                                <span>{this.props.intl.formatMessage({ id: 'NOTIFICATION.POPUP.DUPLICATE.COPY_ATTACHMENTS' })}:&nbsp;&nbsp;</span>
                            </div>
                            <div style={{ flex: 2 }}>
                                <Switch
                                    dataField='copyattachment'
                                    defaultValue={this.state.copyattachment}
                                    onValueChanged={this.onCopyAttachmentChanged}
                                    switchedOffText={this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_NO" })}
                                    switchedOnText={this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_YES" })}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <div style={{ flex: 1, paddingRight: '10px' }}>
                                <span>{this.props.intl.formatMessage({ id: 'NOTIFICATION.POPUP.PROJECT' })}:&nbsp;&nbsp;</span>
                            </div>
                            <div style={{ flex: 2 }}>
                                <SelectBox
                                    value={this.props.projectId}
                                    valueExpr="projectid"
                                    displayExpr="display"
                                    dataField='project'
                                    dataSource={this.state.projects}
                                    disabled={true}
                                    //defaultValue={this.state.project}
                                    onValueChanged={this.updateContractsList}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <div style={{ flex: 1, paddingRight: '10px' }}>
                                <span>{this.props.intl.formatMessage({ id: 'NOTIFICATION.POPUP.CONTRACTS' })}:&nbsp;&nbsp;</span>
                            </div>
                            <div style={{ flex: 2 }}>
                                <DropDownBox
                                    ref={this.contractsDropdown}
                                    id='AvailableContractsDropDown'
                                    dataSource={this.state.contractsList}
                                    valueExpr="contractid"
                                    deferRendering={true}
                                    displayExpr="contractname"
                                    placeholder={this.props.intl.formatMessage({ id: "CONTRACT.USERS.BULK.CONTRACTS_PH" })}
                                    showClearButton={true}
                                    value={this.state.selectedContracts}
                                    onValueChanged={this.onDropdownValueChanged}
                                    showDropDownButton={true}
                                    dropDownOptions={{ 
                                        width: '80vw', 
                                        maxHeight: '50vh', 
                                        showCloseButton: true, 
                                        showTitle: true, 
                                        title: this.props.intl.formatMessage({ id: "NOTIFICATION.POPUP.PROJECT.CONTRACTS" }),
                                        position:{ at:"bottom",of:'#AvailableContractsDropDown'}
                                    }}
                                >
                                    <Validator>
                                        <RequiredRule message={this.props.intl.formatMessage({ id: "CONTRACT.USERS.FIELD.CONTRACTS" }) + ' ' + this.props.intl.formatMessage({ id: 'GENERAL.FORMS.VALIDATION.REQUIRED' })} />
                                    </Validator>
                                    <FilterRow visible={true} />
                                    <Column allowFiltering={false} />
                                    <DataGrid
                                        ref={this.dataGrid}
                                        keyExpr="contractid"
                                        dataSource={this.state.contractsList}
                                        columns={this.contractGridColumns}
                                        hoverStateEnabled={true}
                                        onSelectionChanged={this.contractsGridOnSelectionChanged}
                                        style={{ height: '100%' }}
                                    >
                                        <SearchPanel visible={true} />
                                        <HeaderFilter visible={true} />
                                        <Selection mode="multiple" allowSelectAll="true" selectAllMode="allPages" showCheckBoxesMode="always" />
                                        <Scrolling mode="virtual" showScrollbar={true} useNative={true} columnRenderingMode="virtual" rowRenderingMode="virtual" />
                                        <FilterRow visible={true} />
                                        <ColumnChooser enabled={true} />
                                        <ColumnFixing enabled={true} />
                                    </DataGrid>
                                </DropDownBox>
                            </div>
                        </div>
                    </div>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{ text: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_CANCEL' }), onClick: this.onClose }}>
                </ToolbarItem>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_CREATE_DRAFTS' }),
                        icon: this.state.isbusy ? 'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--dark' : 'save',
                        onClick: this.saveItem
                    }}>
                </ToolbarItem>
            </Popup >
        );
    }
}

const mapStateToProps = store => ({
    contract: store.contract.contract,
    contractTemplate: store.contract.template,
});

export default withSnackbar(injectIntl(connect(mapStateToProps)(ActionCopyModal)));