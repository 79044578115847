import React from 'react';
import { Nav } from "react-bootstrap";
import { Badge } from '@mui/material';


export default function SideBarNavigationTabAction(props) {
    const { partyHasRoleInContract, actions } = props;
    if (partyHasRoleInContract || (actions && actions.length > 0)) {
        return (
            <Nav.Item className="nav-item">
                <Badge overlap="rectangular" badgeContent={actions.length} color="error">
                    <Nav.Link eventKey={props.eventKey} className="nav-link show">
                        {props.title}
                    </Nav.Link>
                </Badge>
            </Nav.Item>
        );
    }
    else {
        return null;
    }
}