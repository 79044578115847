/* eslint-disable no-eval, react/no-direct-mutation-state */
import React from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as commonCrud from '../../../crud/common.crud';
import Switch from 'devextreme-react/switch';
import { withSnackbar } from 'notistack';
import { withRouter } from '../../../shared/hoc/withRouter';

export const RESPONSES = {
    SAVE: 'SAVE',
    CANCEL: 'CANCEL',
};

const initialState = {
    copyattachment: false,
    isbusy: false
}

class ActionDuplicateModal extends React.Component {
    constructor(props) {
        super(props);
        this.validatorRef = React.createRef();
        this.validatorName = 'ActionDuplicateModalValidator';
        this.formRef = React.createRef();
        this.formData = {}
        this.defaultFields = null;
        this.state = { ...initialState }
    }

    onClose = () => {
        if (this.props.visible) {
            if (this.formRef.current?.instance) {
                this.formRef.current.instance.clear();
            }

            this.props.onDialogResult();
            this.formData = {}
            this.setState({ ...initialState });
        }
    }

    saveItem = async () => {
        try {
            this.setState({ isbusy: true }, async () => {
                try {
                    const payload = {
                        groupid: this.props.workflowItem.groupID,
                        contractids: [this.props.contract.contractid],
                        notificationid: this.props.params.notificationid,
                        projectid: this.props.projectId,
                        copyattachment: this.state.copyattachment,
                        documenttype: this.props.documentType
                    }

                    const { data: newObj } = await commonCrud.copyNotificationToOtherContracts(payload);

                    this.props.onDialogResult(RESPONSES.SAVE);
                    this.setState({ ...initialState });

                    if (newObj.length > 0) {
                        this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'NOTIFICATION.POPUP.DUPLICATE.SUCCESSFUL' }), { variant: 'success', });

                        this.props.navigate(this.props.workflowItem.paths.basePath + Number(newObj[0].notificationid));
                    }
                    else {
                        this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'NOTIFICATION.POPUP.DUPLICATE.FAILED' }), { variant: 'warning', });
                    }
                } catch (err) {
                    this.setState({ isbusy: false });
                    this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
                }
            })
        } catch (err) {
            this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
        }

        return;
    }

    onCopyAttachmentChanged = (e) => {
        this.setState({ copyattachment: e.value })
    }

    render() {
        if (!this.props.visible) {
            return null;
        }

        return (
            <Popup
                id='createActionDuplicatePopup'
                visible={this.props.visible}
                onHiding={this.onClose}
                dragEnabled={true}
                hideOnOutsideClick={false}
                showTitle={true}
                title={this.props.intl.formatMessage({ id: "NOTIFICATION.POPUP.DUPLICATE.TITLE" })}
                width={600}
                disabled={this.state.isbusy}
                height='auto'>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>{this.props.intl.formatMessage({ id: 'NOTIFICATION.POPUP.DUPLICATE.COPY_ATTACHMENTS' })}:&nbsp;&nbsp;</span>
                        <Switch
                            dataField='copyattachment'
                            defaultValue={this.state.copyattachment}
                            onValueChanged={this.onCopyAttachmentChanged}
                            switchedOffText={this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_NO" })}
                            switchedOnText={this.props.intl.formatMessage({ id: "GENERAL.FORMS.SWITCH_YES" })}
                        />
                    </div>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{ text: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_CANCEL' }), onClick: this.onClose }}>
                </ToolbarItem>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_CREATE_DRAFT' }),
                        icon: this.state.isbusy ? 'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--dark' : 'save',
                        onClick: this.saveItem
                    }}>
                </ToolbarItem>
            </Popup >
        );
    }
}

const mapStateToProps = store => ({
    contract: store.contract.contract,
    contractTemplate: store.contract.template,
});

export default withRouter(withSnackbar(injectIntl(connect(mapStateToProps)(ActionDuplicateModal))));