/* eslint-disable no-eval, react/no-direct-mutation-state */
import React from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { connect } from "react-redux";
import BiDynamicForm from '../../../partials/layout/BiDynamicForm';
import { ValidationSummary, ValidationGroup } from 'devextreme-react';
import { injectIntl, FormattedMessage } from "react-intl";
import TemplateHelper from '../../../Templates/TemplateHelper';
import { CheckBox } from 'devextreme-react/check-box';
import * as actionCrud from '../../../crud/action.crud';
import { Form, Item as FormItem, SimpleItem, RequiredRule, GroupItem, RangeRule, Label as FormLabel } from 'devextreme-react/form';
import { typeOptions as actionTypes } from "../../admin/Contracts/Contract/ContractActions";
import moment from 'moment';
import { withSnackbar } from 'notistack';
import { Link } from "react-router-dom";
import { withRouter } from '../../../shared/hoc/withRouter';
import Spinner from '../../../partials/layout/Spinner';
import { LoadIndicator } from 'devextreme-react/load-indicator';


export const RESPONSE = {
    OK: 'OK',
    CANCEL: 'CANCEL',
    CREATE: 'CREATE'
};

class ActionModalPostAction extends React.Component {
    constructor(props) {
        super(props);
        let createActions = [];
        let clearActionsOnClause = [];
        let userCreateSelectionExist = false;
        let { availableActionTypes } = this.props.contractTemplate;
        if (this.props.selectedClause?.postActions?.length > 0) {
            //CHECK POST-ACTION Definitions on clause item
            createActions = this.props.selectedClause.postActions.filter(act => (act.action === "CREATE" && availableActionTypes.some(typ => typ.value === act.actionType)));
            userCreateSelectionExist = createActions.some(act => !!act.askUser);
            clearActionsOnClause = this.props.selectedClause.postActions.filter(act => (act.action === "CLEAR" && availableActionTypes.some(typ => typ.value === act.actionType)));
        }

        ///
        let possibleClearActionTypes = [];
        ///Calculate CLEAR Action Types
        //1-Current Workflow
        let actionTypeOfWF = actionTypes.find(ty => ty.wfid === this.props.workflowItem.id);
        if (actionTypeOfWF) {
            possibleClearActionTypes.push(actionTypeOfWF.value)
        }
        //2- Types on Clause definition
        possibleClearActionTypes.push(...clearActionsOnClause.map(x => x.actionType));

        this.state = {
            createActions,
            userCreateSelectionExist,
            availableActionTypes,
            possibleClearActionTypes,
            clearOptions: [],
            loadingClearItems: false,
            earliestRecurringClearActionID: 0
        }
    }

    componentDidMount(prevProps, prevState) {
        if(this.props.mode === 'COMMUNICATE'){
            this.loadClearOptions();
        }
        
    }

    loadClearOptions = async () => {
        if (this.state.possibleClearActionTypes.length > 0) {
            this.setState({ loadingClearItems: true }, async () => {
                try {
                    const { data: clearOptions } = await actionCrud.loadClearActionOptions(this.props.contract.contractid, this.props.workflowItem.id, this.props.params.notificationid, this.state.possibleClearActionTypes);
                    let earliestRecurringClearActionID = clearOptions.filter(a => !!a.recurring)[0]?.actionid;
                    for (let index = 0; index < clearOptions.length; index++) {
                        clearOptions[index].defaultValue = (!!clearOptions[index].relationtosourceexist || clearOptions[index].actionid === this.state.earliestRecurringClearActionID);
                        this.props.formRef.current.instance.updateData("CLEAR_" + clearOptions[index].actionid, clearOptions[index].defaultValue);
                    }
                    
                    this.setState({ clearOptions, earliestRecurringClearActionID, loadingClearItems: false })
            } catch (err) {
                this.setState({ clearOptions:[], earliestRecurringClearActionID:0, loadingClearItems: false })
                this.props.enqueueSnackbar("Failed to load clear actions: "+ err.toString(), { variant: 'error', });
            }
            });

        }
    }

    onClearItemSourceURLClick = (clearOption, e) => {
        e.preventDefault();
        if (clearOption.sourceworkflowid && clearOption.sourcerecordid) {
            if (this.props.contractTemplate.workflowsQA[clearOption.sourceworkflowid]) {
                if (this.props.contractTemplate.workflowsQA[clearOption.sourceworkflowid].paths?.basePath) {
                    this.props.navigate(this.props.contractTemplate.workflowsQA[clearOption.sourceworkflowid].paths.basePath + clearOption.sourcerecordid);

                }
            }
        }
    }
    handleCheckBoxValueChange = (clearOption, e) => {
        this.props.formRef.current.instance.updateData("CLEAR_" + clearOption.actionid, e.value)
    }

    render() {

        if (this.props.mode === 'COMMUNICATE') {
            return (
                <>
                    <Form ref={this.props.formRef}>
                        {
                            this.state.createActions.length > 0 && (
                                <GroupItem caption={`${this.props.intl.formatMessage({ id: "ACTION.MODAL.CREATE_PENDING_TITLE" })}${this.state.userSelectionExist ? ` ${this.props.intl.formatMessage({ id: "ACTION.MODAL.CREATE_PENDING_SELECT_OPTION" })}` : ""}`}>
                                    {
                                        this.state.createActions.map((postAction, index) =>
                                        (
                                            <FormItem
                                                key={"CREATE_" + index}
                                                visible={true}
                                                label={{ visible: false }}
                                                dataField={postAction.actionType}
                                                editorType='dxCheckBox'

                                                editorOptions={{
                                                    text: this.state.availableActionTypes.find(typ => typ.value === postAction.actionType).display,
                                                    readOnly: !postAction.askUser,
                                                    hoverStateEnabled: !!postAction.askUser,
                                                    value: !postAction.askUser
                                                }}
                                            />
                                        ))
                                    }

                                </GroupItem>
                            )
                        }
                        {
                            this.state.clearOptions.length > 0 && (
                                <GroupItem caption={this.props.intl.formatMessage({ id: "ACTION.MODAL.CLEAR_PENDING_TITLE" })}>
                                    {
                                        this.state.clearOptions.map((clearOption, index) =>
                                        (
                                            <SimpleItem
                                                key={"CLEAR_" + index}
                                                visible={true}
                                                label={{ visible: false }}
                                                dataField={"CLEAR_" + clearOption.actionid}
                                                editorType='dxCheckBox'
                                            // render={this.renderChackbox.bind(this, clearOption)}
                                            // editorOptions={{
                                            //     text: "[" + moment(clearOption.duedate).format('DD/MM/YYYY') + "] " + clearOption.subject,
                                            //     hoverStateEnabled: true,
                                            //     defaultValue: false
                                            // }}
                                            >
                                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                                    <CheckBox
                                                        style={{ display: "flex" }}
                                                        text={`[${moment(clearOption.duedate).format('DD/MM/YYYY')}] ${clearOption.subject}${clearOption.recurring ? " [R]" : ""}`}//+this.state.availableActionTypes.find(typ => typ.value === clearOption.actiontype).display ,
                                                        hoverStateEnabled={true}
                                                        defaultValue={clearOption.defaultValue}
                                                        onValueChanged={this.handleCheckBoxValueChange.bind(this, clearOption)}
                                                    />
                                                    {
                                                        clearOption.sourceworkflowid && clearOption.sourcerecordid && (
                                                            <a style={{ display: "flex" }} href="#" onClick={this.onClearItemSourceURLClick.bind(this, clearOption)} className="kt-nav__link">
                                                                <i id="linkToClear" className="dx-icon dx-icon-link align-middle ml-2" style={{ fontSize: '20px' }}></i>
                                                            </a>
                                                        )
                                                    }
                                                </div>
                                            </SimpleItem>

                                        ))
                                    }


                                </GroupItem>
                            )
                        }
                    </Form>
                    {
                        this.state.loadingClearItems && (
                            <div style={{ display: 'flex', flexDirection: 'column', textAlign: "center" }}>
                                <LoadIndicator
                                    id="medium-indicator"
                                    style={{alignSelf:"center"}}
                                    height={40}
                                    width={40}
                                />
                                <span>
                                    <FormattedMessage id="ACTIONS.INFO.LOADING_CLEAR_ITEMS"  />
                                </span>
                            </div>
                        )
                    }
                </>
            )
        }
        else { return null; }
    }
}



export default withRouter(withSnackbar(injectIntl(ActionModalPostAction)));