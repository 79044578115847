import React from 'react';
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import DataGridBase from "../../../../partials/layout/DataGridBase";
import { Badge } from "react-bootstrap";
import * as proposalCrud from '../../../../crud/proposal.crud';
import { notificationAggregateListTagsFilter, notificationUniqueTagsFilter } from "../../../../store/helpers/FilterHelper";
import CustomFieldHelper from "../../_Helpers/CustomFieldHelper";
import { withListPageProps } from '../../../../shared/hoc/withListPageProps';

class IssueList extends React.Component {
    constructor(props) {
        super(props);
        this.replyOptions = props.placeholders.find(ph => ph.replacementKey === 'ReplyOptions')
            .values.reduce((obj, item) => ({ ...obj, [item.field]: item.display }), {});
        this.notificationsTagsEnabled = process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true' && props.contract.specifictags?.length > 0;
        this.notificationsRelsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true';
        this.customFields = [];
        if (props.customFields) {
            if (props.customFields.COMMUNICATION && props.customFields.COMMUNICATION.length > 0) {
                this.customFields.push(...(props.customFields.COMMUNICATION.filter(cf => cf.includelisting)));
            }
            if (props.customFields.REPLY && props.customFields.REPLY.length > 0) {
                this.customFields.push(...(props.customFields.REPLY.filter(cf => cf.includelisting)));
            }
        }
        this.state = {
            notifications: null,
            tags: null
        };
    }
    componentDidMount() {
        this.fetchProposals()
    }
    fetchProposals = () => {
        const { companyid, projectid, contractid } = this.props.contract;
        proposalCrud.getProposals(companyid, projectid, contractid, this.props.accessibleWfIds)
            .then(response => {
                let listData = notificationAggregateListTagsFilter(JSON.parse(response.data.aggregatelist));
                listData = (this.props.workflowItem.userFilters.mainNotice.viewDraftAllowed === true) ?
                    listData :
                    listData.filter(ntf => ntf.showtasknotification === 1);

                let tagFilters = notificationUniqueTagsFilter(JSON.parse(response.data.uniquetags));

                this.setState({ notifications: listData, tags: tagFilters });
            })
            .catch(error => { })
    }
    onTableSelectionChanged = ({ selectedRowsData }) => {
        if (selectedRowsData.length > 0) {
            const data = selectedRowsData[0];
            const url = this.calculateUrl(data, true);
            this.props.navigate(url);
        }
    }
    calculateUrl = (rowData, skipHostUrl) => {
        return  (skipHostUrl ? '' : process.env.PUBLIC_URL) +this.props.workflowItem.paths.basePath + rowData.proposalid;
    }
    renderStatusColumn = (cellInfo) => {
        let badgeVariant = 'secondary';
        switch (cellInfo.data.status) {
            case 'Awaiting Acceptance':
                badgeVariant = 'warning';
                break;
            case 'Not Communicated':
                badgeVariant = 'secondary';
                break;
            case 'Not Accepted':
                badgeVariant = 'danger';
                break;
            case 'Accepted':
                badgeVariant = 'success';
                break;
            default:
                break;
        }
        const displayText = this.calculateStatusValue(cellInfo.data);
        return (<Badge variant={badgeVariant}>{displayText}</Badge>);
    }
    calculateStatusValue = (rowData) => {
        if (this.replyOptions.hasOwnProperty(rowData.status)) {
            return this.replyOptions[rowData.status];
        }
        else {
            return this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.' + rowData.status });
        }
    }
    formatCurrency = () => {
        return this.props.contract.currency
    }
    render() {
        if (!this.state.notifications) {
            return (<PortletSpinner />);
        }

        const columns = [
            { resource: "GENERAL.FORMS.FIELD.ID", dataField: "referenceid", dataType: 'string', renderReferenceidLink: true },
            { resource: "GENERAL.FORMS.FIELD.SUBJECT", dataField: 'subject' },
            { resource: "GENERAL.FORMS.FIELD.SUBMITTED_DATE", dataField: "notificationdate", dataType: 'date' },
            { resource: "GENERAL.FORMS.FIELD.SUBMITTED_BY", dataField: "communicatedby" },
            { resource: "GENERAL.FORMS.FIELD.REPLY_REQUIRED", dataField: "replyrequireddate", dataType: 'date' },
            { resource: "GENERAL.FORMS.FIELD.AMOUNT", dataField: 'proposedcost', dataType: 'currency', currencyCode: this.props.contract.currency },
            { resource: "GENERAL.FORMS.FIELD.DAYS", dataField: 'proposeddays' },
            { resource: "GENERAL.FORMS.FIELD.STATUS", dataField: "status", cellRender: this.renderStatusColumn, calculateCellValue: this.calculateStatusValue },
            { resource: "GENERAL.FORMS.FIELD.TYPE", dataField: "clause", visible: false },
            { resource: "GENERAL.FORMS.FIELD.REPLIED_BY", dataField: "replyby", visible: false },
            { resource: "GENERAL.FORMS.FIELD.REPLY_DRAFT_BY", dataField: "draftreplyby", visible: false },
            { resource: "GENERAL.FORMS.FIELD.DETAILS", dataField: "description", isHtml: true },
            { resource: "GENERAL.FORMS.FIELD.REPLY_COMMENTS", dataField: "replycomment", isHtml: true },
            { resource: "GENERAL.FORMS.FIELD.LINK", dataField: "url", calculateCellValue: this.calculateUrl, visible: false, showInColumnChooser: false },
            { resource: "GENERAL.FORMS.FIELD.CURRENCY", dataField: "currency", visible: false, calculateCellValue: this.formatCurrency },
            { resource: "PROJECT.FIELD.CODE", dataField: "projectcode", visible: false },
            { resource: "PROJECT.FIELD.NAME", dataField: "project", visible: false },
            { resource: "GENERAL.FORMS.FIELD.REPLY_RECIEVED", dataField: "replydate", dataType: 'date', visible: false },
            { resource: "GENERAL.FORMS.REPLING_PARTY", dataField: "replyingparty", lookup: { dataSource: this.props.contractTemplate.parties.options, valueExpr: 'id', displayExpr: 'display' }, visible: false }

        ];
        if (this.props.workflowItem.assetFieldsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.FIELD.ASSET_NAME", dataField: "assetname", visible: false },
                { resource: "GENERAL.FORMS.FIELD.ASSET_TYPE", dataField: "assettype", visible: false },
            );
        }
        if (this.notificationsTagsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.FIELD.PORTFOLIO", dataField: "tags_portfolio", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.PortfolioFilter } },
                { resource: "GENERAL.FORMS.FIELD.PROGRAMME", dataField: "tags_programme", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.ProgrammeFilter } },
                { resource: "GENERAL.FORMS.FIELD.PROJECT", dataField: "tags_project", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.ProjectFilter } },
                { resource: "GENERAL.FORMS.FIELD.OTHER", dataField: "tags_other", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.OtherFilter } },

            )
        }
        if (this.notificationsRelsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.TAB_RELATIONS", dataField: "relatednotices", visible: false },
            )
        }
        columns.push(...CustomFieldHelper.MapCustomFieldsToDataGridColumns(this.customFields));

        return (
            <Portlet>
                <PortletBody>
                    <DataGridBase
                        version="1"
                        title={this.props.intl.formatMessage({ id: 'GENERAL.REGISTER.TITLE2' }, { ITEM_NAME: this.props.workflowItem.pageTitle })}
                        createPath={this.props.workflowItem.userFilters.mainNotice.createAllowed ? this.props.workflowItem.paths.createPath : null}
                        workflowItem={this.props.workflowItem}
                        name={this.props.workflowItem.pageTitle}
                        dataSource={this.state.notifications}
                        keyExpr="proposalid"
                        selection={{ mode: 'single' }}
                        onSelectionChanged={this.onTableSelectionChanged}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={false}
                        columns={columns}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}


export default withListPageProps(IssueList);
