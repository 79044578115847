import React from 'react';
import { connect } from "react-redux";
import { ListGroup, Badge } from "react-bootstrap";
import ExpandableActionControl2 from './ExpandableActionControl2';
import { withRouter } from '../../../../shared/hoc/withRouter';
import { Collapse } from "react-bootstrap";


class ManualActionGroup extends React.Component {

    state = {
        expanded: false,
    }
    handleChange = event => {
        event.preventDefault();
        this.setState({ expanded: !this.state.expanded });
    };
    componentDidUpdate(prevProps, prevState) {
        if (prevProps) {
            if (prevProps.isOpen !== this.props.isOpen) {
                if (!this.props.isOpen) {
                    this.setState({ expanded: false });
                }
            }
        }
    }

    render() {

        const toggleContainerClasses = ['kt-notification__item'];
        if (this.state.expanded) {
            toggleContainerClasses.push('kt-notification__item--open');
        }
        let notification = null;
        let badgeVariant = 'secondary';
        if (this.props.actionsObj.items?.length > 0) {
            notification = this.props.actionsObj.items.length;
            badgeVariant = 'danger';
        }
        else {
            notification = 0;
        }
        
        return (
            <>
                <a  style={{ paddingLeft: 10, fontWeight: 500, fontSize: '14px', fontFamily: '\'Helvetica Neue\', \'Segoe UI\', Helvetica, Verdana, sans-serif', color: '#333' }} onClick={this.handleChange} className={toggleContainerClasses.join(' ')}>
                    <div className="kt-notification__item-icon">
                        <Badge variant={badgeVariant}>{notification}</Badge>
                    </div>
                    <div className="kt-notification__item-details">
                        <div className="kt-notification__item-title" style={{ fontWeight: 400, fontSize: '14px', color: '#333' }}>
                            {this.props.title}
                        </div>
                    </div>
                </a>
                <Collapse in={this.state.expanded}>
                    <div>
                        <ListGroup>
                            {
                                this.props.actionsObj.subGroups && this.props.actionsObj.subGroups.map((item, index) => (
                                    <ExpandableActionControl2
                                        key={this.props.skey + "SGRP"+ index}
                                        skey={this.props.skey + "SGRP"+ index}
                                        panelCloseBtnRef={this.props.panelCloseBtnRef}
                                        contractid={this.props.contract.contractid}
                                        isOpen={this.props.isOpen}
                                        onSizeChanged={() => { if (this._scrollBarRef) { this._scrollBarRef.forceUpdate(); } }}
                                        actionsObj={item}
                                        party={this.props.party}
                                        contract={this.props.contract}
                                        contractTemplate={this.props.contract}
                                    />
                                ))
                            }

                        </ListGroup>
                    </div>
                </Collapse>
            </>
        );
    }
}


const mapStateToProps = store => ({
    contract: store.contract.contract,
    contractTemplate: store.contract.template,
});



export default withRouter(connect(mapStateToProps)(ManualActionGroup));