import React from 'react';
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import DataGridBase from "../../../../partials/layout/DataGridBase";
import { Badge } from "react-bootstrap";
import * as projectOrderCrud from '../../../../crud/projectOrder.crud';
import { notificationAggregateListTagsFilter, notificationUniqueTagsFilter } from "../../../../store/helpers/FilterHelper";
import CustomFieldHelper from "../../_Helpers/CustomFieldHelper";
import { withListPageProps } from '../../../../shared/hoc/withListPageProps';

class ProjectListPage extends React.Component {
    constructor(props) {
        super(props);
        this.notificationsTagsEnabled = process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true' && props.contract.specifictags?.length > 0;
        this.notificationsRelsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true';
        this.customFields = [];
        if (props.customFields) {
            if (props.customFields.COMMUNICATION && props.customFields.COMMUNICATION.length > 0) {
                this.customFields.push(...(props.customFields.COMMUNICATION.filter(cf => cf.includelisting)));
            }
            if (props.customFields.REPLY && props.customFields.REPLY.length > 0) {
                this.customFields.push(...(props.customFields.REPLY.filter(cf => cf.includelisting)));
            }
        }
        this.state = {
            notifications: null,
            tags: null
        };
    }
    componentDidMount() {
        this.fetchProjectOrders();
    }
    fetchProjectOrders = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const { data: responseObj } = await projectOrderCrud.getProjectOrdersReduced(companyid, projectid, contractid, this.props.accessibleWfIds);
            let listData = notificationAggregateListTagsFilter(responseObj.aggregatelist);
            listData = (this.props.workflowItem.userFilters.mainNotice.viewDraftAllowed === true) ?
                listData :
                listData.filter(ntf => ntf.showtasknotification === 1);
            let tagFilters = notificationUniqueTagsFilter(responseObj.uniquetags);
            this.setState({ notifications: listData, tags: tagFilters });
        } catch (error) {
            console.log(error)
            this.props.enqueueSnackbar('Failed to load communications', { variant: 'error', });
        }
    }
    onTableSelectionChanged = ({ selectedRowsData }) => {
        if (selectedRowsData.length > 0) {
            const data = selectedRowsData[0];
            this.props.navigate(this.props.workflowItem.paths.basePath + data.projectorderid);
        }
    }
    calculateUrl = (rowData) => {
        return  this.props.workflowItem.paths.basePath + rowData.projectorderid;
    }
    renderStatusColumn = (cellInfo) => {
        let badgeVariant = cellInfo.data.showtasknotification === 1 ? 'success' : 'secondary';
        const displayText = this.calculateStatusValue(cellInfo.data);
        return (<Badge variant={badgeVariant}>{displayText}</Badge>);
    }
    calculateStatusValue = (rowData) => {
        return this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.' + rowData.status });
    }
    render() {
        if (!this.state.notifications) {
            return (<PortletSpinner />);
        }

        const columns = [
            { resource: "GENERAL.FORMS.FIELD.ID", dataField: "referenceid", dataType: 'string', renderReferenceidLink: true },
            { resource: "GENERAL.FORMS.FIELD.SUBJECT", dataField: "subject" },
            { resource: "GENERAL.FORMS.FIELD.NOTIFIED_BY", dataField: "communicatedby" },
            { resource: "GENERAL.FORMS.FIELD.NOTIFIED_DATE", dataField: "notificationdate", dataType: 'date' },
        ];
        if (this.props.CENProjectOrderOptionEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.FIELD.REVISIED.START.DATE", dataField: "revisedstartdate", dataType: 'date' },
                { resource: "GENERAL.FORMS.FIELD.REVISIED.COMPLETION.DATE", dataField: "revisedcompletiondate", dataType: 'date' },
                { resource: "GENERAL.FORMS.FIELD.REVISIED.TOTAL.PRICES", dataField: "revisedprojectordertotal", dataType: 'currency', currencyCode: this.props.contract.currency },
            );
        }
        if (this.props.fields.Description) {
            columns.push(
                { caption: this.props.fields.Description, dataField: "description", isHtml: true, visible: false },
            );
        }
        if (this.props.fields.ItemsList) {
            columns.push(
                { caption: this.props.fields.ItemsList, dataField: "pricelistdescription", visible: false },
            );
        }
        if (this.props.fields.TotalListPrice) {
            columns.push(
                { caption: this.props.fields.TotalListPrice, dataField: "pricelisttotal", dataType: 'currency', currencyCode: this.props.contract.currency, visible: false },
            );
        }
        if (this.props.fields.CompensationDescription) {
            columns.push(
                { caption: this.props.fields.CompensationDescription, dataField: "compensationeventdescripton", visible: false },
            );
        }
        if (this.props.fields.TotalAssessed) {
            columns.push(
                { caption: this.props.fields.TotalAssessed, dataField: "compensationeventtotal", dataType: 'currency', currencyCode: this.props.contract.currency, visible: false },
            );
        }
        if (this.props.fields.TotalPrice) {
            columns.push(
                { caption: this.props.fields.TotalPrice, dataField: "projectordertotal", dataType: 'currency', currencyCode: this.props.contract.currency },
            );
        }
        if (this.props.fields.StartDate) {
            columns.push(
                { caption: this.props.fields.StartDate, dataField: "startdate", dataType: 'date' },
            );
        }
        if (this.props.fields.CompletionDate) {
            columns.push(
                { caption: this.props.fields.CompletionDate, dataField: "completiondate", dataType: 'date' },
            );
        }
        if (this.props.fields.DelayAmount) {
            columns.push(
                { caption: this.props.fields.DelayAmount, dataField: "delaydamagestotal", dataType: 'currency', currencyCode: this.props.contract.currency, visible: false }
            );
        }
        if (this.props.fields.DelayPaymentPeriod) {
            columns.push(
                { caption: this.props.fields.DelayPaymentPeriod, dataField: "delaydamagestype", visible: false }
            );
        }
        if (this.props.fields.BonusRate) {
            columns.push(
                { caption: this.props.fields.BonusRate, dataField: "bonusrate", dataType: 'currency', currencyCode: this.props.contract.currency, visible: false }
            );
        }
        if (this.props.fields.BonusCalculationPeriod) {
            columns.push(
                { caption: this.props.fields.BonusCalculationPeriod, dataField: "bonuscalculationperiod", visible: false }
            );
        }

        columns.push(
            { resource: "GENERAL.FORMS.FIELD.STATUS", dataField: "status", cellRender: this.renderStatusColumn, calculateCellValue: this.calculateStatusValue },
            { resource: "GENERAL.FORMS.FIELD.LINK", dataField: "url", calculateCellValue: this.calculateUrl, visible: false, showInColumnChooser: false },
            { resource: "PROJECT.FIELD.CODE", dataField: "projectcode", visible: false },
            { resource: "PROJECT.FIELD.NAME", dataField: "project", visible: false }
        );
        if (this.props.workflowItem.assetFieldsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.FIELD.ASSET_NAME", dataField: "assetname", visible: false },
                { resource: "GENERAL.FORMS.FIELD.ASSET_TYPE", dataField: "assettype", visible: false },
            );
        }
        if (this.notificationsTagsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.FIELD.PORTFOLIO", dataField: "tags_portfolio", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.PortfolioFilter } },
                { resource: "GENERAL.FORMS.FIELD.PROGRAMME", dataField: "tags_programme", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.ProgrammeFilter } },
                { resource: "GENERAL.FORMS.FIELD.PROJECT", dataField: "tags_project", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.ProjectFilter } },
                { resource: "GENERAL.FORMS.FIELD.OTHER", dataField: "tags_other", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.OtherFilter } }
            )
        }
        if (this.notificationsRelsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.TAB_RELATIONS", dataField: "relatednotices", visible: false },
            )
        }
        if (this.props.projectOrderCertifiedStartDateFieldName) {
            columns.push(
                { caption: this.props.intl.formatMessage({ id: 'GENERAL.REGISTER.FIELD.PARTY.CERTIFIEDDATE' }, { PARTY: this.props.globalResources.SUPPLIER_NAME, CERTIFIED_DATE: this.props.projectOrderCertifiedStartDateFieldName }), dataField: "suppliercertifiedstartdate", dataType: 'date', visible: false },
                { caption: this.props.intl.formatMessage({ id: 'GENERAL.REGISTER.FIELD.PARTY.CERTIFIEDDATE' }, { PARTY: this.props.globalResources.PM_NAME, CERTIFIED_DATE: this.props.projectOrderCertifiedStartDateFieldName }), dataField: "pmcertifiedstartdate", dataType: 'date', visible: false },
                { caption: this.props.intl.formatMessage({ id: 'GENERAL.REGISTER.FIELD.PARTY.CERTIFIEDDATE' }, { PARTY: this.props.globalResources.SUPERVISOR_NAME, CERTIFIED_DATE: this.props.projectOrderCertifiedStartDateFieldName }), dataField: "supervisorcertifiedstartdate", dataType: 'date', visible: false },
                { caption: this.props.intl.formatMessage({ id: 'GENERAL.REGISTER.FIELD.PARTY.CERTIFIEDDATE' }, { PARTY: this.props.globalResources.CLIENT_NAME, CERTIFIED_DATE: this.props.projectOrderCertifiedStartDateFieldName }), dataField: "clientcertifiedstartdate", dataType: 'date', visible: false }
            );
        }
        if (this.props.projectOrderCertifiedCompletionDateFieldName) {
            columns.push(
                { caption: this.props.intl.formatMessage({ id: 'GENERAL.REGISTER.FIELD.PARTY.CERTIFIEDDATE' }, { PARTY: this.props.globalResources.SUPPLIER_NAME, CERTIFIED_DATE: this.props.projectOrderCertifiedCompletionDateFieldName }), dataField: "suppliercertifiedcompletiondate", dataType: 'date', visible: false },
                { caption: this.props.intl.formatMessage({ id: 'GENERAL.REGISTER.FIELD.PARTY.CERTIFIEDDATE' }, { PARTY: this.props.globalResources.PM_NAME, CERTIFIED_DATE: this.props.projectOrderCertifiedCompletionDateFieldName }), dataField: "pmcertifiedcompletiondate", dataType: 'date', visible: false },
                { caption: this.props.intl.formatMessage({ id: 'GENERAL.REGISTER.FIELD.PARTY.CERTIFIEDDATE' }, { PARTY: this.props.globalResources.SUPERVISOR_NAME, CERTIFIED_DATE: this.props.projectOrderCertifiedCompletionDateFieldName }), dataField: "supervisorcertifiedcompletiondate", dataType: 'date', visible: false },
                { caption: this.props.intl.formatMessage({ id: 'GENERAL.REGISTER.FIELD.PARTY.CERTIFIEDDATE' }, { PARTY: this.props.globalResources.CLIENT_NAME, CERTIFIED_DATE: this.props.projectOrderCertifiedCompletionDateFieldName }), dataField: "clientcertifiedcompletiondate", dataType: 'date', visible: false }
            );
        }

        columns.push(...CustomFieldHelper.MapCustomFieldsToDataGridColumns(this.customFields));

        return (
            <Portlet>
                <PortletBody>
                    <DataGridBase
                        version="2"
                        title={this.props.intl.formatMessage({ id: 'GENERAL.REGISTER.TITLE2' }, { ITEM_NAME: this.props.workflowItem.pageTitle })}
                        createPath={this.props.workflowItem.userFilters.mainNotice.createAllowed ? this.props.workflowItem.paths.createPath : null}
                        workflowItem={this.props.workflowItem}
                        name={this.props.workflowItem.pageTitle}
                        dataSource={this.state.notifications}
                        keyExpr="projectorderid"
                        selection={{ mode: 'single' }}
                        onSelectionChanged={this.onTableSelectionChanged}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={false}
                        columns={columns}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}

export default withListPageProps(ProjectListPage);
