import React from 'react';
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import DataGridBase from "../../../../partials/layout/DataGridBase";
import { Badge } from "react-bootstrap";
import * as quotationCrud from '../../../../crud/quotation.crud';
import { withListPageProps } from '../../../../shared/hoc/withListPageProps';

export const getCompensationPathWithQuoteType= (quoteType) =>{
    let compensationPath = ''
    if (quoteType === '623CON') {
        compensationPath = 'supplier';
    }
    else if (quoteType === '623CLIENT') {
        compensationPath = 'client';
    }
    else if (quoteType === '623CWQ') {
        compensationPath = 'claim';
    }
    else if (quoteType === '623SCON') {
        compensationPath = 'short-supplier';
    }
    else if (quoteType === '623CWN') {
        compensationPath = 'claim-with-notice';
    }
    else if (quoteType === '623CLIENTCWN') {
        compensationPath = 'client-cwn';
    }
    else if (quoteType === '623PM') {
        compensationPath = 'pm';
    }
    else if (quoteType === '612PM') {
        compensationPath = 'instructions';
    }
    else {
        this.props.enqueueSnackbar('Quote type ' + quoteType + ' unknown', { variant: 'error', });
        return;
    }
    return compensationPath;
}
class CompensationListAll extends React.Component {
    constructor(props) {
        super(props);
        this.quoteStatuses = props.placeholders
            .find(ph => ph.replacementKey === 'QuoteStatuses')
            .values.filter(item => item.isActive === true);


        this.state = {
            notifications: null
        };
        this.baseURL = props.workflowGroupItem.path.split('/').slice(0, 4).join('/');
        //can be used after Claims WQ-37 has a new modelType
        this.compensationPathsPerType = props.workflowGroupItem.workflows.reduce((obj, item) => ({ ...obj, [(item.config || {}).currentModelType]: (item.paths || {}).basePath }), {});
    }
    componentDidMount() {
        this.fetchQuotes();
    }
    fetchQuotes = async () => {
        try {
            const { companyid, contractid } = this.props.contract;
            let { data: quotes } = await quotationCrud.getQuotesReduced(companyid, contractid);
            quotes = quotes.filter(quo => quo.showtasknotification === 1);
            this.setState({ notifications: quotes });
        } catch (error) {
            this.props.enqueueSnackbar('Failed to load communications', { variant: 'error', });
        }
    }

    onTableSelectionChanged = ({ selectedRowsData }) => {
        if (selectedRowsData.length > 0) {
            const data = selectedRowsData[0];
            const url = this.calculateUrl(data, true);
            this.props.navigate(url);
        }
    }
    calculateUrl = (rowData, skipHostUrl) => {
        const { workflowItem } = this.props;
        const compensationPath = getCompensationPathWithQuoteType(rowData.type);
        return `${skipHostUrl ? '' : process.env.PUBLIC_URL}${workflowItem.paths.groupFullPath}${compensationPath}/${rowData.compensationeventid}/quote/${rowData.quotationid}`;
    }
    renderStatusColumn = (cellInfo) => {
        let badgeVariant = 'secondary';
        let status = this.renderStatusDisplay(cellInfo.data);
        if (cellInfo.data.showtasknotification !== 0) {
            switch (cellInfo.data.responsestatus) {
                case 1:
                case 7:
                    badgeVariant = 'success';
                    break;
                case 2:
                case 6:
                    badgeVariant = 'warning';
                    break;
                case 0:
                case 3:
                case 4:
                case 5:
                    badgeVariant = 'danger';
                    break;
                default:
                    badgeVariant = 'secondary';
                    break;
            }
        }
        return (<Badge variant={badgeVariant}>{status}</Badge>);
    }
    renderStatusDisplay = (rowData) => {
        let resourceKey;
        if (rowData.showtasknotification === 1) {
            resourceKey = rowData.responsestatus.toString();
        } else {
            resourceKey = 'none';
        }
        const status = this.quoteStatuses.find(x => x.field === resourceKey);
        if (status) {
            return status.display;
        } else {
            return rowData.status;
        }
    }

    formatCurrency = () => {
        return this.props.contract.currency
    }
    render() {
        if (!this.state.notifications) {
            return (<PortletSpinner />);
        }

        const columns = [
            { resource: "GENERAL.FORMS.FIELD.ID", dataField: "referenceid", dataType: 'string', renderReferenceidLink: true },
            { resource: "GENERAL.FORMS.FIELD.COMPENSATION_ID", dataField: "compensationreference", dataType: 'string' },
            { resource: "GENERAL.FORMS.FIELD.DAYS", dataField: 'days' },
            { resource: "GENERAL.FORMS.FIELD.AMOUNT", dataField: 'amount', dataType: 'currency', currencyCode: this.props.contract.currency },
            { resource: "CONTRACT.WF.QUOTES.FIELD.QUOTE_DATE", dataField: "notificationdate", dataType: 'date' },
            { resource: "GENERAL.FORMS.FIELD.ISSUED_BY", dataField: "communicatedby" },
            { caption: this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.RESPONSE_REQUIRED' }), dataField: "responsedate", dataType: 'date' },
            { caption: this.props.intl.formatMessage({ id: 'CONTRACT.WF.COMPENSATION.FIELD.RESPONSE_RECEIVED' }), dataField: "responseresponsedate", dataType: 'date' },
            { resource: "GENERAL.FORMS.FIELD.STATUS", dataField: "responsestatus", dataType: 'string', cellRender: this.renderStatusColumn, calculateCellValue: this.renderStatusDisplay },
            { resource: "GENERAL.FORMS.FIELD.TITLE", dataField: "quotereference", allowFiltering: true, visible: false },
            { resource: "GENERAL.FORMS.FIELD.REPLIED_BY", dataField: "replyby", visible: false },
            { resource: "CONTRACT.WF.QUOTES.FIELD.REPLY_BY_COMPANY", dataField: "replybycompany", visible: false },
            { resource: "GENERAL.FORMS.FIELD.REPLY_DRAFT_BY", dataField: "draftreplyby", visible: false },
            { resource: "GENERAL.FORMS.FIELD.REPLY_COMMENTS", dataField: "responsecomment", isHtml: true },
            { resource: "CONTRACT.WF.QUOTES.FIELD.SUMMARY", dataField: "quotecomment", isHtml: true },
            { resource: "GENERAL.FORMS.FIELD.LINK", dataField: "url", calculateCellValue: this.calculateUrl, visible: false, showInColumnChooser: false },
            { resource: "GENERAL.FORMS.FIELD.CURRENCY", dataField: "currency", visible: false, calculateCellValue: this.formatCurrency },
            { resource: "PROJECT.FIELD.CODE", dataField: "projectcode", visible: false },
            { resource: "PROJECT.FIELD.NAME", dataField: "project", visible: false },
            { resource: "GENERAL.FORMS.REPLING_PARTY", dataField: "replyingparty", lookup: { dataSource: this.props.contractTemplate.parties.options, valueExpr: 'id', displayExpr: 'display' }, visible: false }



        ];
        if (this.props.globalResources.QUOTE_DEEMED_ACCEPTANCE_STARTS !== 'OFF') {
            columns.push(
                { resource: "CONTRACT.WF.COMPENSATION.FIELD.QUOTE_DEEM_DATE", dataField: "quotedeemedacceptancedate", dataType: 'date' }
            );
        }

        return (
            <Portlet>
                <PortletBody>
                    <DataGridBase
                        version="1"
                        title={this.props.intl.formatMessage({ id: 'CONTRACT.WF.QUOTES.TITLE.REGISTER' })}
                        workflowItem={this.props.workflowItem}
                        name={this.props.intl.formatMessage({ id: 'CONTRACT.WF.QUOTES.TITLE.REGISTER' })}
                        dataSource={this.state.notifications}
                        keyExpr="quotationid"
                        selection={{ mode: 'single' }}
                        onSelectionChanged={this.onTableSelectionChanged}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={false}
                        columns={columns}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}



export default withListPageProps(CompensationListAll);
