/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
import { connect } from "react-redux";
import { Nav, Tab } from "react-bootstrap";
import SideBarNavigationTabAction from './CustomControl/SideBarNavigationTabAction';
import TabAction from './CustomControl/TabAction';

class ContractSidePanelAction extends React.Component {
    state = { selectedTab: "1" };
    setTab = _tabName => {
        this.setState({ selectedTab: _tabName });
    };


    render() {
        if (!this.props.contractTemplate || !this.props.manualActions) {
            return null;
        }
        const { contractTemplate, manualActions } = this.props;

        return (
            <Tab.Container defaultActiveKey={this.state.selectedTab}>
                <div style={{ marginTop: "2rem" }} className="kt-quick-panel__nav">
                    <Nav
                        onSelect={this.setTab}
                        className="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand kt-notification-item-padding-x"
                    >
                        <SideBarNavigationTabAction
                            partyHasRoleInContract={this.props.contractTemplate.parties.supplierExist}
                            title={contractTemplate.resources.SUPPLIER_NAME}
                            eventKey="1"
                            actions={manualActions.SUPPLIER.items}
                        />
                        <SideBarNavigationTabAction
                            partyHasRoleInContract={this.props.contractTemplate.parties.pmExist}
                            title={contractTemplate.resources.PM_NAME}
                            eventKey="2"
                            actions={manualActions.PM.items}
                        />
                        <SideBarNavigationTabAction
                            partyHasRoleInContract={this.props.contractTemplate.parties.supervisorExist}
                            title={contractTemplate.resources.SUPERVISOR_NAME}
                            eventKey="3"
                            actions={manualActions.SUPERVISOR.items}
                        />
                        <SideBarNavigationTabAction
                            partyHasRoleInContract={this.props.contractTemplate.parties.clientExist}
                            title={contractTemplate.resources.CLIENT_NAME}
                            eventKey="4"
                            actions={manualActions.CLIENT.items}
                        />
                    </Nav>
                </div>
                <div className="kt-quick-panel__content">
                    <Tab.Content>
                        <Tab.Pane eventKey="1">
                            <TabAction
                                panelCloseBtnRef={this.props.panelCloseBtnRef}
                                contractid={this.props.contractid}
                                isOpen={this.props.isOpen}
                                actionsObj={manualActions.SUPPLIER}
                                skey="SUPPLIER"
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="2">
                            <TabAction
                                panelCloseBtnRef={this.props.panelCloseBtnRef}
                                contractid={this.props.contractid}
                                isOpen={this.props.isOpen}
                                actionsObj={manualActions.PM}
                                skey="PM"
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="3">
                            <TabAction
                                panelCloseBtnRef={this.props.panelCloseBtnRef}
                                contractid={this.props.contractid}
                                isOpen={this.props.isOpen}
                                actionsObj={manualActions.SUPERVISOR}
                                skey="SUPERVISOR"
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="4">
                            <TabAction
                                panelCloseBtnRef={this.props.panelCloseBtnRef}
                                contractid={this.props.contractid}
                                isOpen={this.props.isOpen}
                                actionsObj={manualActions.CLIENT}
                                skey="CLIENT"
                            />
                        </Tab.Pane>
                    </Tab.Content>
                </div>
            </Tab.Container>
        );
    }
}

const mapStateToProps = store => ({
    contractDashboard: store.contract.dashboard,
    contractTemplate: store.contract.template,
    manualActions: store.contract.manualActions,
});

export default connect(mapStateToProps)(ContractSidePanelAction)
