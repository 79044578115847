import React from 'react';
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as earlyWarningCrud from '../../../../crud/earlyWarning.crud';
import DataGridBase from "../../../../partials/layout/DataGridBase";
import CustomFieldHelper from "../../_Helpers/CustomFieldHelper";
import { notificationAggregateListTagsFilter, notificationUniqueTagsFilter } from "../../../../store/helpers/FilterHelper";
import { withListPageProps } from '../../../../shared/hoc/withListPageProps';

// const updateModalConfig = {
//     fields: [
//         { resource: "GENERAL.FORMS.FIELD.ACTION_OWNER", dataField: 'owner', required: true },
//         { resource: "GENERAL.FORMS.FIELD.ACTION", dataField: 'solution', required: true, editorType: 'dxHtmlEditor', required: true },
//     ]
// };

// const resolveModalConfig = {
//     fields: [
//         { resource: 'CONTRACT.WF.EW.FIELD.RESOLVED', dataField: 'resolvedate', required: true, editorType: 'dxDateBox', required: true },
//     ]
// };

class WarningListAll extends React.Component {

    constructor(props) {
        super(props);
        this.gridRef = React.createRef();
        this.maxReferenceIDPrefix = this.props.notificationAbbreviation_PM;
        if ((this.maxReferenceIDPrefix||'').length < (props.notificationAbbreviation_SUP||'').length) {
            this.maxReferenceIDPrefix = props.notificationAbbreviation_SUP;
        }

        this.notificationsTagsEnabled = process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true' && props.contract.specifictags?.length > 0;
        this.notificationsRelsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true';
        this.customFields = [];
        if (props.customFields) {
            if (props.customFields.COMMUNICATION && props.customFields.COMMUNICATION.length > 0) {
                this.customFields.push(...(props.customFields.COMMUNICATION.filter(cf => cf.includelisting)));
            }
            if (props.customFields.REPLY && props.customFields.REPLY.length > 0) {
                this.customFields.push(...(props.customFields.REPLY.filter(cf => cf.includelisting)));
            }
        }
        this.state = {
            notifications: null,
            tags: null,
            selectedWarning: null,
            showUpdateModal: false,
            showResolveModal: false,
        };
    }
    componentDidMount() {
        this.fetchNotifications();
    }

    fetchNotifications = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const {data: responseObj } = await earlyWarningCrud.getEarlyWarningsReduced(companyid, projectid, contractid, '', this.props.accessibleWfIds)
            let listData = notificationAggregateListTagsFilter(responseObj.aggregatelist);
            listData = listData.filter(ntf => ntf.showtasknotification === 1);
            let tagFilters = notificationUniqueTagsFilter(responseObj.uniquetags);
            this.setState({ notifications: listData, tags: tagFilters });
        } catch (error) {
            console.log(error)
            this.props.enqueueSnackbar('Failed to load communications', { variant: 'error', });
        }

    }

    // componentDidUpdate(prevProps, prevState) {
    //     if (prevProps.earlyWarnings.data !== this.props.earlyWarnings.data) {
    //         this.setNotifications();
    //     }
    // }
    // setNotifications = () => {
    //     if (this.props.earlyWarnings.data) {
    //         this.setState({ notifications: this.props.earlyWarnings.data.filter(ntf => ntf.showtasknotification === 1) })
    //     }
    // }
    // onTableSelectionChanged = ({ selectedRowsData }) => {
    //     if (selectedRowsData.length > 0) {
    //         const data = selectedRowsData[0];
    //         if (this.state.selectedWarning !== data) {
    //             this.setState({ selectedWarning: data }, () => { this.gridRef.current.instance.option('onToolbarPreparing', this.onToolbarPreparing) });
    //         }
    //     }
    // }
    onTableSelectionChanged = ({ selectedRowsData }) => {
        if (selectedRowsData.length > 0) {
            const data = selectedRowsData[0];
            const url = this.calculateUrl(data, true);
            this.props.navigate(url);
        }
    }
    calculateUrl = (rowData, skipHostUrl) => {
        //Find related WF configuration
        const recordWF = this.props.workflowGroupItem.workflows.find(wf=> wf.config.currentModelType === rowData.party);
        if(recordWF){            
            return  (skipHostUrl ? '' : process.env.PUBLIC_URL) +recordWF.paths.basePath + rowData.warningid;
        }
        return "";
    }
    renderIdColumn = (cellInfo) => {
        let redirectURL = '';
        if (cellInfo.data.party === 'PM') {
            redirectURL = this.props.workflowItem.paths.basePath.replace('register', 'pm') + cellInfo.data.warningid;
        }
        else if (cellInfo.data.party === 'Contractor') {
            redirectURL = this.props.workflowItem.paths.basePath.replace('register', 'supplier') + cellInfo.data.warningid;
        }


        return (
            <Link to={redirectURL}>
                <strong style={{ whiteSpace: 'normal' }}>
                    {cellInfo.data.referenceid}
                </strong>
            </Link>
        );
    }

    
    renderMeetingColumn = (cellInfo) => {
        let badgeVariant = 'secondary';
        let display = this.props.intl.formatMessage({ id: 'OPTIONS.MEETING.NOT_REQUIRED' })
        if (cellInfo.data.meetingrequired === 2) {
            badgeVariant = 'danger';
            display = this.props.intl.formatMessage({ id: 'OPTIONS.MEETING.REQUIRED' })
        }
        return (<Badge variant={badgeVariant}>{display}</Badge>);
    }
    renderMeetingDisplay = (rowData) => {
        return rowData.meetingrequired === 2 ? this.props.intl.formatMessage({ id: 'OPTIONS.MEETING.REQUIRED' }) : this.props.intl.formatMessage({ id: 'OPTIONS.MEETING.NOT_REQUIRED' });
    }


    renderStatusColumn = (cellInfo) => {
        let badgeVariant = 'danger';
        let display = this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.Open' });
        if (cellInfo.data.status === 'Resolved') {
            badgeVariant = 'success';
            display = this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.Closed' });
        }
        return (<Badge variant={badgeVariant}>{display}</Badge>);
    }
    renderStatusValue = (rowData) => {
        return rowData.status === 'Resolved' ? this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.Closed' }) : this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.Open' });
    }
    calculatePriorityValue = (rowData) => {
        return this.props.intl.formatMessage({ id: 'OPTIONS.RISK.' + rowData.reference });
    }
    renderPriorityColumn = (cellInfo) => {
        const priority = cellInfo.data.reference;
        let badgeVariant = 'secondary';
        switch (priority) {
            case 'Low':
                badgeVariant = 'secondary';
                break;
            case 'Medium':
                badgeVariant = 'warning';
                break;
            case 'High':
                badgeVariant = 'danger';
                break;
            default:
                break;
        }
        const displayText = this.calculatePriorityValue(cellInfo.data);
        return (<Badge variant={badgeVariant}>{displayText}</Badge>);
    }

    // renderToolbar = () => {
    //     if (this.props.canActionPm || this.props.canActionSupplier) {

    //         let enabled = this.state.selectedWarning && this.state.selectedWarning.status !== 'Resolved';
    //         enabled = enabled && ((this.state.selectedWarning.party === "PM" && this.props.canActionPm) || (this.state.selectedWarning.party === "Contractor" && this.props.canActionSupplier));
    //         return (
    //             <div style={{ display: 'flex', flexFlow: 'row-reverse', margin: "20px 0px" }}>
    //                 <Button disabled={!enabled} onClick={() => this.setState({ showResolveModal: true })} variant="primary" size="sm" style={{ marginLeft: "10px" }}>
    //                     <i className="fas fa-check-circle"></i>
    //                     <FormattedMessage id="CONTRACT.WF.EW.BUTTON.CLOSE_RISK" />
    //                 </Button>
    //                 <Button disabled={!enabled} onClick={() => this.setState({ showUpdateModal: true })} variant="primary" size="sm" >
    //                     <i className="fas fa-pen"></i>
    //                     <FormattedMessage id="CONTRACT.WF.DEFECTS.BUTTON.UPDATE_OWNER_ACTIONS" />
    //                 </Button>
    //             </div>
    //         );
    //     }
    //     else {
    //         return null;
    //     }
    // }
    // onResolveModalResponse = (resp) => {
    //     this.setState({ showUpdateModal: false, showResolveModal: false });
    //     if (resp) {
    //         const warning = { ...this.state.selectedWarning, ...resp };
    //         warning.status = 'Resolved';
    //         warning.resolvedatedisplay = moment(warning.resolvedate).format('DD/MM/YYYY');
    //         this.saveWarning(warning);
    //     }
    // }
    // onActionModalResponse = (resp) => {
    //     this.setState({ showUpdateModal: false, showResolveModal: false });
    //     if (resp) {
    //         const warning = { ...this.state.selectedWarning, ...resp };

    //         const earlyWarningReduction = {
    //             warningid: warning.warningid,
    //             solution: warning.solution,
    //             actionby: this.props.contractUser.accountid,
    //             action: warning.owner,
    //             deleted: 0,
    //         }
    //         earlyWarningReductionCrud.saveEarlyWarningReduction(earlyWarningReduction)
    //             .then(response => {
    //                 // this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.SAVED_SUCCESS' }), { variant: 'success', });
    //                 this.saveWarning(warning);
    //             })
    //             .catch(err => {
    //                 this.props.enqueueSnackbar('Failed to save EarlyWarningReduction', { variant: 'error', });
    //             });
    //     }
    // }

    // saveWarning = (warning, triggerFethAfter) => {
    //     if (!warning.notificationdatedisplay && warning.notificationdate) {
    //         warning.notificationdatedisplay = moment(warning.notificationdate).format('DD/MM/YYYY');
    //     }
    //     if (!warning.replydatedisplay && warning.replydate) {
    //         warning.replydatedisplay = moment(warning.replydate).format('DD/MM/YYYY');
    //     }
    //     if (!warning.resolvedatedisplay && warning.resolvedate) {
    //         warning.resolvedatedisplay = moment(warning.resolvedate).format('DD/MM/YYYY');
    //     }

    //     earlyWarningCrud.saveEarlyWarning(warning)
    //         .then(response => {
    //             this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: 'GENERAL.COMMUNICATON.SAVED_SUCCESS' }), { variant: 'success', });
    //             this.props.fetchEarlyWarning();
    //             this.gridRef.current.instance.clearSelection();
    //         })
    //         .catch(err => {
    //             this.props.enqueueSnackbar(err.toString(), { variant: 'error', });
    //         });
    // }


    renderNotifyingParty = (rowData) => {
        const notifyingPartyOpt = this.props.contractTemplate.parties.options.find(opt => opt.id === rowData.notifyingparty);
        if(notifyingPartyOpt){
            return `${notifyingPartyOpt.display} (${rowData.communicatedby})`
        }
        else return rowData.party;
    }

    getWarningID = (warning) => {
        return warning.referenceid;
    }

    // addGridContextMenuItems = (e) => {
    //     if (e.target === 'content') {
    //         this.gridRef.current.instance.selectRows([e.row.data.warningid]);
    //         // e.items can be undefined
    //         if (!e.items) e.items = [];
    //         const authorized = (e.row.data.party === "PM" && this.props.canActionPm) || (e.row.data.party === "Contractor" && this.props.canActionSupplier);

    //         const enabled = authorized &&
    //             e.row.data &&
    //             e.row.data.status !== 'Resolved';
    //         // Add a custom menu item
    //         e.items.push({
    //             text: this.props.intl.formatMessage({ id: 'CONTRACT.WF.DEFECTS.BUTTON.UPDATE_OWNER_ACTIONS' }),
    //             disabled: !enabled,
    //             icon: 'edit',
    //             onItemClick: () => {
    //                 //this.dataGrid.instance.selectRows([e.row.data.warningid]);
    //                 this.setState({ selectedWarning: e.row.data, showUpdateModal: true });
    //             }
    //         });
    //         e.items.push({
    //             text: this.props.intl.formatMessage({ id: 'CONTRACT.WF.EW.BUTTON.CLOSE_RISK' }),
    //             disabled: !enabled,
    //             icon: 'check',
    //             onItemClick: () => {
    //                 //this.dataGrid.instance.selectRows([e.row.data.warningid]);
    //                 this.setState({ selectedWarning: e.row.data, showResolveModal: true });
    //             }
    //         });
    //     }
    // }
    renderReplyStatusColumn = (cellInfo) => {
        let badgeVariant = 'secondary';
        switch (cellInfo.data.replystatus) {
            case 'Awaiting Reply':
            case 'Awaiting Acceptance':
                badgeVariant = 'warning';
                break;
            case 'Not Communicated':
                badgeVariant = 'secondary';
                break;
            case 'Not Accepted':
                badgeVariant = 'danger';
                break;
            case 'Reply Not Required':
            case 'Reply Received':
            case 'Accepted':
                badgeVariant = 'success';
                break;
            default:
                break;
        }
        const displayText = this.calculateStatusValue(cellInfo.data);
        return (<Badge variant={badgeVariant}>{displayText}</Badge>);
    }
    calculateStatusValue = (rowData) => {
        return this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.' + rowData.replystatus });
    }
    // onToolbarPreparing = (e) => {
    //     let enabled = this.state.selectedWarning && this.state.selectedWarning.status !== 'Resolved';
    //     enabled = enabled && ((this.state.selectedWarning.party === "PM" && this.props.canActionPm) || (this.state.selectedWarning.party === "Contractor" && this.props.canActionSupplier));

    //     const onCloseRisk = () => this.setState({ showResolveModal: true });
    //     const onUpdateOwner = () => this.setState({ showUpdateModal: true });
    //     const items = [];
    //     items.push(
    //         {
    //             location: 'before',
    //             template: 'toolbarTitle'
    //         }
    //     );
    //     items.push(
    //         {
    //             location: "after",
    //             widget: "dxButton",
    //             options: {
    //                 icon: "edit",
    //                 text: this.props.intl.formatMessage({ id: 'GENERAL.FORMS.BUTTON_UPDATE' }),
    //                 disabled: !enabled,
    //                 onClick: onUpdateOwner
    //             }
    //         }
    //     );
    //     items.push(
    //         {
    //             location: "after",
    //             widget: "dxButton",
    //             options: {
    //                 icon: "check",
    //                 text: this.props.intl.formatMessage({ id: 'CONTRACT.WF.EW.BUTTON.CLOSE_RISK' }),
    //                 disabled: !enabled,
    //                 onClick: onCloseRisk
    //             }
    //         }
    //     );

    //     e.toolbarOptions.items.unshift(...items);
    // }
    sortIdColumn = (rowData) => {
        return rowData.sequencenumber;
    }
    render() {
        if (!this.state.notifications) {
            return (<PortletSpinner />);
        }
        const columns = [
            { resource: "GENERAL.FORMS.FIELD.ID", dataField: "referenceid", dataType: 'string', calculateSortValue: this.sortIdColumn, renderReferenceidLink: true },
            { resource: "GENERAL.FORMS.FIELD.SUBJECT", dataField: 'title' },
            { resource: "GENERAL.FORMS.FIELD.NOTIFIED_DATE", dataField: "notificationdate", dataType: 'date' },
            { resource: "GENERAL.FORMS.FIELD.NOTIFIED_BY", dataField: "notifyingparty", calculateCellValue: this.renderNotifyingParty },
            { resource: "CONTRACT.WF.EW.FIELD.MEETING", dataField: "meetingrequired", dataType: 'string', cellRender: this.renderMeetingColumn, calculateCellValue: this.renderMeetingDisplay },
            { resource: "CONTRACT.WF.EW.FIELD.PRIORITY", dataField: "reference", cellRender: this.renderPriorityColumn, calculateCellValue: this.calculatePriorityValue },
            { resource: "GENERAL.FORMS.FIELD.ACTION_OWNER", dataField: "owner" },
            { resource: "CONTRACT.WF.EW.FIELD.RESOLVED", dataField: "resolvedate", dataType: 'date' },
            { resource: "GENERAL.FORMS.FIELD.STATUS", dataField: "status", cellRender: this.renderStatusColumn, calculateCellValue: this.renderStatusValue },
            { resource: "GENERAL.FORMS.FIELD.REPLY_REQUIRED", dataField: "replyrequireddate", visible: false, dataType: 'date' },
            { resource: "GENERAL.FORMS.FIELD.REPLY_RECIEVED", dataField: "replydate", visible: false, dataType: 'date' },
            { resource: "CONTRACT.WF.EW.FIELD.REPLY_STATUS", dataField: "replystatus", visible: false, cellRender: this.renderReplyStatusColumn, calculateCellValue: this.calculateStatusValue },
            { resource: "GENERAL.FORMS.FIELD.REPLIED_BY", dataField: "replyby", visible: false },
            { resource: "GENERAL.FORMS.FIELD.REPLY_DRAFT_BY", dataField: "draftreplyby", visible: false },
            { resource: "GENERAL.FORMS.FIELD.ACTION", dataField: "solution", isHtml: true },
            { resource: "GENERAL.FORMS.FIELD.DESCRIPTION", dataField: "description", isHtml: true },
            { resource: "GENERAL.FORMS.FIELD.REPLY", dataField: "replycomment", isHtml: true },
            { resource: "CONTRACT.WF.EW.FIELD.LIKELIHOOD", dataField: "likelihood", visible: false },
            { resource: "CONTRACT.WF.EW.FIELD.SEVERITY", dataField: "severity", visible: false },
            { resource: "CONTRACT.WF.EW.FIELD.RISK_STATUS", dataField: "riskstatus", visible: false },
            { resource: "GENERAL.FORMS.FIELD.LINK", dataField: "url", calculateCellValue: this.calculateUrl, visible: false, showInColumnChooser: false },
            { resource: "PROJECT.FIELD.CODE", dataField: "projectcode", visible: false },
            { resource: "PROJECT.FIELD.NAME", dataField: "project", visible: false },
            { resource: "GENERAL.FORMS.REPLING_PARTY", dataField: "replyingparty", lookup: { dataSource: this.props.contractTemplate.parties.options, valueExpr: 'id', displayExpr: 'display' }, visible: false },
        ];
        if (this.props.workflowItem.assetFieldsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.FIELD.ASSET_NAME", dataField: "assetname", visible: false },
                { resource: "GENERAL.FORMS.FIELD.ASSET_TYPE", dataField: "assettype", visible: false },
            );
        }
        if (this.notificationsTagsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.FIELD.PORTFOLIO", dataField: "tags_portfolio", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.PortfolioFilter } },
                { resource: "GENERAL.FORMS.FIELD.PROGRAMME", dataField: "tags_programme", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.ProgrammeFilter } },
                { resource: "GENERAL.FORMS.FIELD.PROJECT", dataField: "tags_project", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.ProjectFilter } },
                { resource: "GENERAL.FORMS.FIELD.OTHER", dataField: "tags_other", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.OtherFilter } }
            )
        }
        if (this.notificationsRelsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.TAB_RELATIONS", dataField: "relatednotices", visible: false },
            )
        }
        columns.push(...CustomFieldHelper.MapCustomFieldsToDataGridColumns(this.customFields));

        return (
            <Portlet>
                <PortletBody >
                    <DataGridBase
                        version="2"
                        title={this.props.title}
                        // onToolbarPreparing={this.onToolbarPreparing}
                        gridRef={this.gridRef}
                        // selectedRowKeys={null}
                        referenceIDPrefix={this.maxReferenceIDPrefix}
                        name={`Master Early Warning Register`}
                        dataSource={this.state.notifications}
                        keyExpr="warningid"
                        // allowSelection={true}
                        selection={{ mode: 'single' }}
                        // selection={{ mode: 'single', showCheckBoxesMode: 'onClick' }}
                        onSelectionChanged={this.onTableSelectionChanged}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={false}
                        columns={columns}
                    // onContextMenuPreparing={this.addGridContextMenuItems}
                    />
                </PortletBody>
            </Portlet >
        );
    }
}



export default withListPageProps(WarningListAll);
