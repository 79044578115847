import React from 'react';
import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import PortletSpinner from "../../../../partials/layout/PortletSpinner";
import DataGridBase from "../../../../partials/layout/DataGridBase";
import { Badge } from "react-bootstrap";
import CustomFieldHelper from "../../_Helpers/CustomFieldHelper";
import * as inspectionDefectCrud from '../../../../crud/inspectionDefect.crud';
import { notificationAggregateListTagsFilter, notificationUniqueTagsFilter } from "../../../../store/helpers/FilterHelper";
import { withListPageProps } from '../../../../shared/hoc/withListPageProps';
import moment from 'moment';

class ExtensionListPage extends React.Component {
    constructor(props) {
        super(props);

        this.notificationsTagsEnabled = process.env.REACT_APP_COMMUNICATION_TAG_ENABLED === 'true' && props.contract.specifictags?.length > 0;
        this.notificationsRelsEnabled = process.env.REACT_APP_COMMUNICATION_REL_ENABLED === 'true';
        this.customFields = [];
        if (props.customFields) {
            if (props.customFields.COMMUNICATION && props.customFields.COMMUNICATION.length > 0) {
                this.customFields.push(...(props.customFields.COMMUNICATION.filter(cf => cf.includelisting)));
            }
            if (props.customFields.REPLY && props.customFields.REPLY.length > 0) {
                this.customFields.push(...(props.customFields.REPLY.filter(cf => cf.includelisting)));
            }
        }
        this.state = {
            notifications: null,
            tags: null
        };
    }

    componentDidMount() {
        this.fetchNotifications();
    }

    fetchNotifications = async () => {
        try {
            const { companyid, projectid, contractid } = this.props.contract;
            const {data: responseObj } = await inspectionDefectCrud.getContractInspections(companyid, projectid, contractid, this.props.workflowItem.config.currentModelType, this.props.accessibleWfIds);
            let listData = notificationAggregateListTagsFilter(JSON.parse(responseObj.aggregatelist));
            listData = (this.props.workflowItem.userFilters.mainNotice.viewDraftAllowed === true) ?
                listData :
                listData.filter(ntf => ntf.showtasknotification === 1);

            let tagFilters = notificationUniqueTagsFilter(JSON.parse(responseObj.uniquetags));

            this.setState({ notifications: listData, tags: tagFilters });
        } catch (error) {
            console.log(error);
            this.props.enqueueSnackbar('Failed to load communications', { variant: 'error', });
        }

    }

    onTableSelectionChanged = ({ selectedRowsData }) => {
        if (selectedRowsData.length > 0) {
            const data = selectedRowsData[0];
            const url = this.calculateUrl(data, true);
            this.props.navigate(url);
        }
    }
    calculateUrl = (rowData, skipHostUrl) => {
        return  (skipHostUrl ? '' : process.env.PUBLIC_URL) +this.props.workflowItem.paths.basePath + rowData.inspectiondefectid;
    }
    renderStatusColumn = (cellInfo) => {
        let badgeVariant = 'secondary';
        switch (cellInfo.data.replystatus) {
            case 'Awaiting Reply':
            case 'Awaiting Acceptance':
                badgeVariant = 'warning';
                break;
            case 'Not Communicated':
                badgeVariant = 'secondary';
                break;
            case 'Uncorrected':
            case 'Not Accepted':
                badgeVariant = 'danger';
                break;
            case 'Communicated':
            case 'Reply Received':
            case 'Accepted':
                badgeVariant = 'success';
                break;
            default:
                break;
        }
        const displayText = this.calculateStatusValue(cellInfo.data);
        return (<Badge variant={badgeVariant}>{displayText}</Badge>);
    }
    calculateStatusValue = (rowData) => {
        return this.props.intl.formatMessage({ id: 'OPTIONS.STATUS.' + rowData.replystatus });
    }
    calculateDateTimeValue = (data) => {
        const date = moment(data.assistancetestserchdefect, 'DD/MM/YYYY').toDate();
        return date;
    }
    render() {
        if (!this.state.notifications) {
            return (<PortletSpinner />);
        }

        const columns = [
            { resource: "GENERAL.FORMS.FIELD.ID", dataField: "referenceid", dataType: 'string', renderReferenceidLink: true },
            { resource: "GENERAL.FORMS.FIELD.COMPENSATION_ID", dataField: 'replyaction' },
            { resource: "GENERAL.FORMS.FIELD.SUBJECT", dataField: 'replyactiondetail' },
            { resource: "CONTRACT.WF.EXTENSION.FIELD.ORIGINAL_DATE", dataField: 'assistancetestserchdefect', dataType: 'date', calculateCellValue: this.calculateDateTimeValue },
            { resource: "CONTRACT.WF.EXTENSION.FIELD.PROPOSED_DATE", dataField: 'inspectiondate', dataType: 'date' },
            { resource: "GENERAL.FORMS.FIELD.REPLY_REQUIRED", dataField: "replyrequireddate", dataType: 'date' },
            { resource: "GENERAL.FORMS.FIELD.NOTIFIED_DATE", dataField: "notificationdate", dataType: 'date' },
            { resource: "GENERAL.FORMS.FIELD.NOTIFIED_BY", dataField: "communicatedby" },
            { resource: "GENERAL.FORMS.FIELD.STATUS", dataField: "replystatus", cellRender: this.renderStatusColumn, calculateCellValue: this.calculateStatusValue },
            { resource: "GENERAL.FORMS.FIELD.REPLY_DRAFT_BY", dataField: "draftreplyby", visible: false },
            { resource: "GENERAL.FORMS.FIELD.LINK", dataField: "url", calculateCellValue: this.calculateUrl, visible: false, showInColumnChooser: false },
            { resource: "PROJECT.FIELD.CODE", dataField: "projectcode", visible: false },
            { resource: "PROJECT.FIELD.NAME", dataField: "project", visible: false },
            { resource: "GENERAL.FORMS.FIELD.REPLY_RECIEVED", dataField: "replydate", dataType: 'date', visible: false },
            { resource: "GENERAL.FORMS.REPLING_PARTY", dataField: "replyingparty", lookup: { dataSource: this.props.contractTemplate.parties.options, valueExpr: 'id', displayExpr: 'display' }, visible: false }
        ];
        if (this.props.workflowItem.assetFieldsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.FIELD.ASSET_NAME", dataField: "assetname", visible: false },
                { resource: "GENERAL.FORMS.FIELD.ASSET_TYPE", dataField: "assettype", visible: false },
            );
        }
        if (this.notificationsTagsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.FIELD.PORTFOLIO", dataField: "tags_portfolio", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.PortfolioFilter } },
                { resource: "GENERAL.FORMS.FIELD.PROGRAMME", dataField: "tags_programme", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.ProgrammeFilter } },
                { resource: "GENERAL.FORMS.FIELD.PROJECT", dataField: "tags_project", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.ProjectFilter } },
                { resource: "GENERAL.FORMS.FIELD.OTHER", dataField: "tags_other", visible: false, dataType: 'tag', headerFilter: { dataSource: this.state.tags.OtherFilter } }
            )
        }
        if (this.notificationsRelsEnabled) {
            columns.push(
                { resource: "GENERAL.FORMS.TAB_RELATIONS", dataField: "relatednotices", visible: false },
            )
        }
        columns.push(...CustomFieldHelper.MapCustomFieldsToDataGridColumns(this.customFields));

        if (this.props.mode === 'PM') {
            columns.splice(2, 0, { resource: "CONTRACT.WF.EXTENSION.FIELD.QUOTE_ID", dataField: 'location' });
        }
        return (
            <Portlet>
                <PortletBody>
                    <DataGridBase
                        version="1"
                        title={this.props.intl.formatMessage({ id: 'GENERAL.REGISTER.TITLE2' }, { ITEM_NAME: this.props.workflowItem.pageTitle })}
                        createPath={this.props.workflowItem.userFilters.mainNotice.createAllowed ? this.props.workflowItem.paths.createPath : null}
                        workflowItem={this.props.workflowItem}
                        name={this.props.workflowItem.pageTitle}
                        dataSource={this.state.notifications}
                        keyExpr="inspectiondefectid"
                        selection={{ mode: 'single' }}
                        onSelectionChanged={this.onTableSelectionChanged}
                        hoverStateEnabled={true}
                        rowAlternationEnabled={false}
                        columns={columns}
                    />
                </PortletBody>
            </Portlet>
        );
    }
}




export default withListPageProps(ExtensionListPage);
