import React from 'react';
import { Badge } from "react-bootstrap";
import { addWeeks } from 'date-fns';
import moment from 'moment';
import { Dropdown } from "react-bootstrap";
import ActionDropdownToggle from "../CustomDropdowns/ActionDropdownToggle";
import { completeActionConfirmationDialog } from "../../../pages/admin/Contracts/Contract/ContractActions";
import * as actionCrud from "../../../crud/action.crud";
import { injectIntl } from "react-intl";
import { withSnackbar } from 'notistack';
import { withRouter } from '../../../shared/hoc/withRouter';



const getNotificationDate = (actionItem) => {
    const actionDate = new Date(actionItem.duedate);
    if (actionDate) {
        let badgeVariant = "success";
        if (actionDate < new Date()) {
            badgeVariant = "danger";
        }
        else if (actionDate < addWeeks(new Date(), 1)) {
            badgeVariant = "warning";
        }

        return (<Badge variant={badgeVariant}>{moment(actionDate).format('L')}</Badge>)
    } else {
        return null;
    }
}

class PendingActionItemRow extends React.Component {
    constructor(props) {
        super(props);
        const { actionItem, workflow } = props;
        const actionOptions = []
        let noActions = false;
        if (actionItem.groupcategory === 1) {
            actionOptions.push({ icon: "flaticon2-check-mark", text: `Set Complete`, onClick: this.onClickComplete });
        }
        if ([1, 3].includes(actionItem.groupcategory) && actionItem.sourcereferenceid && actionItem.sourceworkflowid && actionItem.sourcerecordid) {
            actionOptions.push({ icon: "flaticon2-paper", text: `View Related ${actionItem.sourcereferenceid}`, onClick: this.onClickSource });
        }
        if (actionItem.groupcategory === 3 && workflow?.userFilters?.mainNotice?.createAllowed) {
            actionOptions.push({ icon: "flaticon2-add-square", text: `Create ${workflow.title}`, onClick: this.onClickDestination });
        }
        if (actionItem.groupcategory === 2 && (workflow?.userFilters?.replyNotice?.saveDraftReplyAllowed || workflow?.userFilters?.replyNotice?.communicateReplyAllowed)) {
            actionOptions.push({ icon: "flaticon2-reply", text: `Reply ${workflow.title}`, onClick: this.onClickDestination });
        }
        if (actionItem.groupcategory === 4 && workflow?.userFilters?.quoteMainNotice?.createQuoteAllowed) {
            actionOptions.push({ icon: "flaticon2-add-square", text: `Reply ${workflow.title}`, onClick: this.onClickDestination });
        }
        if (actionItem.groupcategory === 5 && (workflow?.userFilters?.quoteReplyNotice?.saveQuoteDraftReplyAllowed || workflow?.userFilters?.quoteReplyNotice?.communicateQuoteReplyAllowed)) {
            actionOptions.push({ icon: "flaticon2-reply", text: `Reply ${workflow.title}`, onClick: this.onClickDestination });
        }
        if (actionItem.groupcategory === 6 && (workflow?.userFilters?.actionTab?.actionAllowed || workflow?.userFilters?.actionTab?.resolveActionAllowed)) {
            actionOptions.push({ icon: "flaticon2-accept", text: `Action ${workflow.title}`, onClick: this.onClickDestination });
        }
        if (actionOptions.length === 0) {
            noActions = true;
            actionOptions.push({ icon: "flaticon2-cross", text: `No actions available`, onClick: this.onEmptyClick, style: { cursor: "default" } });
        }
        const toggleClickAction = {}
        if (actionOptions.length === 1 && !noActions && actionItem.groupcategory !== 1) {
            //IF GROUP CATEGORY IS NOT 1
            //AND IF THERE IS ONLY ONE ACTION OPTION
            //DIRECTLY EXECUTE ACTION
            //THERE IS NO NEED TO SHOW 1 ITEM DROPDOWN OPTION
            toggleClickAction.onClick = actionOptions[0].onClick;
        }
        this.actionOptions = actionOptions;
        this.toggleClickAction = toggleClickAction;
    }

    shouldComponentUpdate() {
        return false;
    }
    onEmptyClick = (e) => {
        e.preventDefault();
    }
    onClickComplete = async (e) => {
        e.preventDefault();
        const { actionItem, workflow } = this.props;
        let result = await completeActionConfirmationDialog(actionItem, this.props.intl);
        if (result) {
            try {
                const requestObj = {
                    contractid: this.props.contractid,
                    actionids: [actionItem.actionid]
                }
                const response = await actionCrud.complete(requestObj);
                this.props.enqueueSnackbar("Action completed!", { variant: "success" });
            } catch (err) {
                this.props.enqueueSnackbar(err.toString(), { variant: "error" });
            }
        }
    }
    onClickSource = (e) => {
        e.preventDefault();
        const { actionItem, contractTemplate } = this.props;
        if (contractTemplate.workflowsQA[actionItem.sourceworkflowid]) {
            if (this.props.panelCloseBtnRef) {
                this.props.panelCloseBtnRef.current.click();
            }
            this.props.navigate(contractTemplate.workflowsQA[actionItem.sourceworkflowid].paths.basePath + actionItem.sourcerecordid);
        }
    }
    onClickDestination = (e) => {
        e.preventDefault();
        const { actionItem, workflow } = this.props;
        if (workflow) {
            if (this.props.panelCloseBtnRef) {
                this.props.panelCloseBtnRef.current.click();
            }
            if (actionItem.type === "SYSTEM_COMMUNICATE_QUOTE") {
                const quotereatePath = workflow.paths.quotationBasePath.replace(":notificationid", actionItem.resolvingrecordid) + "create";
                this.props.navigate(quotereatePath);
            }
            else if (actionItem.type === "SYSTEM_REPLY_QUOTE") {
                const quotereatePath = workflow.paths.quotationBasePath.replace(":notificationid", actionItem.resolvingrecordid) + actionItem.resolvingchildrecordid;
                this.props.navigate(quotereatePath);
            }
            else if (actionItem.resolvingrecordid) {
                this.props.navigate(workflow.paths.basePath + actionItem.resolvingrecordid);
            }
            else {
                this.props.navigate(workflow.paths.createPath);
            }
        }
    }

    render() {
        return (
            <Dropdown
                // className="kt-header__topbar-item kt-header__topbar-item--langs"
                drop="down"
            >
                <Dropdown.Toggle {...this.toggleClickAction} as={ActionDropdownToggle} id="dropdown-toggle-action">
                    <div style={{ width: '100%', float: 'right', display: 'flex', flexDirection: 'row-reverse' }}>
                        <div>{getNotificationDate(this.props.actionItem)}</div>
                        <div style={{
                            width: '100%',
                            fontWeight: 400,
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            boxOrient: 'vertical',
                            overflow: 'hidden',
                            marginLeft: 5,
                        }}>
                            {this.props.actionItem.sourcereferenceid && (<strong style={{ marginRight: 5 }}>{this.props.actionItem.sourcereferenceid}</strong>)}
                            {this.props.actionItem.subject}
                        </div>
                    </div>
                </Dropdown.Toggle>
                {(!this.toggleClickAction.onClick) && (
                    <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-lg" >
                        <ul className="kt-nav">
                            {
                                this.actionOptions.map((opt, index) => (
                                    <li style={opt.style ? { ...opt.style } : null} className="kt-nav__item" key={`PendingActionSelector_${index}`} >
                                        <a style={opt.style ? { ...opt.style } : null} href="#" className="kt-nav__link">
                                            <i className={`kt-nav__link-icon ${opt.icon}`} />
                                            <span id={`PendingActionSelector_${index}`} className="kt-nav__link-text" onClick={opt.onClick}>{opt.text}</span>
                                        </a>
                                    </li>
                                ))
                            }
                        </ul>
                    </Dropdown.Menu>
                )}

            </Dropdown>

        );
    }

};

export default withRouter(withSnackbar(injectIntl(PendingActionItemRow)));