import React, { Component } from "react";
import * as authDuck from "../../store/ducks/auth.duck";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";
import Auth0LogoutHandler from "./Handlers/Auth0LogoutHandler";
import SuperTokenLogoutHandler from "./Handlers/SuperTokenLogoutHandler";

class Logout extends Component {
  constructor(props) {
    super(props);
    this.auth0Method = process.env.REACT_APP_SSO_METHOD;
  }
  componentDidMount() {
    console.log("Logout page did Mount")
    const { hasAuthToken, logoutProcessing, loginMethod } = this.props;
    if (loginMethod !== 'AUTH0' && loginMethod !== 'SUPERTOKEN') {
      console.log("Logout.js process started!")
      this.props.startLogout();
    }

  }

  render() {
    const { hasAuthToken, logoutProcessing, loginMethod } = this.props;
    console.log("LOGIN METHOD:", loginMethod);
    console.log("LOGOUT IN PROGRES:", logoutProcessing);
    console.log("FD AUTH TOKEN EXIST:", !!hasAuthToken);
    if (loginMethod === 'AUTH0') {
      return <Auth0LogoutHandler />
    }
    else if (loginMethod === 'SUPERTOKEN') {
      return <SuperTokenLogoutHandler />
    }
    else {
      if (hasAuthToken || logoutProcessing) {
        //LOGOUT IN PROGRESS
        return <LayoutSplashScreen />;
      }
      else {
        return <Navigate replace to="/auth/login" />;
      }
    }

  }
}

const mapStateToProps = state => ({
  hasAuthToken: (state.auth.authToken.token || state.auth.csrfToken.token),
  loginMethod: state.auth.authToken.loginMethod,
  logoutProcessing: state.auth.logoutProcessing
});

const mapDispatchToProps = {
  startLogout: authDuck.actions.startLogout
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
