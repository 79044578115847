import React from "react";
import { connect } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { withRouter } from '../shared/hoc/withRouter';
import AuthPage from "../pages/auth/AuthPage";

import SuperTokenHandler from "../pages/auth/Handlers/SuperTokenHandler";
import Auth0Handler from "../pages/auth/Handlers/Auth0Handler";
import DiscoverHandler from "../pages/auth/Handlers/DiscoverHandlerSinglePage";
import DiscoverWithRouter from "../pages/auth/Handlers/DiscoverWithRouter";
import QueryString from "query-string";
import { LayoutSplashScreen } from "../../_metronic";
import FDConnectorLoginHandler from "../pages/auth/Handlers/FDConnectorLoginHandler";
import { Auth0Provider } from "@auth0/auth0-react";

const debugAuthToken = process.env.REACT_APP_DEBUG_AUTH_TOKEN;

// const onRedirectCallback = (appState) => {
//   history.push(
//     appState && appState.returnTo ? appState.returnTo : window.location.pathname
//   );
// };

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider




class AppRoutesWOT extends React.Component {
  // componentDidMount(){
  //   console.log("AppRoutesWOT Mounted!")
  // }
  render() {
    const { location, auth } = this.props;
    if (process.env.REACT_APP_SSO_METHOD === 'SUPERTOKEN' && !debugAuthToken) {
      return (
          <SuperTokenHandler />
      )
    }
    if (process.env.REACT_APP_SSO_METHOD === 'AUTH0' && !debugAuthToken) {
      return (
          <Auth0Handler />
      )
    }
    if (process.env.REACT_APP_SSO_METHOD === 'DISCOVER' && !debugAuthToken) {
      return (
        <DiscoverWithRouter />
      )
    }
    if (process.env.REACT_APP_SSO_METHOD === 'FDCONNECTOR' && !debugAuthToken) {
      const queryObj = QueryString.parse(location.search);
      this.tokenId = queryObj.tokenId;
      if (!queryObj.tokenId && !auth.logoutProcessing) {
        window.location.href = process.env.REACT_APP_AUTH0_URL; // 'https://fastdraftauth0-staging.builtintelligence.com/Account/Login/'
        return <LayoutSplashScreen />;
      }

      if (this.tokenId) {
        return <FDConnectorLoginHandler tokenId={this.tokenId} />;
      }
    }
    return (
      <Routes caseSensitive>
        <Route path="/auth/*" element={<AuthPage />} sensitive />
        <Route path="*" element={<Navigate state={{ from: this.props.location.pathname }} replace to="/auth/login" />} />
      </Routes>
    );
  }
}

const mapStateToProps = (store) => ({
  userProfile: store.auth.userProfile,
  auth: store.auth,
});


export default (withRouter(connect(mapStateToProps)(AppRoutesWOT)));
